.FlightTimeCalculator{
    position: relative;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
}
.FlightTimeCalculator .tools-and-apis .currentCityHeader .currentCityImg {
    background: #63697b;
}
.FlightTimeCalculator .tools-and-apis .currentCityHeader .currentCityTittle{
    background: #767b8a;
}
/* .CalculatorsAndTimers .currentCityHeader .currentCityTittle */

.description{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    width: 77%;
}

.description h4 {
    font-size: 18px;
    font-weight: bold;
}

.description p {
    line-height: 1.4;
    font-weight: bold;
}

.description .left_text{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.description .link_width{
    font-weight: bold;
}


