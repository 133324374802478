.Search {
	padding: 15px;
}
.search_form {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 0 5px;
}
.Search .search_field_timepicker,
.Search .search_field {
	width: 100%;
	color: rgba(68, 68, 68, 1);
	border: 1px solid #d2d5d5;
	padding: 21px 30px;
}
.Search .search_field_timepicker::-webkit-input-placeholder,
.Search .search_field::-webkit-input-placeholder {
	color: #d2d5d5;
}
.Search .search_field_timepicker[placeholder],
.Search .search_field[placeholder] {
	text-overflow: ellipsis;
}
.Search .searchError {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	background: #d0cccc;
	padding: 5px 10px;
	color: rgb(68, 68, 68);
	width: 100%;
	z-index: 6;
}
.Search .searchError span {
	display: block;
}
.Search .search_form_select {
	flex-basis: 400px;
	position: relative;
	margin: 0 10px 0 0;
}
.Search .search_form_list_timepicker,
.Search .search_form_list {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	color: rgba(68, 68, 68, 1);
	border: none;
	background: #fff;
	max-height: 250px;
	height: 0;
	overflow-y: auto;
	z-index: 6;
}
.Search .search_form_list_timepicker li .selectCityWrapperTimepicker,
.Search .search_form_list li .selectCityWrapperTimepicker,
.Search .search_form_list_timepicker li .selectCityWrapper,
.Search .search_form_list li .selectCityWrapper {
	display: block;
	position: relative;
	padding: 5px 40px 5px 10px;
	cursor: pointer;
}
.Search .search_form_list_timepicker li .selectCityWrapperTimepicker div,
.Search .search_form_list li .selectCityWrapperTimepicker div,
.Search .search_form_list_timepicker li .selectCityWrapperTimepicker b,
.Search .search_form_list li .selectCityWrapperTimepicker b,
.Search .search_form_list_timepicker li .selectCityWrapperTimepicker img,
.Search .search_form_list li .selectCityWrapperTimepicker img,
.Search .search_form_list_timepicker li .selectCityWrapper div,
.Search .search_form_list li .selectCityWrapper div,
.Search .search_form_list_timepicker li .selectCityWrapper b,
.Search .search_form_list li .selectCityWrapper b,
.Search .search_form_list_timepicker li .selectCityWrapper img,
.Search .search_form_list li .selectCityWrapper img {
	color: inherit;
	background: inherit;
}
.Search
	.search_form_list_timepicker
	li
	.selectCityWrapperTimepicker
	.mainNameWrapper
	img,
.Search .search_form_list li .selectCityWrapperTimepicker .mainNameWrapper img,
.Search .search_form_list_timepicker li .selectCityWrapper .mainNameWrapper img,
.Search .search_form_list li .selectCityWrapper .mainNameWrapper img {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 10px;
	width: 20px;
}
.Search .cityDateButton {
	margin: 0 6px 0 20px;
}

.Search .search_options {
	width: 200px;
	color: #444f4f;
	height: 38px;
}
.Search .search_options > div {
	border-color: #d2d5d5;
	box-shadow: none;
	border-radius: 0;
	height: 38px;
}
.Search .search_options .css-15k3avv > div,
.Search .search_options > div:nth-child(3) > div {
	background: #fff;
}
.Search .search_options > div:focus,
.Search .search_options > div:hover {
	border-color: #d2d5d5;
	box-shadow: none;
}
.Search .search_options.close_options {
	display: none;
}

@media (max-width: 599px) {
	.Search .search_form {
		flex-direction: column;
		align-items: flex-start;
		padding: 6px;
	}
	.Search .search_form_select {
		width: 100%;
		order: 1;
		flex-basis: auto;
		margin: 0 0 10px;
		order: 0;
	}
	.Search .search_options {
		width: 100%;
		order: 2;
		flex-basis: auto;
	}
}

.serarch-block {
	display: flex;
	justify-content: center;
	align-items: center;
}
.serarch-block .Search {
	width: 675px;
	max-width: 100%;
}
.serarch-block b {
	margin: 0 10px 0;
}
.Search .search_form .search_form_select,
.Search .search_form .search_form_select .search_field {
	height: 38px;
}
.serarch-block .Search .search_field_timepicker,
.serarch-block .Search .search_field {
	padding: 10px 15px;
}
.serarch-block .Search .search_options .Select-control {
	height: 39px;
}
.serarch-block .Select-placeholder,
.serarch-block .Select--single > .Select-control .Select-value {
	padding: 0 30px 0 15px;
}
@media (max-width: 737px) {
	.serarch-block {
		flex-direction: row;
	}
}

@media (max-width: 991px) {
	.serarch-block {
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
	}
	.serarch-block .Search {
		width: 660px;
		padding: 15px 0;
	}
}
