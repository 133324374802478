.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide{
    display: flex;
}
.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info{
    width: 50%;
    margin: 0 15px 0 0;
}
.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info:last-child{
    margin: 0;
}
.eclipsesSolarLunar.eclipsesCitySolarLunar .next-eclipse{
    margin: 15px 0 0;
}

.SunCityEclipses .eclipsesSolarLunarInfo{
    display: flex;
}
.SunCityEclipses .eclipsesSolarLunarInfo > .sunCulcCityInfoRightSide{
    width: 69%;
    margin: 0 1% 0 0;
}
.SunCityEclipses .eclipsesSolarLunarInfo > .eclipses-img{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 30%;
}
.SunCityEclipses .eclipsesSolarLunarInfo > .eclipses-img .cityMainImg{
    max-width: 100%;
    max-height: 300px;
    margin: 0 0 10px;
}
.SunCityEclipses .eclipsesCitySolarLunar .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info{
    width: 49.5%;
    margin: 0 1% 0 0;
}
.SunCityEclipses .eclipsesCitySolarLunar .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info:last-child{
    margin: 0;
}
.SunCityEclipses .eclipsesSolarLunarInfo.table > .eclipses-img{
    justify-content: inherit;
}