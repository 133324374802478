.MainPage .search-block {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 5px;
  background: #008854;
}
.MainPage .search-block h4 {
  color: #fff;
  margin: 0 10px 0 0;
}
.MainPage .search-block .Search {
  width: 610px;
  max-width: 100%;
  padding: 0;
}
.MainPage .search-block .Search .search_form {
  margin: 0;
}

.MainPage .citiesRender {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.MainPage .search_field {
  padding-left: 10px !important;
}

.MainPage .meeting-note {
  margin: 0 auto 30px;
  text-align: center;
}

.MainPage .meeting-note span {
  padding: 4px 20px;
  background-color: #dbdbdb;
  font-weight: 700;
}

.MainPage .almahac-title.almahac-title_mob {
  display: none;
}
.MainPage .almahac-title.almahac-title_desk {
  display: none;
}

@media (max-width: 1199px) {
  .MainPage .search-block {
    flex-direction: column;
  }
  .MainPage .search-block h4 {
    margin: 0 0 10px;
  }
}

@media (max-width: 1080px) {
  .MainPage .almahac-title {
    margin: 20px 0;
  }
  .MainPage .almahac-title.almahac-title_desk {
    display: block;
  }
}

@media (max-width: 780px) {
  .MainPage .meeting-note span {
	display: block;
	line-height: 1.3;
  }
}

@media (max-width: 699px) {
  .MainPage .almahac-title.almahac-title_mob {
    display: block;
    margin-top: 0;
  }
  .MainPage .almahac-title.almahac-title_desk {
    display: none;
  }
}
