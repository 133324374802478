.Timezones .timezones-header {
	background: #008854;
	color: #fff;
	padding: 10px 30px 2px;
	margin: 0 0 20px;
}
.Timezones .timezones-header .select-block {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.Timezones .timezones-header h1 {
	margin: 0 0 5px;
	font-size: 24px;
	line-height: 26px;
	font-weight: 400;
}
.Timezones .timezones-header h3 {
	font-size: 20px;
	line-height: 22px;
	font-weight: 400;
}
.Timezones .timezones-header .select-block .check-block div {
	color: #fff !important;
}
.Timezones
	.timezones-header
	.select-block
	.check-block
	.sort-list
	> div
	> div
	svg {
	color: #fff !important;
	fill: #fff !important;
}
.Timezones .timezones-header .select-block .check-block .sort-list label {
	color: #fff !important;
}
.Timezones .timezones-header .select-block .check-block .sort-list hr {
	border-top: none rgb(224, 224, 224) !important;
	border-left: none rgb(224, 224, 224) !important;
	border-right: none rgb(224, 224, 224) !important;
	border-bottom: 1px solid rgb(224, 224, 224) !important;
}
.Timezones .timezones-header .select-block .check-block .sort-list {
	width: 160px !important;
	overflow: hidden;
}
.Timezones .geotimedate-table {
	margin: 0 0 10px;
}
.Timezones .geotimedate-table .sticky-table-header {
	width: 100%;
}
.Timezones
	.geotimedate-table
	.sticky-table-header-wrapper
	.sticky-table-row
	.sticky-table-cell {
	background: #d5efde;
	height: 47px;
	font-size: 14px;
	line-height: 16px;
}
.Timezones .geotimedate-table .sticky-table-header-wrapper {
	background: #d5efde;
}
.Timezones
	.geotimedate-table
	.sticky-table-row
	.sticky-table-cell
	p:last-child {
	padding: 9px 10px;
	font-size: 14px;
	line-height: 16px;
}

@media screen and (max-width: 589px) {
	.Timezones .timezones-header .select-block {
		flex-direction: column;
	}
}
