.Retrograde .citySunRiseSet .city_name_date h4 b{
    display: block;
    font-weight: normal;
}

.rowRetrogradetitleTable .titleTable_PlanetInSigns{
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
}
.select-change .year{
    width: 110px !important;
    margin: 0 0px 0 0 !important;
}
.select-change .period{
    width: 160px !important;
    margin: 0 0px 0 0 !important;
}


.select-change  .month-chnge-request{
    flex-basis: 70px !important;
    width: 95px !important;
    background: #e9e9e9;
    color: #7f7f7f;
    border: none;
    cursor: pointer;
    padding: 0;
}
.select-change .month-chnge-request:hover{
    background: #008853;
    color: #fff;
}
.Planet-In-Signs .geotimedate-table .sticky-table .sticky-table-row > div{
    width: calc(100% / 3) !important;
}
.Planet-In-Signs .geotimedate-table .sticky-table-row .sticky-table-cell p{
    padding: 9px 10px;
    font-size: 14px;
    line-height: 16px;
}
.Planet-In-Signs .geotimedate-table .sticky-table-row .sticky-table-cell p:last-child{
    padding: 9px 10px;
}
.Planet-In-Signs .geotimedate-table .sticky-table .sticky-table-header{
    width: 100%;
}
@media screen and (max-width: 991px) {
    .header-bottom{
        display: none;
    }
}