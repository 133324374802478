.content {
  margin: 0 20px;
}

.text-center {
  text-align: center;
}

.world_header {
  background: #008854;
  color: #fff;
  padding: 10px 0;
}

.world_header_gray {
  background: #f5f5f5;
  color: black;
  padding: 10px 0;
}

.world_header h1 {
  font-size: 24px;
  line-height: 26px;
  font-weight: 400;
}

.world_header h3 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  width: 650px;
}

.world_header .row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.world_header .center-g {
  display: flex;
  justify-content: center;
}

.world_header .start-v {
  display: flex;
  align-items: flex-start;
}

.world_exchanges_page {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
}

.world_exchanges_page .world_header .Search .search_options {
  display: none;
}

.world_exchanges_page .world_header .Search .search_form .search_form_select {
  max-width: 205px;
}

.world_exchanges_page .world_header .Search {
  padding: 0;
}

.world_exchanges_page .world_header .Search .search_form {
  margin: 0;
}

.world_exchanges_page .world_header .Search .search_form_select {
  margin: 0;
}

/*Radio BTN*/
.row-radio-btn {
  display: flex;
  align-items: center;
  justify-content: start;
}

.row-radio-btn.column {
  flex-direction: column;
}

.row-radio-btn .world-checkbox {
  width: auto;
  white-space: nowrap;
  margin-right: 25px;
}

.world_exchanges_page
  .world_header
  .row-radio-btn
  .world-checkbox
  > div
  > div
  > div
  svg {
  color: #fff !important;
  fill: #fff !important;
}
.world_exchanges_page .world_header .row-radio-btn .world-checkbox label {
  color: #fff !important;
}

.world_exchanges_page .date-current {
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
}

.world_exchanges_page .header-bottom-row {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
}

.world_exchanges_page .link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  border-radius: 9px;
  background: #feeda5;
}

.world_exchanges_page .link a {
  color: red;
  font-size: 16px;
}

.world_exchanges_page .row-table {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.world_exchanges_page .row-table .column {
  width: 50%;
}

.world_exchanges_page .row-table .column .content {
  display: flex;
  justify-content: start;
  align-items: center;
}

.world_exchanges_page .row-table .column .content .link_table {
  display: inline-block;
  width: 40%;
  min-width: 211px;
  text-align: start;
  padding-left: 10px;
}

.world_exchanges_page .row-table .column .content .status {
  display: inline-block;
  width: 15%;
  text-align: left;
}

.world_exchanges_page .row-table .column .content .time_work {
  color: green;
}

.world_exchanges_page .row-table .column .content .time_work_close {
  color: red;
}

.world_exchanges_page .row-table .column .content .abbr {
  margin-left: 10px;
}

.world_exchanges_page .row-table .column .cell {
  border: 1px solid #ccc;
}

.world_exchanges_page .row-table .column .cell:nth-child(2n) {
  background: #f1eeee;
}

.row-table .img_flag {
  width: 32px;
}

.logo_stock_exchange {
  width: 90px;
}

.world_exchanges_page .world_header_gray h3 {
  width: auto;
}

.world_exchanges_page .world_header_gray i {
  font-size: 30px;
}

.world_exchanges_page .world_header_gray .title_stock {
  margin: 0 30px;
}

.world_exchanges_page .world_header_gray .title_stock .title_location {
  margin-top: 5px;
}

.world_exchanges_page .world_header_gray .row-schedule {
  width: 100%;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.world_exchanges_page .world_header_gray .col-schedule h4 {
  text-align: center;
}

.world_exchanges_page .world_header_gray .info_stock {
  width: 80%;
}

.world_exchanges_page .world_header_gray .block_baner {
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.world_exchanges_page .world_header_gray .baner {
  width: 250px;
}

.world_exchanges_page .world_header_gray .more_info {
  margin: 30px 0 10px 0;
  justify-content: center;
}

/*TIME PLANER*/

.world_exchanges_page .timePicker-about {
  padding: 3em 1em 0;
}

.world_exchanges_page .timePicker-about_title {
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px;
}

.world_exchanges_page .timePicker-about_question {
  font-size: 18px;
  margin-bottom: 8px;
}

.world_exchanges_page .timePicker-about_answer {
  font-size: 16px;
  margin-bottom: 26px;
}

.world_exchanges_page .timePicker .timePickerSlider {
  background: #f5f5f5;
}

.world_exchanges_page .time-format-date {
  display: flex;
  justify-content: flex-end;
}

.world_exchanges_page .cityBlock .time-picker-left {
  width: 347px;
}

.world_exchanges_page
  .cityBlock
  .time-picker-left
  .time-picker-left-name-block {
  width: 318px;
}

.world_exchanges_page .cityBlock .time-picker-left .time-picker-left-flag {
  width: 60px;
}

.world_exchanges_page .cityBlock .time-picker-left .time-picker-left-time {
  width: 85px;
}

.world_exchanges_page
  .cityBlock
  .time-picker-left
  .time-picker-left-time
  .difference_hours {
  position: absolute;
  left: -38px;
  font-size: 13px;
  top: 5px;
  color: #d75101;
}

.world_exchanges_page .cityBlock .time-picker-left .time-picker-left-city {
  flex-basis: 115px;
}

.world_exchanges_page .timePicker .cityBlock .time-picker-right {
  min-height: 75px;
}

@media (max-width: 1225px) {
  .world_exchanges_page .row-table .column .content .link_table {
    width: auto;
  }

  .world_exchanges_page .world_header_gray .row-schedule {
    width: 93%;
  }

  .world_exchanges_page .world_header_gray .info_stock {
    width: 100%;
  }

  .world_exchanges_page .world_header_gray .block_baner {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
  }
}

@media (max-width: 1186px) {
  .world_exchanges_page .row-table .column {
    width: 100%;
  }

  .world_exchanges_page .row-table .column .content {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .world_exchanges_page .header-bottom-row {
    flex-wrap: wrap;
  }

  .row-radio-btn {
    margin: 10px 0;
  }
}

@media (max-width: 1100px) {
  .world_exchanges_page .world_header_gray .col-schedule {
    margin: 20px 0;
    width: 30%;
  }
}

@media (max-width: 856px) {
  .world_exchanges_page .link {
    margin-top: 10px;
  }
}

@media (max-width: 760px) {
  .world_exchanges_page .world_header_gray .col-schedule {
    width: 50%;
  }
}

@media (max-width: 670px) {
  .world_exchanges_page .world_header .Search {
    width: 100%;
  }

  .world_exchanges_page .world_header .Search .search_form .search_form_select {
    max-width: 100%;
  }
}

@media (max-width: 620px) {
  .world_exchanges_page .row-table .column .content .link_table {
    width: 82%;
  }

  .world_exchanges_page .row-table .column .content {
    padding: 5px 0;
  }

  .world_exchanges_page .row-table .column .content .status {
    width: 9%;
  }

  .row-radio-btn {
    flex-wrap: wrap;
  }

  .world_header .row {
    margin: 10px 0;
  }
}

@media (max-width: 500px) {
  .world_exchanges_page .world_header_gray .col-schedule {
    width: 80%;
  }
}

@media (max-width: 400px) {
  .world_exchanges_page .row-table .column .content .status {
    width: auto;
  }
}
