.site-map{
	display: inline-block;
	vertical-align: top!important;
    margin: 10px;
}

.site-map .sm-column {
-webkit-column-width: 12px;
    -moz-column-width: 12px;
    column-width: 12px;
    -webkit-column-count: 8;
    -moz-column-count: 8;
    column-count: 8;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;

	line-height: 1.4em;
	display: table-cell;
	width: 12%;
}

.site-map h1{
	text-align: center;
	margin-bottom: 20px;
}

.site-map b, .site-map link{
	display: block;
}