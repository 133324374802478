.sun-calc-page {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}
.sum-moon-pade-date{
    display: flex;
}
.sum-moon-pade-date .page-logo-block{
    position: relative;
    width: 188px;
    height: 128px;
    /* margin: 0 60px 0 0; */
}
.sum-moon-pade-date .page-logo-block img{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
}
.sum-moon-pade-date .right-block{
    flex-grow: 1;
    height: 100%;
}

@media (max-width: 839px) {
    .sum-moon-pade-date .page-logo-block{
        display: none;
    }
    .sum-moon-pade-date .right-block{
        width: 100%;
    }
}