.Calculator {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
  margin: 0 0 50px;
  padding-bottom: 10px;
}

.Calculator .header {
  display: flex;
  align-items: center;
  height: 75px;
  padding: 10px 20px;
  background: #63697b;
  color: #fff;
}

.row_convector {
  display: flex;
  flex-wrap: wrap;
}
.column1 {
  width: 100%;
  max-width: calc(100% - 330px);
}

.Calculator .content {
  padding: 20px;
}

.Calculator .content h4 {
  margin: 0 0 10px;
}

.Calculator .content .content-info {
  display: flex;
  justify-content: space-between;
}

.Calculator .content .content-info > div:first-child {
  margin: 0 2% 0 0;
}

.Calculator .content .content-info .info-block {
  display: flex;
}

.Calculator .content .content-info .info-block .image {
  position: relative;
  width: 80px;
  height: 80px;
}

.Calculator .content .content-info .info-block .image i {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 100%;
  max-width: 100%;
  margin: auto 0;
  padding: 0;
  width: 70px;
  height: 70px;
  font-size: 70px;
}

.Calculator .content .content-info .info-block .info {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  width: calc(100% - 80px);
}

.Calculator .content .content-info .info-block .info h5 {
  padding: 0 0 5px;
}

.Calculator .content .content-info .info-block .info input {
  width: 200px;
  max-width: 100%;
  padding: 10px 15px;
}

.Calculator .content .content-info .info-block:first-child .info input {
  width: 400px;
}

.Calculator .content .content-info .info-block .info .Search {
  padding: 0;
  width: 400px;
  max-width: 100%;
}

.Calculator
  .content
  .content-info
  .info-block
  .info
  .Search
  .search_form_select {
  margin: 0;
  max-width: 100%;
}

.Calculator .content .content-info .calendar-date-picker > div,
.Calculator .content .content-info .time-date-picker {
  max-width: 100%;
}

.Calculator .footer {
  padding: 20px;
  display: flex;
  align-items: center;
}

.Calculator .footer button {
  width: 130px;
  height: 65px;
  background-color: rgba(22, 155, 213, 1);
  border: 1px solid rgba(22, 155, 213, 1);
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.Calculator .footer b {
  display: none;
  margin: 0 0 0 20px;
  color: rgb(244, 67, 54);
}

.search_form_select input[type="text"]::-webkit-input-placeholder {
  color: #808080;
}

.search_form_select input[type="text"]::-moz-placeholder {
  color: #808080;
}

.unix-title {
  font-size: 18px;
  padding: 10px 0px 10px 10px;
  margin: 0px;
  font-weight: 400;
  line-height: 1.1;
}

.unix-general {
  font-family: Roboto, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 20px;
}

.unix-row {
  font-family: Roboto, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  min-height: 20px;
}

.unix-row > h3,
.unix-row > h4,
.unix-row > p {
  padding: 10px 0px 10px 10px;
  margin: 0px;
  min-height: 36px;
}

.Calculator > .unix-row > h4 {
  padding: 20px 0px 20px 20px;
  margin: 0px;
}

.julian-convector .unix-row p span {
  display: block;
  border-bottom: 2px solid #c0c0c0 !important;
  width: 100%;
  padding-bottom: 2px;
}

.julian-convector .unix-row p:hover span {
  transition: 0.5s;
  border-bottom: none;
  box-shadow: none;
}

.julian-convector .unix-row p:not(:hover) span {
  transition: 0.5s;
  border-bottom: 1px solid #c0c0c0;
}

.julian-convector .unix-column {
  width: 74%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1200px) {
  .julian-convector .unix-column {
    width: 90%;
  }
}

.julian-convector .unix-head > h3 {
  display: flex;
  justify-content: center;
  min-height: 20px;
  font-weight: 600;
}

.julian-convector .text {
  display: flex;
  justify-content: flex-start;
  width: 53%;
  flex-wrap: wrap;
}

.julian-convector .text > h4 {
  font-weight: bold;
}

.julian-convector .text-result {
  isplay: flex;
  justify-content: flex-start;
  width: 26%;
}

.julian-convector .text-result > h4,
h5 {
  font-weight: bold;
}

.julian-convector .description-result {
  justify-content: center;
  display: flex;
  width: 100%;
}

.julian-convector .input > h4 {
  font-weight: bold;
}

.julian-convector .input-result {
  width: 77%;
}

.julian-convector .input-result > h4 {
  font-weight: bold;
}

.julian-convector .input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38%;
}

.julian-convector .input_your_curent {
  width: 83%;
}

.input > p > b:hover {
  font-size: 18px;
  transition: 0.5s;
}

.julian-convector .input > p > b:not(:hover) {
  font-size: 18px;
  transition: 0.5s;
}

.input > select,
input,
input:disabled {
  width: 85%;
  height: 30px;
  padding: 0px 0px 0px 5px;
  margin: 0px;
  border: none;
  border-bottom: 1px solid #ccc;
}

.julian-convector .input > select {
  border: none;
}

.julian-convector .input > input {
  height: 21px;
  border: none;
}

.julian-convector .input > input:disabled {
  height: 21px;
  border: none;
}

.julian-convector .input > input:disabled {
  /*background-color: white;*/
}

.julian-convector .input-select > select {
  height: 22px !important;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid #d2d5d5;
  color: darkgrey;
}

.julian-convector .input-select {
  border: 2px solid #d2d5d5;
  height: 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: darkgrey;
  width: 20%;
}

.julian-convector .input-select-label {
  border: none;
  height: 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: darkgrey;
  width: 20%;
}

.julian-convector .input-date > select:focus {
  border-bottom: none;
  box-shadow: none;
  transition: 0.5s;
}

.julian-convector .input-date > input:focus {
  border-bottom: none;
  box-shadow: none;
  transition: 0.5s;
}

.julian-convector .input-date > select:not(:focus) {
  border-bottom: none;
  transition: 0.5s;
}

.julian-convector .input-date > input:not(:focus) {
  border-bottom: none;
  transition: 0.5s;
}

.input-date {
  display: flex;
  justify-content: center;
  align-items: center;
}

.julian-convector .input-date {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #d2d5d5;
  width: 8%;
  margin-right: 2px;
  position: relative;
}

.julian-convector .input-date-label {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 8%;
  margin-right: 2px;
}

.julian-convector .input-date-label label {
  color: #d2d5d5;
}

.input-date > input {
  text-align: center;
  width: 70%;
  height: 30px;
  padding: 0px 0px 0px 5px;
  margin: 0px;
}

.julian-convector .input-date > input {
  text-align: center;
  width: 100% !important;
  height: 21px;
  padding: 0 !important;
  margin: 0;
}

.julian-convector .input-date input::-webkit-input-placeholder {
  color: white;
}

.julian-convector .input-date input::-moz-placeholder {
  color: white;
}

.input-date input::-webkit-input-placeholder {
  color: #c0c0c0;
}

.input-date input::-moz-placeholder {
  color: #c0c0c0;
}

.julian-convector .button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10% !important;
}

.julian-convector .button > button {
  height: 20px;
  width: 50px;
  padding: 0px 0px 0px 0px;
  margin: 0px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.buttons > button {
  height: 30px;
  width: 90%;
  padding: 0px 0px 0px 0px;
  margin: 10px;
}

.julian-convector .unix-use {
  background-color: #ee9545 !important;
  border: 1px solid #ee9545 !important;
  border-radius: 3px !important;
  color: #fff;
  cursor: pointer;
}

.unix-use:hover {
  background-color: #1dacd6;
  transition: 0.5s;
}

.unix-use:not(:hover) {
  transition: 2s;
  background-color: #169bd5;
}

.errorUnix {
  padding-left: 48%;
  color: #f44336;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.julian-convector .input-enter {
  border: 2px solid #d2d5d5;
  /*padding: 10px;*/
  height: 25px;
}

.input > .input-error {
  border-bottom: 1px solid #f44336;
  box-shadow: 0px 5px 10px -9px #f44336;
  transition: 0.5s;
}

.input-date > .input-error {
  border-bottom: 1px solid #f44336;
  box-shadow: 0px 5px 10px -9px #f44336;
  transition: 0.5s;
}

.julian-convector .placeholder_input {
  position: absolute;
  top: -26px;
  color: #d2d5d5;
}

@media (max-width: 1199px) {
  .FlightTimeCalculator .InformationBlocks {
    justify-content: center;
  }
  .Calculators .InformationBlocks {
    flex-direction: column;
  }
  .Calculators .InformationBlocks > div {
    margin: 0 0 25px;
  }
}

@media (max-width: 870px) {
  .text-long {
    flex-wrap: wrap;
    min-height: 90px !important;
    width: 100%;
  }

  .text-long-height {
    min-height: 160px !important;
  }

  .text-long .text {
    width: 100%;
    justify-content: center;
  }

  .text-long .input_your_curent {
    width: 61%;
  }

  .julian-convector .text-long .input-date {
    width: 16%;
    height: 40px;
  }

  .julian-convector .text-long .input-date input {
    height: 38px;
    font-size: 18px;
  }

  .julian-convector .text-long .input-select {
    height: 37px;
    width: 28%;
    position: relative;
    left: -36%;
  }

  .julian-convector .text-long .input-select > select {
    height: 33px !important;
  }

  .placeholder_row {
    display: none !important;
  }

  .julian-convector .text-result {
    width: 29%;
  }

  .julian-convector .placeholder_input {
    position: absolute;
    margin-top: -30px;
  }
}

@media (max-width: 768px) {
  .Calculator .content .content-info {
    flex-direction: column;
  }

  .Calculator .content .content-info > div {
    width: 100%;
  }

  .Calculator .content .content-info > div:first-child {
    margin: 0;
  }

  .text-long .input_your_curent {
    width: 97%;
  }
}

@media (max-width: 400px) {
  .julian-convector .text-long .input-date {
    width: 25%;
    height: 40px;
  }

  .julian-convector .text-long-mob {
    flex-wrap: wrap;
    min-height: 90px !important;
    width: 100%;
  }

  .julian-convector .text-long-mob .text {
    width: 100%;
  }

  .julian-convector .text-long-mob .input-result {
    width: 100%;
  }

  .julian-convector .text-long-mob .text-result {
    width: 46%;
  }

  .julian-convector .text-long-height {
    min-height: 214px !important;
  }

  .julian-convector .text-long .input-select {
    height: 40px;
    width: 40%;
    left: -27%;
  }

  .julian-convector .text-long-mob .empty_btn {
    width: 0% !important;
  }
}
