.blockSettings {
	/*border: 1px solid rosybrown;*/

	width: 100%;
	padding-left: 1.7em;
	padding-right: 1.7em;
}
.blockSettings > .row {
	/*border: 1px solid yellowgreen;*/

	display: flex;
	flex-direction: row;
}
.blockSettings > .row > .blockCurrentButton {
	margin-top: 15px;
}
.blockShowSettings {
	width: 100%;
	background: inherit;
	background-color: rgba(255, 255, 255, 1);
	box-sizing: border-box;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(238, 238, 238, 1);
	border-radius: 0px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.349019607843137);
}
.blockShowSettings > .row {
	display: flex;
	flex-direction: row;
}
.blockSettings > .align-start {
	display: flex;
	justify-content: flex-start;
}
.blockSettings > .center {
	display: flex;
	justify-content: center;
}
.blockShowSettings > .row > .column {
	/*border: 1px solid goldenrod;*/

	width: 25%;
	display: flex;
	flex-direction: column;
}
.blockSettings > .align-start > .check {
	padding: 10px 10px 10px 0px;
}
.blockSettings > .align-start > .check > input {
	width: 15px;
	height: 15px;
}
.blockSettings > .align-start > .check > label {
	margin: 0;
	padding: 5px;
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	line-height: 16px;
}

.check > label > input[type="checkbox"] {
	display: none;
	background-color: white;
}
.check > label > span:hover {
	padding-bottom: 2px;
	border-bottom: 1px solid #169bd5;
	box-shadow: 0px 5px 10px -9px #169bd5;
	transition: 0.5s;
}
.check > label > span:not(:hover) {
	padding-bottom: 2px;
	border-bottom: 1px solid white;
	transition: 0.5s;
}
.check > label > .pseudocheckbox::before {
	content: "\00A0";
	display: inline-block;
	box-sizing: border-box;
	width: 18px;
	height: 18px;
	background-color: white;
	border: 2px solid #b0b0b0;
	border-radius: 2px;
	margin-right: 6px;
	vertical-align: baseline;
	text-align: center;
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	line-height: 14px;
	font-weight: bold;
	color: #169bd5;
}
.check > label > input[type="checkbox"]:checked + .pseudocheckbox::before {
	content: "\2713";
}
.specific_day {
	color: #169bd5;
}

.column > .daySelect {
	width: 100%;
	/*min-width: 117px;*/
	margin: 20px 0px 20px 20px;
	display: flex;
	flex-direction: column;
}
.column > .daySelect > label {
	padding-top: 5px;
}
.column > .input-select > select {
	width: 85%;
	height: 30px;
	padding: 0px 0px 0px 5px;
	margin: 20px 0px 0px 10px;
	border: none;
	border-bottom: 1px solid #ccc;
}
.column > .input-select > select:hover {
	border-bottom: 1px solid #169bd5;
	box-shadow: 0px 5px 10px -9px #169bd5;
	transition: 0.5s;
}

.column > .input-select > select:not(:hover) {
	border-bottom: 1px solid #ccc;
	transition: 0.5s;
}

@media (max-width: 700px) {
	.blockShowSettings > .row > .column {
		width: 50%;
		display: flex;
		flex-direction: column;
	}
}

@media (max-width: 450px) {
	.blockShowSettings > .row {
		flex-wrap: wrap;
	}

	.blockShowSettings > .row > .column {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}
