.countdown-time{
    display: flex;
    justify-content: center;
}
.countdown-time .countdown-block{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.countdown-time .countdown-block .countdown-block-time{
    width: 70px;
    padding: 0 1px;
    font-size: 30px;
    line-height: 32px;
}