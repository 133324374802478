.CityBlocksList .cityBlock{
    display: flex;
    width: 100%;
    padding: 5px;
    background: #f5f5f5;
    margin: 0 0 5px;
}
.CityBlocksList .cityBlock .name-block{    
    display: flex;
    width: 40%;
    min-width: 300px;
}
.CityBlocksList .cityBlock .name-block .add-and-dell-city {    
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.CityBlocksList .cityBlock .name-block .add-and-dell-city i{  
    font-size: 22px;
    line-height: 24px;
    color: #7b7b7b;
    cursor: pointer;
}
.CityBlocksList .CityBlocks .cityBlock .name-block .add-and-dell-city.reorder-cities i:first-child{    
    transform: rotate(-90deg);
}
.CityBlocksList .CityBlocks .cityBlock .name-block .add-and-dell-city.reorder-cities i:last-child{
    transform: rotate(90deg);
}
.CityBlocksList .CityBlocks .cityBlock:first-child .add-and-dell-city i:first-child,
.CityBlocksList .CityBlocks .cityBlock:first-child .add-and-dell-city i:last-child,
.CityBlocksList .CityBlocks .cityBlock:nth-child(2) .add-and-dell-city.reorder-cities i:first-child,
.CityBlocksList .CityBlocks .cityBlock:last-child .add-and-dell-city.reorder-cities i:last-child {
    opacity: 0;
    cursor: default;
}
.CityBlocksList .cityBlock .name-block .flag{
    width: 84px;
    padding: 0 10px;
}
.CityBlocksList .cityBlock .name-block .flag img{
    width: 100%
}
.CityBlocksList .cityBlock .name-block .city{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.CityBlocksList .cityBlock .name-block .city p {
    font-size: 18px;
    line-height: 20px;
}
.CityBlocksList .cityBlock .name-block .city span {
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: #888;
}
.CityBlocksList .cityBlock .time-block {    
    display: flex;
    width: 60%;
    font-size: 16px;
    line-height: 18px;
}
.CityBlocksList .cityBlock .time-block .time-zone{    
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 190px;
    padding: 0 10px;
    position: relative;
}
.CityBlocksList .cityBlock .time-block .time-zone p,
.CityBlocksList .cityBlock .time-block .time-zone span,
.CityBlocksList .cityBlock .time-block .time-zone em{
    opacity: 0;
}
.CityBlocksList .cityBlock .time-block .time-zone span{
    font-size: 12px;
    line-height: 14px;
}
.CityBlocksList .cityBlock .time-block .time-zone em{
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto;
    height: 16px;
    font-style: normal;
    color: #fcc238;
}
.CityBlocksList .cityBlock .time-block .time{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: calc(100% - 190px);
}
.CityBlocksList .cityBlock.utc-time{
    display: none;
}
.CityBlocksList .cityBlock.utc-time .name-block .flag i{
    font-size: 61px;
    color: #7b7b7b;
}
.CityBlocksList .cityBlock.utc-time .name-block .add-and-dell-city i{
    opacity: 0;
    cursor: default;
}

@media (max-width: 767px){
    .CityBlocks .cityBlock .name-block,       
    .CityBlocks .cityBlock .time-block {        
        width: 100%;
    }
    .CityBlocks .cityBlock .time-block .time-zone {
        width: 130px;
    }
    .CityBlocks .cityBlock .time-block .time{
        width: calc(100% - 130px);
    }
}
@media (max-width: 379px){
    .CityBlocks .cityBlock .time-block .time{
        flex-direction: column;
    }
}