.moon-calc-page .geotimedate-table .sticky-table-header-wrapper{
    background: #b3e5fc;
}
.MoonData .geotimedate-table .sticky-table-header-wrapper .sticky-table-row .sticky-table-cell{
    padding: 0;
    background: #b3e5fc;
}
.MoonData .geotimedate-table .sticky-table-row .sticky-table-cell p,
.MoonData .geotimedate-table .sticky-table-row .sticky-table-cell b{
    display: flex;
    justify-content: center;
}
.MoonData .geotimedate-table .sticky-table-row .sticky-table-cell p i,
.MoonData .geotimedate-table .sticky-table-row .sticky-table-cell b i{
    font-size: 14px;
    line-height: 14px;
}
.MoonData .geotimedate-table .moon-event-block{
    display: flex;
    justify-content: center;
    align-items: center;
}
.MoonData .geotimedate-table .moon-event-block img{
    display: none;
    width: 20px;
    height: 20px;
    margin: 0 5px 0 0;
}
.MoonData .geotimedate-table .moon-event-RiseSet{
    max-width: 120px !important;
}

/* @media screen and (max-width: 40em){
	.MoonData .responsiveTable td .tdBefore{
		white-space: normal;
	}
} */
