.cookies-notice {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 6px 32px;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 5;
}

.cookies-notice p {
  font-size: 14px;
  color: #282828;
}

.cookies-notice button {
  padding: 4px 12px;
  color: #282828;
  background: transparent;
  border: 2px solid #282828;
  border-radius: 14px;
  cursor: pointer;
}

@media screen and (max-width: 950px) {
  .cookies-notice button {
    margin-top: 8px;
  }
}
