.holidays-time-planer {
	display: flex;
	justify-content: space-between;
	padding: 1em 5px 1em 1.5em;
	background: #f5f5f5;
}

.holidays-time-planer .title-list {
	min-width: 220px;
	margin-right: 36px;
}

.holidays-time-planer .title-wrapper {
	overflow: hidden;
	font-size: 13px;
	font-weight: 400;
}

.holidays-time-planer .title-wrapper p {
	position: relative;
	left: 0;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.holidays-time-planer .title-list-item {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.holidays-time-planer .title-list-item:not(:last-child) {
	margin-bottom: 0.3em;
}

.holidays-time-planer .title-list-item.title-list-item__hidden {
	opacity: 0;
}

.holidays-time-planer .title-item_small {
	min-width: 86px;
	font-size: 11px;
	line-height: 1;
	text-align: right;
}

.holidays-time-planer .timetable {
	position: relative;
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-between;
}

.holidays-time-planer .timetable_item__wrapper {
	position: relative;
	min-height: 18px;
}

.holidays-time-planer .timetable_item__wrapper p {
	position: absolute !important;
	top: 0;
}

.holidays-time-planer
	.timetable_item__wrapper.timetable_item__wrapper-event
	p:hover {
	z-index: 2;
}

.holidays-time-planer .timetable span {
	display: flex;
	justify-content: space-between;
	align-content: center;
	width: 100%;
	border-left: 1px solid #b1a9a9;
}

.holidays-time-planer .timetable span:nth-child(24) {
	border-right: 1px solid #b1a9a9;
}

.holidays-time-planer .timetable-items-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column wrap;
	justify-content: space-around;
	overflow: hidden;
}

.holidays-time-planer .timetable_item {
	position: relative;
	padding: 1px 5px;
	text-align: center;
	font-size: 12px;
	background-color: #bddcef;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.holidays-time-planer .timetable_item.timetable_item_active {
	background-color: #ffeea5;
}
