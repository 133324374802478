.holiday_main_container {
	justify-content: space-between;
	padding-top: 40px;
}

.holiday_main_container .panel {
	width: 100%;
	max-width: 340px;
	display: flex;
	flex-flow: column wrap;
	align-items: center;
}

.holiday_main_container .panel > img {
	width: 100%;
	max-width: 300px;
	margin-bottom: 20px;
}

.holiday_main_container .panel .planet-signs {
	width: 100%;
	margin-bottom: 40px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
	/*background: #f5f5f5;*/
}

.holiday_main_container .panel .planet-signs .head {
	background: #ff7532;
	padding: 10px 15px;
	color: white;
}

.holiday_main_container .panel .planet-signs > div {
	padding: 10px 15px;
}

.holiday_main_container .panel .planet-signs > div .articles-block {
	padding: 10px 0;
}
.holiday_main_container .panel .planet-signs > div .articles-block:first-child {
	padding: 0 0 10px;
}
.holiday_main_container .panel .planet-signs > div b {
	font-weight: normal;
}
.holiday_main_container .panel .planet-signs > div > b {
	display: block;
	text-align: right;
	padding: 14px 16px;
}

.holiday_main_container .panel .planet-signs .hindu {
	display: flex;
	justify-content: space-between;
}

.holiday_main_container .panel .planet-signs .hindu .text,
.holiday_main_container .panel .planet-signs .hindu .value {
	line-height: 26px;
	font-size: 14px;
}

.holiday_main_container .panel .planet-signs .hindu .value {
	width: 50%;
	font-size: 14px;
}

.holiday_main_container .panel .planet-signs .hindu .value .code_phone {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media (max-width: 1200px) {
	.holiday_main_container .panel {
		width: 100%;
		margin: 40px auto 0;
		max-width: unset;
	}

	.holiday_main_container .panel .planet-signs {
		width: auto;
	}
}

@media (max-width: 992px) {
	.holiday_main_container .panel {
		width: 100%;
	}
}
