.SunDetailsEclipses .eclipsesSolarLunar .eclipsesSolarLunarInfo {
    display: flex;
}
.SunDetailsEclipses .eclipsesSolarLunar .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide{
    flex-direction: column;
    width: 76%;
    padding: 0 1% 0 0;
}
.SunDetailsEclipses .eclipsesSolarLunar .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-city-information{
    box-shadow: none;
    background: none;
}
.sunCulcCityInfoRightSide .eclipse-info-block.eclipse-country{
    flex-wrap: wrap;
}
.sunCulcCityInfoRightSide .eclipse-info-block.eclipse-country a{
    width: auto !important;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info{
    margin: 0 !important;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .details-info{
    margin: 5px 0 20px;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-prev-cities-list{
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 10px;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipses-details{
    display: flex;
    margin: 0 0 20px;
    background: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipses-details .details-block{
    border-radius: 2px;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipses-details .details-block-information{
    width: 50%;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipses-details .eclipse-img{
    position: relative;
    width: 400px;
    max-width: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipses-details .eclipse-img img{
    max-width: 100%;
    height: auto;
    width: auto;
    padding: 10px;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipses-details .picture-eclipse-text{
    display: block;
    width: 100%;
    padding: 0 10px;
    text-align: center;
    margin: 0 0 10px;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info.city-lists{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info .eclipse-info-header{
    padding: 16px;
    background: #63697d;
    color: #fff;
    width: 100%;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info .eclipse-info-block.eclipse-country{
    width: 100%;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info .eclipse-info-block.eclipse-country > div{
    width: 33%;
}
.SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info .show-more{
    width: 100px;
    background: #4d8b87;
    color: #fff;
    border: 1px solid #4d8b87;
    border-radius: 2px;
    margin: 10px 16px;
    cursor: pointer;
}
.SunDetailsEclipses .sunCulcCityInfoRightSide .next-eclipse-year .next-eclipse-block{
    border: 1px solid #b0b1ac;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    border-radius: 2px;
    padding: 14px 16px;
}
.SunDetailsEclipses .sunCulcCityInfoRightSide .next-eclipse-year .eclipse-tittle{
    margin: 10px 0 !important;
}
.SunDetailsEclipses .sunCulcCityInfoRightSide .next-eclipse-year .eclipse-tittle:first-child{
    margin: 0 0 10px !important;
}
.SunDetailsEclipses .sunCulcCityInfoRightSide .next-eclipse-year b{
    padding: 5px 0;
}
.SunDetailsEclipses .eclipsesSolarLunar .eclipsesSolarLunarInfo .eclipses-img{
    width: 25%;
}
.SunDetailsEclipses .eclipsesSolarLunar .eclipsesSolarLunarInfo .eclipses-img > div{
    width: 100%;
}
.SunDetailsEclipses .eclipsesSolarLunar.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .eclipses-img .sunCulcCityInfoRightSide > div{
    margin: 0 0 20px !important;
}
.SunDetailsEclipses .eclipsesSolarLunar.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .eclipses-img .sunCulcCityInfoRightSide > div:last-child{
    margin: 0 !important;
}
.SunDetailsEclipses .eclipsesSolarLunar.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .eclipses-img .sunCulcCityInfoRightSide > div:first-child{
    margin: 0 0 20px !important;
}


.SunDetailsEclipses .eclipsesSolarLunar.eclipsesCitySolarLunar .next-eclipse{
    margin: 0;
}
.SunDetailsEclipses .eclipsesSolarLunar.eclipsesCitySolarLunar .sunCulcCityInfoRightSide .advertising-block-main{
    margin: 20px 0;
}

.eclipsesSolarLunar.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .eclipses-img .sunCulcCityInfoRightSide .eclipse-info > b{
    text-align: right;
    padding: 14px 16px;
}


@media screen and (max-width: 1250px) {
    .SunDetailsEclipses .eclipsesSolarLunar .eclipsesSolarLunarInfo{
        flex-direction: column;
    }
    .SunDetailsEclipses .eclipsesSolarLunar .eclipsesSolarLunarInfo > div{
        width: 100% !important;
    }
}
@media screen and (max-width: 1199px){
    .SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info .eclipse-info-block.eclipse-country > div{
        width: 50%;
    }
}

@media screen and (max-width: 820px) {
    .SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipses-details{
        flex-direction: column;
    }
    .SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipses-details > div{
        width: 100% !important;
        max-width: 100% !important;
    }
    .SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipses-details .eclipse-img{        
        align-items: center;
    }
    .SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipses-details .eclipse-img img{
        width: 300px;
        max-width: 100%;
    }
}
@media screen and (max-width: 769px) {    
    .SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info .eclipse-info-block.eclipse-country > div{
        width: 100%;
    }
}
@media (max-width: 768px) {
    .SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipses-details{
        flex-direction: column;
    }
    .SunDetailsEclipses .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipses-details .details-block-information{
        margin: 0 0 20px;
    }
}




