.main-article-page h1{
    width: 73%;
	margin: 0 0 20px;
	border-bottom: 1px solid #000;
}
.all_news_wrapper{
	display: flex;
}
.all_news_wrapper .main-articles-block{
    width: 75%;
	padding: 0 2% 0 0;
}
.all_news_list {
	margin: 0 0 20px;
}
.all_news_list h3{
	display: block;
	width: 45%;
	margin: 0 0 10px;
	border-bottom: 1px solid #000;
}
.all_news_list ul{
	display: flex;
	flex-wrap: wrap;
}
.all_news_list ul li{
	width: 45%;
	margin: 0 0 20px;
}
.all_news_list ul li:nth-child(3n-1){
	margin: 0 0 20px;
}
.all_news_list ul li:nth-child(odd){
	margin: 0 10% 20px 0;
}
/* .all_news_list ul li:nth-last-child(2){
	margin: 0 10% 0 0;
} */
.all_news_list ul li:nth-last-child(1){
	margin: 0;
}
.one_news_wrapper:hover{
	text-decoration: underline;
}
.one_news_wrapper a{
	display: flex;
}
.short_news_info h4{
	margin: 0 0 10px;
}
.articles_text_info.ellipsis {	
    height: 50px;
}
.articles_text_info.ellipsis:before {
	height: 57px;
}
.articles_text_info.ellipsis em{	
	font-style: normal
}
.one_news_wrapper:hover .articles_text_info.ellipsis em{
	text-decoration: underline;
}

.main-article-page .article-title{
	border-bottom: none;
}
.current_news_block table{
	overflow: auto;
    width: 100% !important;
}

.all_news_wrapper .right-sidebar{
	position: relative;
	width: 25%;
	min-width: 340px;
}
.all_news_wrapper .right-sidebar .loader-spinner{
	position: absolute;
}
.all_news_wrapper .right-sidebar .links.img-block{
	display: flex;
	flex-direction: column;
}
.all_news_wrapper .right-sidebar .links .eclipse-header{
	background: #ff7532;
	color: #444f4f;
}
.all_news_wrapper .right-sidebar .links.sun .eclipse-header{
	background: #fff492;
	color: #444f4f;
}
.all_news_wrapper .right-sidebar .links.moon .eclipse-header{
	background: #ade4f9;
	color: #444f4f;
}
.all_news_wrapper .right-sidebar .links.retrograde .eclipse-header{
	background: #ff7532;
	color: #fff;
}
.all_news_wrapper .right-sidebar .links > img{
	width: 100%;
	max-width: 355px;
	align-self: center;
}
.all_news_wrapper .right-sidebar .links > img.eclipse-planet{
	width: calc(100% - 32px);
}

@media(max-width: 1199px){
	.main-article-page h1,
	.all_news_list h3{
		width: 100%;
	}
	.all_news_list ul li{
		width: 100%;
		margin: 0 0 20px;
	}
	.all_news_list ul li:nth-child(3n-1){
		margin: 0 0 20px;
	}
	.all_news_list ul li:nth-child(odd){
		margin: 0 0 20px;
	}
}
@media(max-width: 769px){
	.all_news_wrapper{
		flex-direction: column;
	}
	.all_news_wrapper .main-articles-block{
		width: 100%;
	}
	.all_news_wrapper .right-sidebar{
		width: 100%;
		min-width: 100%;
	}
}
