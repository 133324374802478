.PlanetaryHoursTable{
    position: relative;
}
.PlanetaryHoursTable .loader-spinner {
    position: absolute;
    top: calc(50% - 40px);
    bottom: calc(50% - 40px);
}
.PlanetaryHoursTable > span{
    display: block;
    margin: 0 0 5px;
    color: #000;
    /* color: #a9a7a7; */
}
.PlanetaryHoursTable > span:last-child{
    margin: 0;
}
.PlanetaryHoursTable > span i{
    font-style: normal;
    color: #000;
    font-weight: bold;
}
.PlanetaryHoursTable .table{
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px;
}
.PlanetaryHoursTable .table .daily-hours .daily-hours-info{
    background: #fff590;
    padding: 10px 20px;
}
.PlanetaryHoursTable .table .daily-hours:last-child .daily-hours-info{
    background: #afe6fb;
}
.PlanetaryHoursTable .table > div {
    width: 48%;
}
.PlanetaryHoursTable .table > div > span {
    display: block;
    padding: 20px 0 10px;
    font-weight: bold;
    /* border-bottom: 4px solid #b0b2f1; */
    /* text-transform: uppercase; */
}
.PlanetaryHoursTable .table  > div .table-list {
    /* padding: 10px 20px; */
}
.PlanetaryHoursTable .table  > div .table-title{
    display: flex;
    margin: 0 0 10px;
}
.PlanetaryHoursTable .table  > div .table-title > div{
    text-align: center;
}
.PlanetaryHoursTable .table  > div .table-list .table-line {
    display: flex;
    margin: 0 0 10px;
    font-size: 16px;
    line-height: 18px;
}
.PlanetaryHoursTable .table  > div .table-list .table-line:last-child {
    margin: 0;
}
.PlanetaryHoursTable .table  > div .table-list .table-line > div {
    display: flex;
    align-items: center;
}
.PlanetaryHoursTable .table  .daily-hours .order-number {
    flex-basis: 30px;
    justify-content: center;
}
.PlanetaryHoursTable .table  .daily-hours .from-time-to-time {
    flex-direction: column;
    /* align-items: flex-start; */
    justify-content: center;
    flex-basis: calc(100% - 30px - 150px);
}
.PlanetaryHoursTable .table  .daily-hours .sign {
    align-items: flex-start;
    flex-basis: 150px;
    /* padding: 0 0 0 15px; */
}
.PlanetaryHoursTable .table  .daily-hours .sign img{
    width: 20px;
    margin: 0 5px 0 15px;
}
/* .PlanetaryHoursTable .table > div .table-list .table-line .from-time-to-time > div {
    display: flex;
}
.PlanetaryHoursTable .table > div .table-list .table-line .from-time-to-time > div span{
    display: block;
    width: 34px;
    margin: 0 10px 0 0;
    text-align: right;
}
.PlanetaryHoursTable .table > div .table-list .table-line .from-time-to-time > div b {    
    white-space: nowrap;
} */

.PlanetaryHoursTable .table {
    position: relative;
    min-height: 200px;
}
.PlanetaryHoursTable .table .loader-spinner{
    position: absolute;
    top: calc(calc(50% - 40px));
    bottom: calc(calc(50% - 40px));
}
.text-block-fish{
    padding: 0 30px;
}
.text-block-fish > h4{
    margin: 0 0 20px;
    font-size: 20px;
    line-height: 22px;
    font-weight: bold;
}
.text-block-fish > h4 b{
    display: block;
    text-align: center;
}
.text-block-fish p{
    margin: 0 0 30px;
    font-size: 18px;
    line-height: 20px;
}
.text-block-fish p:last-child{
    margin: 0;
}
.text-block-fish a{
    display: block;
    color: #3434e2;/*#556bb5;*/
}
.text-block-table{
    margin: 0 0 30px;
}
.text-block-table tr th,
.text-block-table tr td{
    text-align: left;
    font-size: 18px;
    line-height: 20px;

}
.text-block-table tr th:first-child,
.text-block-table tr td:first-child{
    padding: 0 10px 0 0;
}

@media (max-width: 1099px){
    .text-block-fish{
        margin: 0 0 20px;
    }
}
@media (max-width: 819px){
    .PlanetaryHoursTable .table {
        flex-direction: column;
    }
    .PlanetaryHoursTable .table > div{
        width: 100%;
    }
}