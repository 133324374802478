.PlanetaryHours {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
}

.PlanetaryHours .planetary-hours-content {
	display: flex;
	justify-content: space-between;
}

.PlanetaryHours .planetary-hours-content .PlanetaryHoursTable {
	width: 74%;
}

.PlanetaryHours .planetary-hours-content .PlanetaryHoursRightBlock {
	width: 25%;
}

@media screen and (max-width: 1099px) {
	.PlanetaryHours .planetary-hours-content {
		flex-direction: column;
	}
	.PlanetaryHours .planetary-hours-content .PlanetaryHoursTable,
	.PlanetaryHours .planetary-hours-content .PlanetaryHoursRightBlock {
		width: 100%;
	}
}
