.test-design {
	position: absolute;
	left: 0;
	width: 100%;
	min-height: 520px;
}
.Birthday {
	background: #ffebd0;
}

.gift {
	position: absolute;
	bottom: 0px;
	width: 150px;
	left: 172px;
	z-index: 12;
	height: 180px;
}
.wrap {
	height: 435px;
	width: 74%;
	margin: 0px auto;
	position: relative;
}
.ribbon_right {
	width: 45px;
	height: 45px;
	border-radius: 100% 100% 100% 0px;
	box-shadow: 0px 0px 0px 10px #1495f1 inset;
	position: absolute;
	right: 30px;
}
.ribbon_left {
	width: 45px;
	height: 45px;
	border-radius: 100% 100% 0px 100%;
	box-shadow: 0px 0px 0px 10px #1aa8fc inset;
	position: absolute;
	left: 30px;
}
.gift_box_top {
	height: 28px;
	top: 45px;
	position: absolute;
	width: 150px;
	background: #ff7d6d;
	box-shadow: -75px 0px 0px #ff6259 inset;
}
.gift_ribbon_center {
	width: 2px;
	bottom: 0px;
	position: absolute;
	right: 0;
	left: 0;
	margin: 0px auto;
	background: #1cadfe;
	height: 140px;
	border-right: 6px solid #28c9ff;
	border-left: 6px solid #28c9ff;
	z-index: 10;
}
.gift_box_bottom {
	background: #ff8168;
	bottom: 0px;
	height: 102px;
	overflow: hidden;
	right: 7px;
	position: absolute;
	width: 136px;
	box-shadow: -70px 0px 0px #fe6d68 inset;
	box-shadow: 0px 0px 17px #1cadfe;
	-webkit-animation: gift_box_shadowBlue 2s infinite;
	animation: gift_box_shadowBlue 2s infinite;
}
@-webkit-keyframes gift_box_shadowBlue {
	50% {
		box-shadow: 0px 0px 0px #1cadfe;
	}
}
@keyframes gift_box_shadowBlue {
	50% {
		box-shadow: 0px 0px 0px #1cadfe;
	}
}
.gift_box_bottom_top {
	height: 5px;
	width: 136px;
	right: 7px;
	position: absolute;
	bottom: 102px;
	background: #dd4b4c;
}
.gift_ribbon_left {
	height: 55px;
	width: 15px;
	left: 15px;
	position: absolute;
	background: #28c9ff;
	top: 40px;
	z-index: 11;
}
.gift_ribbon_left:after {
	border-width: 9px;
	border-style: solid;
	border-color: transparent;
	border-bottom-color: #ff8168;
	z-index: 12;
	position: absolute;
	content: "";
	bottom: 0px;
}
.gift_box_bottom_ribbon {
	position: absolute;
	right: -25px;
	height: 12px;
	width: 110px;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	background: #ff4149;
}
#ribbon1 {
	top: -15px;
}
#ribbon2 {
	top: 20px;
}
#ribbon3 {
	top: 55px;
}
#ribbon4 {
	top: 90px;
}
.line {
	height: 25px;
	width: 100%;
	background: #fff;
	bottom: -25px;
	position: absolute;
}
.cake {
	position: absolute;
	bottom: 0px;
	width: 330px;
	right: 172px;
	height: 240px;
}
.cake_bottom {
	position: absolute;
	width: 330px;
	height: 5px;
	bottom: 0px;
	background: #9dc5c4;
	border-top: 2px solid #e6e7e9;
}
.cake_bottom2 {
	position: absolute;
	width: 320px;
	bottom: 7px;
	background: #cd7a1c;
	height: 22px;
	right: 5px;
	border-radius: 3px 3px 0px 0px;
	left: 5px;
	box-shadow: -160px 0px 0px #ad631c inset;
}
.cake_bottom3 {
	box-shadow: 0px -3px 0px #fddbea, -155px 0px 0px #5a4027 inset;
	height: 58px;
	border-top: 5px solid #ff7ebe;
	background: #744c28;
	position: absolute;
	width: 310px;
	bottom: 29px;
	right: 10px;
	border-radius: 5px 5px 0px 0px;
	left: 10px;
}
.cake_top1 {
	box-shadow: -115px 0px 0px #ff9201 inset;
	height: 49px;
	background: #ffb009;
	position: absolute;
	width: 230px;
	bottom: 89px;
	right: 50px;
	border-radius: 5px 5px 0px 0px;
	left: 50px;
	border-bottom: 3px solid #c14500;
}
.cake_top2 {
	box-shadow: -70px 0px 0px #5b3f29 inset;
	height: 52px;
	background: #744c28;
	position: absolute;
	width: 140px;
	bottom: 138px;
	border-radius: 5px 5px 0px 0px;
	left: 0px;
	right: 0px;
	margin: 0px auto;
}
.cake_line {
	bottom: 0px;
	height: 30px;
	width: 5px;
	position: absolute;
}
#cake_line1 {
	left: 2px;
	background: #ff9101;
}
#cake_line2 {
	left: 12px;
	background: #ff9101;
}
#cake_line3 {
	left: 22px;
	background: #ff9101;
}
#cake_line4 {
	left: 32px;
	background: #ff9101;
}
#cake_line5 {
	left: 42px;
	background: #ff9101;
}
#cake_line6 {
	left: 52px;
	background: #ff9101;
}
#cake_line7 {
	left: 62px;
	background: #ff9101;
}
#cake_line8 {
	left: 72px;
	background: #ff9101;
}
#cake_line9 {
	left: 82px;
	background: #ff9101;
}
#cake_line10 {
	left: 92px;
	background: #ff9101;
}
#cake_line11 {
	left: 102px;
	background: #ff9101;
}
#cake_line12 {
	left: 112px;
	background: #ff9101;
}
#cake_line13 {
	right: 3px;
	background: #ff7000;
}
#cake_line14 {
	right: 13px;
	background: #ff7000;
}
#cake_line15 {
	right: 23px;
	background: #ff7000;
}
#cake_line16 {
	right: 33px;
	background: #ff7000;
}
#cake_line17 {
	right: 43px;
	background: #ff7000;
}
#cake_line18 {
	right: 53px;
	background: #ff7000;
}
#cake_line19 {
	right: 63px;
	background: #ff7000;
}
#cake_line20 {
	right: 73px;
	background: #ff7000;
}
#cake_line21 {
	right: 83px;
	background: #ff7000;
}
#cake_line22 {
	right: 93px;
	background: #ff7000;
}
#cake_line23 {
	right: 103px;
	background: #ff7000;
}
#cake_line24 {
	right: 113px;
	background: #ff7000;
}
.cherry {
	width: 15px;
	top: -15px;
	height: 15px;
	border-radius: 100%;
	background: #fff;
	position: absolute;
	box-shadow: -2px -1px 0px 6px #ff4d59 inset;
}
#cherry1 {
	left: 20px;
}
#cherry2 {
	left: 64px;
}
#cherry3 {
	left: 108px;
}
#cherry4 {
	right: 64px;
}
#cherry5 {
	right: 20px;
}
.cake_circle {
	height: 56px;
	width: 56px;
	border-radius: 100%;
	box-shadow: 0px 0px 0px 12px #ffebc8 inset;
	top: -30px;
	z-index: 100;
	position: absolute;
}
.circles {
	position: absolute;
	height: 30px;
	width: 100%;
	overflow: hidden;
	top: 0px;
}
#circle1 {
	left: -1px;
}
#circle2 {
	left: 43px;
}
#circle3 {
	left: 87px;
}
#circle4 {
	right: 43px;
}
#circle5 {
	right: -1px;
}
.cake_top1_creams {
	height: 18px;
	border-radius: 5px;
	background: #f24282;
	position: absolute;
	top: 0px;
	width: 100%;
}
.cake_top1_cream1 {
	position: absolute;
	left: -3px;
	height: 25px;
	width: 15px;
	border-radius: 9px;
	background: #f14380;
}
.cake_top1_cream2 {
	position: absolute;
	left: 12px;
	height: 25px;
	width: 10px;
	border-radius: 9px;
	background: #744c28;
	top: 10px;
}
.cake_top1_cream3 {
	position: absolute;
	left: 22px;
	height: 23px;
	width: 10px;
	border-radius: 9px;
	background: #f14380;
}
.cake_top1_cream4 {
	position: absolute;
	left: 32px;
	height: 25px;
	width: 12px;
	border-radius: 9px;
	background: #744c28;
	top: 13px;
}
.cake_top1_cream5 {
	position: absolute;
	left: 44px;
	height: 30px;
	width: 13px;
	border-radius: 9px;
	background: #f14380;
}
.cake_top1_cream6 {
	position: absolute;
	left: 57px;
	height: 25px;
	width: 13px;
	border-radius: 9px;
	background: #744c28;
	top: 9px;
}
.cake_top1_cream7 {
	position: absolute;
	right: -3px;
	height: 22px;
	width: 8px;
	border-radius: 9px;
	background: #f14380;
}
.cake_top1_cream8 {
	position: absolute;
	right: 5px;
	height: 25px;
	width: 10px;
	border-radius: 9px;
	background: #5b3f29;
	top: 8px;
}
.cake_top1_cream9 {
	position: absolute;
	right: 15px;
	height: 31px;
	width: 10px;
	border-radius: 9px;
	background: #f14380;
}
.cake_top1_cream10 {
	position: absolute;
	right: 25px;
	height: 15px;
	width: 10px;
	border-radius: 9px;
	background: #5b3f29;
	top: 13px;
}
.cake_top1_cream11 {
	position: absolute;
	right: 35px;
	height: 24px;
	width: 13px;
	border-radius: 9px;
	background: #f14380;
}
.cake_top1_cream12 {
	position: absolute;
	right: 48px;
	height: 15px;
	width: 12px;
	border-radius: 9px;
	background: #5b3f29;
	top: 10px;
}
.cake_top1_cream13 {
	position: absolute;
	right: 60px;
	height: 26px;
	width: 10px;
	border-radius: 9px;
	background: #f14380;
}
.cake_bottom3_creams {
	height: 22px;
	border-radius: 5px;
	background: #ff7fbf;
	position: absolute;
	top: -5px;
	width: 100%;
}
.cake_bottom3_cream1 {
	position: absolute;
	left: -3px;
	height: 35px;
	width: 18px;
	border-radius: 9px;
	background: #ff7fbf;
}
.cake_bottom3_cream2 {
	position: absolute;
	left: 15px;
	height: 24px;
	width: 15px;
	border-radius: 9px;
	top: 12px;
	background: #744c28;
}
.cake_bottom3_cream3 {
	position: absolute;
	left: 30px;
	height: 38px;
	width: 10px;
	border-radius: 9px;
	background: #ff7fbf;
}
.cake_bottom3_cream4 {
	position: absolute;
	left: 40px;
	height: 24px;
	width: 15px;
	border-radius: 9px;
	top: 15px;
	background: #744c28;
}
.cake_bottom3_cream5 {
	position: absolute;
	left: 55px;
	height: 30px;
	width: 15px;
	border-radius: 9px;
	background: #ff7fbf;
}
.cake_bottom3_cream6 {
	position: absolute;
	left: 70px;
	height: 20px;
	width: 12px;
	border-radius: 100px;
	top: 17px;
	background: #744c28;
}
.cake_bottom3_cream7 {
	position: absolute;
	left: 82px;
	height: 40px;
	width: 18px;
	border-radius: 100px;
	background: #ff7fbf;
}
.cake_bottom3_cream8 {
	position: absolute;
	left: 100px;
	height: 20px;
	width: 22px;
	border-radius: 100px;
	top: 10px;
	background: #744c28;
}
.cake_bottom3_cream9 {
	position: absolute;
	left: 122px;
	top: 0px;
	height: 67px;
	width: 24px;
	border-radius: 100px;
	background: #ff7fbf;
	z-index: 3;
}
.cake_bottom3_cream10 {
	position: absolute;
	left: 146px;
	height: 20px;
	width: 9px;
	border-radius: 100px;
	top: 16px;
	background: #744c28;
}
.cake_bottom3_cream11 {
	position: absolute;
	right: 140px;
	height: 37px;
	width: 15px;
	border-radius: 9px;
	background: #ff7fbf;
}
.cake_bottom3_cream12 {
	position: absolute;
	right: 128px;
	height: 23px;
	width: 12px;
	border-radius: 100px;
	top: 13px;
	background: #5b3f27;
}
.cake_bottom3_cream13 {
	position: absolute;
	left: 116px;
	height: 59px;
	width: 36px;
	border-radius: 100px;
	background: #744c28;
	z-index: 2;
	top: 15px;
}
.cake_bottom3_cream14 {
	position: absolute;
	top: 0px;
	right: 108px;
	height: 64px;
	width: 20px;
	border-radius: 9px;
	background: #ff7fbf;
	z-index: 3;
}
.cake_bottom3_cream15 {
	position: absolute;
	right: 102px;
	height: 55px;
	width: 31px;
	border-radius: 100px;
	background: #5a4027;
	z-index: 2;
	top: 15px;
}
.cake_bottom3_cream16 {
	position: absolute;
	right: 93px;
	height: 23px;
	width: 15px;
	border-radius: 100px;
	top: 8px;
	background: #5b3f27;
}
.cake_bottom3_cream17 {
	position: absolute;
	right: 78px;
	height: 47px;
	width: 15px;
	border-radius: 9px;
	background: #ff7fbf;
}
.cake_bottom3_cream18 {
	position: absolute;
	right: 70px;
	height: 23px;
	width: 8px;
	border-radius: 100px;
	top: 16px;
	background: #5b3f27;
}
.cake_bottom3_cream19 {
	position: absolute;
	right: 58px;
	height: 30px;
	width: 12px;
	border-radius: 9px;
	background: #ff7fbf;
}
.cake_bottom3_cream20 {
	position: absolute;
	right: 43px;
	height: 23px;
	width: 15px;
	border-radius: 100px;
	top: 8px;
	background: #5b3f27;
}
.cake_bottom3_cream21 {
	position: absolute;
	right: 28px;
	height: 38px;
	width: 15px;
	border-radius: 9px;
	background: #ff7fbf;
}
.cake_bottom3_cream22 {
	position: absolute;
	right: 10px;
	height: 23px;
	width: 18px;
	border-radius: 100px;
	top: 14px;
	background: #5b3f27;
}
.cake_bottom3_cream23 {
	position: absolute;
	right: -3px;
	height: 30px;
	width: 13px;
	border-radius: 9px;
	background: #ff7fbf;
}
.cake_bottom1_creams {
	height: 10px;
	position: absolute;
	bottom: 0px;
	width: 100%;
}
.cake_bottom1_cream1 {
	position: absolute;
	left: 56px;
	height: 6px;
	width: 14px;
	border-radius: 20px 20px 0px 0px;
	background: #5a4027;
	bottom: 0px;
}
.cake_bottom1_cream2 {
	position: absolute;
	left: 115px;
	height: 5px;
	width: 10px;
	border-radius: 20px 20px 0px 0px;
	background: #5a4027;
	bottom: 0px;
}
.cake_bottom1_cream3 {
	position: absolute;
	right: 33px;
	height: 8px;
	width: 14px;
	border-radius: 20px 20px 0px 0px;
	background: #744c29;
	bottom: 0px;
}
.cake_bottom1_cream4 {
	position: absolute;
	right: 58px;
	height: 5px;
	width: 11px;
	border-radius: 20px 20px 0px 0px;
	background: #744c29;
	bottom: 0px;
}
.gift_top {
	-webkit-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;
	o-transition: all 0.6s ease-in-out;
	width: 100%;
	position: absolute;
	top: 0px;
	-webkit-transition-delay: 0.6s;
	transition-delay: 0.6s;
}
.gift:hover .gift_top {
	top: -120px;
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}
.gift_ribbon_left {
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	o-transition: all 0.3s ease-in-out;
	-webkit-transition-delay: 1s;
	transition-delay: 1s;
}
.gift_ribbon_center {
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	o-transition: all 0.3s ease-in-out;
	-webkit-transition-delay: 1s;
	transition-delay: 1s;
}
.gift:hover .gift_ribbon_left {
	height: 32px;
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
}
.gift:hover .gift_ribbon_center {
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
	height: 102px;
}
.gift_box_bottom_top {
	-webkit-transition-delay: 1.1s;
	transition-delay: 1.1s;
}
.gift:hover .gift_box_bottom_top {
	height: 0px;
	opacity: 0;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	-webkit-transition-delay: 0.4s;
	transition-delay: 0.4s;
	o-transition: all 0.3s ease-in-out;
}
.giftcard {
	border-radius: 5px;
	background: #fff;
	box-shadow: 0px 1px 0px 1px #fee4c0;
	padding: 10px;
	width: 80px;
	font-size: 15px;
	font-family: "Source Sans Pro", sans-serif;
	color: #ff3f48;
	left: 0px;
	top: 55px;
	right: 0px;
	margin: 0px auto;
	position: absolute;
	line-height: 26px;
	z-index: -1;
	text-align: center;
	-webkit-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
	o-transition: all 0.6s ease-in-out;
}
.gift:hover .giftcard {
	top: -35px;
}
.one_number {
	position: absolute;
	left: 147px;
	top: 15px;
	width: 9px;
}
.one_number:after {
	content: "";
	height: 5px;
	width: 5px;
	position: absolute;
	background: #c6c6c6;
	top: 0px;
	left: 0px;
}
.one_number:before {
	content: "";
	height: 25px;
	width: 5px;
	position: absolute;
	background: #c6c6c6;
	top: 0px;
	right: 0px;
}

.one_flame {
	height: 12px;
	width: 6px;
	border-radius: 100%;
	background: #ffd215;
	position: absolute;
	right: 1px;
	bottom: 2px;
	-webkit-animation: flame 0.5s infinite linear;
	animation: flame 0.5s infinite linear;
	-moz-animation: flame 0.5s infinite linear;
}
@-webkit-keyframes flame {
	0% {
		height: 12px;
		background: #fdd214;
	}
	50% {
		height: 14px;
		background: #ffc617;
	}
	100% {
		height: 12px;
		background: #fdd214;
	}
}
@keyframes flame {
	0% {
		height: 12px;
		background: #fdd214;
	}
	50% {
		height: 14px;
		background: #ffc617;
	}
	100% {
		height: 12px;
		background: #fdd214;
	}
}
@-moz-keyframes flame {
	0% {
		height: 12px;
		background: #fdd214;
	}
	50% {
		height: 14px;
		background: #ffc617;
	}
	100% {
		height: 12px;
		background: #fdd214;
	}
}

/*candle*/

.cera {
	position: absolute;
	top: 160px;
	left: 65%;
	width: 18px;
	height: 80px;
	background: #fff;
	border-radius: 5px 5px 0 0;
}

.cera::after {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 6px;
	height: 80px;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 0 5px 0px 0;
	content: "";
}

.pavio {
	position: absolute;
	top: 143px;
	left: 65.4%;
	width: 4px;
	height: 18px;
	border-radius: 3px 3px 0 0;
	background: #ad88a9;
}

.chama {
	position: absolute;
	width: 28px;
	height: 50px;
	top: 105px;
	left: 64.7%;
	background: #ffad00;
	opacity: 0.9;

	border-radius: 16px 16px 16px 16px / 40px 40px 16px 16px;
	animation: wind 30s ease-in-out infinite, size 40s ease-in-out infinite,
		flickr 40s ease-in-out infinite;
	transform-origin: 30% 100%;
}

.chama::before {
	position: absolute;
	width: 20px;
	height: 25px;
	bottom: 0px;
	left: 50%;
	margin-left: -10px;
	background: #ffe75e;
	opacity: 0.3;
	border-radius: 16px 16px 16px 16px / 40px 40px 16px 16px;
	animation: wind 30s ease-in-out infinite, size 40s ease-in-out infinite,
		flickr 10s ease-in-out infinite;
	transform-origin: 30% 100%;
	content: "";
}
.chama::after {
	position: absolute;
	width: 16px;
	height: 20px;
	bottom: 0px;
	left: 50%;
	margin-left: -8px;
	background: #ff8624;
	opacity: 0.3;
	border-radius: 16px 16px 16px 16px / 40px 40px 16px 16px;
	animation: wind 30s ease-in-out infinite, size 40s ease-in-out infinite,
		flickr 10s ease-in-out infinite;
	transform-origin: 30% 100%;
	content: "";
}

@keyframes wind {
	0%,
	20%,
	40%,
	60%,
	80%,
	100% {
		border-radius: 4px 28px 16px 16px / 40px 40px 16px 16px;
	}
	10%,
	30%,
	50%,
	70%,
	90% {
		border-radius: 24px 4px 16px 16px / 40px 40px 16px 16px;
	}
}

@keyframes size {
	0%,
	20%,
	40%,
	60%,
	80%,
	100% {
		transform: scale(1, 1);
	}
	10%,
	30%,
	50%,
	70%,
	90% {
		transform: scale(0.9, 1.3);
	}
}

@keyframes flickr {
	0%,
	20%,
	40%,
	60%,
	80%,
	100% {
		box-shadow: 0 0 25px 0 rgba(255, 202, 0, 0.7);
	}
	10%,
	30%,
	50%,
	70%,
	90% {
		box-shadow: 0 0 65px 0 rgba(255, 202, 0, 0.8);
	}
}
