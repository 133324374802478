.pages-select {
	width: 25%;
	min-width: 340px;
	margin: 0 2% 0 0;
}
.pages-select > div {
	display: flex;
	flex-direction: column;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}

.pages-select .user-name {
	margin: 0 0 20px;
}
.pages-select .user-name > div {
	padding: 20px;
}
.pages-select .user-name > div:first-child {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.pages-select .user-name > div:last-child {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.pages-select .user-name > div:last-child span {
	margin: 0 0 10px;
	color: #8a8a8a;
}
.pages-select .user-name > div:last-child a {
	padding: 6px 10px;
	color: #fff;
	background: #ff7532;
	text-align: center;
	cursor: pointer;
}

.pages-select .page-types .page-types_icon {
	margin-right: 5px;
}

.pages-select .page-types button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	background: #fff;
	color: #8a8a8a;
	border: 1px solid transparent;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	cursor: pointer;
}
.pages-select .page-types button:last-child {
	border-bottom: 1px solid transparent;
}
.pages-select .page-types button:hover {
	background: #008854;
	color: #fff;
	border: 1px solid #008854;
}
