.block-countdown-comp{
    /*border: 1px solid indianred;*/
}
.title-countdown-comp{
    /*border: 1px solid mediumseagreen;*/
    padding-top: 10px;
    padding-bottom: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 500;
}
.block-collection {
    min-height: 20px;
    background-color: #444;
    padding-bottom: 20px;
}
.row-block-collection{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.block-item{
    border: 3px solid #444;
    width: 15%;
    min-height: 92px;
    display: flex;
    flex-direction : column;
    margin-left: 18px;
    margin-top: 18px;
}
.block-item:hover{
    border: 3px solid navajowhite;
}
.block-item:hover .name-item{
    background: #fff;
}
.block-item-select{
    border: 3px solid navajowhite;
}
.block-item-select > .name-item{
    background: #fff;
}

.image-item{
    height: 75%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
.name-item{
    height: 25%;
    width: 100%;
    padding: 3px 0px 3px 0px;
    display: flex;
    justify-content: center;
    background: #eee;
}
.name-item > p{
    color: #169bd5;
}

.row-block-collection > .block-item > .housing{
    background-image: url('../../../../../../assets/CountdownTimer/Housing.png');
}
.row-block-collection > .block-item > .starwars{
    background-image: url('../../../../../../assets/CountdownTimer/StarWars.png');
}
.row-block-collection > .block-item > .airplane{
    background-image: url('../../../../../../assets/CountdownTimer/Airplane.png');
}
.row-block-collection > .block-item > .halloween{
    background-image: url('../../../../../../assets/CountdownTimer/Halloween.png');
}
.row-block-collection > .block-item > .birthday{
    background-image: url('../../../../../../assets/CountdownTimer/Birthday.png');
}
.row-block-collection > .block-item > .newyear{
    background-image: url('../../../../../../assets/CountdownTimer/NewYear.png');
}

.row-block-collection > .block-item > .undefined{
    background-image: url('../../../../../../assets/CountdownTimer/undefined.png');
}