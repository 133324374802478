.weather-collapse {
  width: 100%;
  background: #aca2ff;
}
.weather-collapse .weather-block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.weather-collapse .weather-block-header h3 {
  color: #fff;
  font-size: 20px;
}
.weather-collapse .weather-block-header a {
  color: #3434e2;
  font-size: 14px;
  text-decoration: underline;
}
.weather-collapse .city-info-map-blocks {
  overflow: hidden;
  background: #fff;
  transition: 0.5s;
}
.CityInfoMapBlocks .city-info-map-blocks {
  display: flex;
  justify-content: space-between;
  flex-flow: column wrap;
  margin: 0 0 20px;
}
.CityInfoMapBlocks .city-info-map-blocks.city-info-map-blocks_updated {
  margin: 30px 0;
  box-shadow: none;
  padding: 0;
}
.CityInfoMapBlocks .city-info-map-blocks > div {
  display: flex;
  flex-direction: column;
  flex-basis: 31.111111%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  margin: 0;
  padding: 20px;
}

.dummy-img {
  max-height: 200px;
}

.MainPage .CityInfoMapBlocks .InformationBlocks > div > .currentCityInfo {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.MainPage
  .CityInfoMapBlocks
  .InformationBlocks
  > div
  > .currentCityInfo.world-clock-info
  a:last-child {
  margin: 0;
}
.MainPage
  .CityInfoMapBlocks
  .InformationBlocks
  > div
  > .currentCityInfo.world-clock-info
  .hover_effect {
  min-height: auto;
  text-align: center;
}
.MainPage
  .CityInfoMapBlocks
  .InformationBlocks
  > div
  > .currentCityInfo.world-clock-info
  .hover_effect
  b
  strong {
  display: inline-block;
  width: 110px;
  text-align: right;
}
.MainPage
  .CityInfoMapBlocks
  .InformationBlocks
  .enlarge_clock
  .currentCityFooter {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 5px 0 0;
  background: transparent;
}
.MainPage
  .CityInfoMapBlocks
  .InformationBlocks
  .enlarge_clock
  .currentCityFooter
  a {
  padding: 5px;
  color: #65c5a1;
  border: 1px solid #fff;
  background-color: white;
  margin: 0 5px;
  font-weight: bold;
  text-align: center;
}
.CityInfoMapBlocks .city-info-map-blocks .enlarge_clock,
.CityInfoMapBlocks .city-info-map-blocks .enlarge_clock .currentCityInfo {
  background: #65c5a1;
}
.CityInfoMapBlocks .city-info-map-blocks .enlarge_clock .clock_block {
  margin: -10px 0 10px;
}
.MainPage
  .CityInfoMapBlocks
  .city-info-map-blocks
  .enlarge_clock
  .timezone_block {
  margin: 0 0 10px;
}
.CityInfoMapBlocks .city-info-map-blocks .enlarge_clock .clock_block,
.CityInfoMapBlocks .city-info-map-blocks .enlarge_clock .timezone_block {
  display: flex;
  justify-content: space-between;
}
.CityInfoMapBlocks .city-info-map-blocks .enlarge_clock .timezone_block {
  margin: 0 0 20px;
}
.CityInfoMapBlocks .city-info-map-blocks .enlarge_clock .clock {
  width: 49%;
}
.CityInfoMapBlocks .city-info-map-blocks .enlarge_clock .clock .city-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 58px;
  margin: 15px 0 10px;
}
.MainPage
  .CityInfoMapBlocks
  .city-info-map-blocks
  .enlarge_clock
  .clock
  .city-name {
  margin: 0 0 -5px;
}
.CityInfoMapBlocks .city-info-map-blocks .enlarge_clock .clock .city-name h4 {
  text-align: center;
}
.MainPage .CityInfoMapBlocks .city-info-map-blocks .hover_effect {
  background: #fdeded;
  padding: 5px 10px;
}
.MainPage .CityInfoMapBlocks .city-info-map-blocks .city-info .hover_effect {
  margin: 0 0 20px !important;
}
.MainPage
  .CityInfoMapBlocks
  .city-info-map-blocks
  .city-info
  .hover_effect:last-child {
  margin: 0 !important;
}
.MainPage .CityInfoMapBlocks .city-info-map-blocks .city-info .hover_effect b {
  display: flex;
  margin: 15px 0 0;
}
.MainPage
  .CityInfoMapBlocks
  .city-info-map-blocks
  .city-info
  .hover_effect.currency_block
  .currency {
  display: flex;
  flex-direction: column;
}
.CityInfoMapBlocks .city-info-map-blocks .planet-info > h3 {
  display: none;
}
.MainPage
  .CityInfoMapBlocks
  .city-info-map-blocks
  .city-info
  .hover_effect.currency_block
  .currency
  i {
  display: flex;
  justify-content: space-between;
  font-style: normal;
}
.MainPage
  .CityInfoMapBlocks
  .city-info-map-blocks
  .city-info
  .hover_effect.currency_block
  .currency
  i
  em {
  width: 120px;
  font-style: normal;
}
.MainPage
  .CityInfoMapBlocks
  .city-info-map-blocks
  .city-info
  .hover_effect.currency_block
  .currency
  i
  em.symbol {
  font-size: 20px;
  line-height: 22px;
}
.MainPage
  .CityInfoMapBlocks
  .city-info-map-blocks
  .city-info
  .hover_effect
  b:first-child {
  margin: 0;
}
.MainPage
  .CityInfoMapBlocks
  .city-info-map-blocks
  .hover_effect
  b
  span:first-child {
  width: 100px;
}
.MainPage
  .CityInfoMapBlocks
  .city-info-map-blocks
  .hover_effect
  b
  span:last-child {
  width: calc(100% - 100px);
}
.MainPage .CityInfoMapBlocks .city-info-map-blocks .hover_effect b span i {
  font-style: normal;
}

.CityInfoMapBlocks .city-info-map-blocks .enlarge_clock .clock .hover_effect {
  text-align: center;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .enlarge_clock
  .clock
  .hover_effect
  strong {
  display: block;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin: 0 0 10px;
}
.CityInfoMapBlocks .city-info-map-blocks .enlarge_clock .clock .hover_effect b {
  display: block;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .enlarge_clock
  .clock
  .hover_effect
  b
  strong {
  font-size: 18px;
  line-height: 20px;
}
.MainPage
  .CityInfoMapBlocks
  .city-info-map-blocks
  .enlarge_clock
  .clock
  .hover_effect
  em {
  display: block;
  min-height: 14px;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .enlarge_clock
  .clock
  .hover_effect
  em {
  font-style: normal;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
}
.CityInfoMapBlocks .city-info-map-blocks .city-info,
.CityInfoMapBlocks .city-info-map-blocks .city-info .currentCityInfo {
  background: #54d4e2;
  margin: 30px 0;
}
.CityInfoMapBlocks .city-info-map-blocks .sun-moon-info,
.CityInfoMapBlocks .city-info-map-blocks .sun-moon-info .currentCityInfo {
  background: #ffe082;
}
.CityInfoMapBlocks
  .city-info-map-blocks.InformationBlocks
  .city-info
  .currentCityInfo
  .hover_effect {
  background: #b3e6fd;
}
.CityInfoMapBlocks
  .city-info-map-blocks.InformationBlocks
  .city-info
  .currentCityInfo
  .hover_effect
  b
  > span:first-child {
  font-weight: 700;
}
.CityInfoMapBlocks
  .city-info-map-blocks.InformationBlocks
  > div
  > .currentCityInfo
  .hover_effect {
  margin: 0 0 4px;
}
.CityInfoMapBlocks .city-info-map-blocks > div .currentCityInfo {
  background: #fff;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .enlarge_clock
  .clock-container.styling {
  height: 100px;
  width: 100px;
  margin: 0 auto 10px;
}
.CityInfoMapBlocks .city-info-map-blocks .enlarge_clock a {
  display: block;
  text-align: right;
  color: #3434e2;
}
.CityInfoMapBlocks .city-info-map-blocks .enlarge_clock .hover_effect a b {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}
.CityInfoMapBlocks b {
  font-weight: normal;
}
.CityInfoMapBlocks .timezone-block-wrapper {
  display: flex;
  margin: 0 20px 20px;
}
.CityInfoMapBlocks .timezone-block-wrapper .timezone-block div:last-child {
  padding: 0 !important;
}
.CityInfoMapBlocks .timezone-block-wrapper > b {
  padding: 0 20px 0 0;
}
.CityInfoMapBlocks .timezone-block-wrapper b em {
  font-style: normal;
  margin: 0 0 0 5px;
}
.CityInfoMapBlocks .some-text {
  font-style: normal;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  padding: 10px 20px;
  margin: 0 0 20px;
}
.CityInfoMapBlocks .city-info-map-blocks .weather-block {
  width: 100%;
  flex-basis: 65.572222%;
  box-shadow: none;
  overflow: visible;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  padding: 0;
}
.CityInfoMapBlocks .city-info-map-blocks .sun-moon-block-wrapper {
  background: #ffe083;
  margin: 0 0 10px;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-block-wrapper
  .sun-moon-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-block-wrapper
  .sun-moon-block
  > div {
  width: 100%;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-block-wrapper
  .sun-moon-block
  .sun-block {
  margin: 0 0 20px;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-block-wrapper
  .sun-moon-block
  .info-block {
  display: flex;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-block-wrapper
  .sun-moon-block
  .info-block
  .img-block {
  position: relative;
  flex-basis: calc(40% - 10px);
  margin: 0 10px 0 0;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-block-wrapper
  .sun-moon-block
  .info-block
  .img-block
  img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 100%;
  width: 100%;
  padding: 0;
  object-fit: cover;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-block-wrapper
  .sun-moon-block
  .info-block
  .info {
  flex-basis: 60%;
  margin: 0 0 10px 0;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-block-wrapper
  .sun-moon-block
  .info-block
  .info
  b {
  display: flex;
  justify-content: space-between;
  margin: 13px 0;
  font-size: 16px;
  line-height: 18px;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-block-wrapper
  .sun-moon-block
  .info-block
  .info
  b
  span {
  font-weight: 700;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-block-wrapper
  .sun-moon-block
  .info-block:last-child
  .img-block {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #3434e2;
  text-align: center;
  text-decoration: underline;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-block-wrapper
  .sun-moon-block
  .info-block:last-child
  .info
  b {
  justify-content: center;
  text-align: center;
  margin: 0;
  color: #3434e2;
}
.sunMoonLine {
  background: #fff;
  height: 3px;
  margin: 0 0 20px;
  width: 100%;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-block-wrapper
  .sun-moon-block
  .moon-block
  .info-block
  .info {
  order: 1;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-block-wrapper
  .sun-moon-block
  .moon-block
  .info-block
  .img-block {
  order: 2;
  margin: 0 0 0 10px;
}
.CityInfoMapBlocks .city-info-map-blocks .sun-moon-info .currentCityFooter {
  background: #fdeded;
  padding: 5px;
  display: block;
}
.CityInfoMapBlocks .city-info-map-blocks .sun-moon-info .currentCityFooter b {
  display: block;
  margin: 0 0 10px;
}
.CityInfoMapBlocks .city-info-map-blocks .sun-moon-info .currentCityFooter a {
  color: #3434e2;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-info
  .currentCityFooter
  span {
  display: block;
  margin: 0 0 5px;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .sun-moon-info
  .currentCityFooter
  .equinoxes-solstices {
  display: block;
  margin: 5px 0 0;
}

.CityInfoMapBlocks
  .city-info-map-blocks.InformationBlocks
  .CalculatorsAndTimers.AddBlock
  > .currentCityHeader
  .currentCityTittle {
  width: 100%;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .enlarge_clock
  .clock
  .hover_effect.time-difference {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.CityInfoMapBlocks
  .city-info-map-blocks
  .enlarge_clock
  .clock
  .hover_effect.time-difference
  img {
  margin: 0 0 5px;
  max-width: 100%;
  max-width: 100%;
  height: 65px;
}
.CityInfoMapBlocks .city-info-map-blocks .upcoming-holidays {
  max-width: 434px;
  width: 100%;
  padding: 0;
  box-shadow: none;
}
.CityInfoMapBlocks .city-info-map-blocks .upcoming-holidays img {
  height: 320px;
  margin-bottom: 50px;
}
.CityInfoMapBlocks .city-info-map-blocks .upcoming-holidays > div {
  padding: 20px;
}
.InformationBlocks > div > .currentCityInfo .time-difference em {
  display: block;
}
.airports-city-name {
  display: block;
}
.currentCityInfo .alter_names_more {
  color: #3434e2;
  padding-left: 5px;
}
.currentCityInfo .alter_names_more:hover {
  cursor: pointer;
  text-decoration: underline;
}
.under_link_city-info {
  text-align: right !important;
}
.under_link_city-info {
  margin: 0;
}
.under_link_city-info a {
  color: #3434e2 !important;
  font-style: normal;
}
.weather-bloc-wrap {
  position: relative;
}

.MainPage .advertising-block-header {
  margin: 0 0 10px;
}
.MainPage .advertising-block-header .advertising-header {
  justify-content: center;
}
.MainPage > h3,
.MainPage .CityInfoMapBlocks > h3 {
  text-align: center;
}
.MainPage .CityInfoMapBlocks > h3 {
  margin: 0 0 20px;
}

.MainPage .CityInfoMapBlocks .meeting-planner {
  background-color: #b5ced4;
  padding: 20px;
}
.MainPage .CityInfoMapBlocks .meeting-planner-box {
  background-color: #fff;
  padding: 14px;
}
.MainPage .CityInfoMapBlocks .meeting-planner-box_top {
  margin-bottom: 20px;
  padding: 14px 0 4px 0;
}
.MainPage .CityInfoMapBlocks .meeting-planner__title {
  display: inline;
  font-size: 18px;
  font-weight: 400;
  padding-right: 20px;
  padding-bottom: 4px;
  margin-left: 14px;
  border-bottom: 2px solid #000;
}
.MainPage .CityInfoMapBlocks .meeting-planner__desc {
  margin: 16px 0 18px 14px;
  font-size: 14px;
}
.MainPage .CityInfoMapBlocks .meeting-planner img {
  width: 100%;
}
.MainPage .CityInfoMapBlocks .meeting-planner-features__title {
  font-size: 18px;
  margin-bottom: 14px;
}
.MainPage .CityInfoMapBlocks .meeting-planner-features {
  list-style: disc;
  padding-left: 20px;
}
.MainPage .CityInfoMapBlocks .meeting-planner-features li {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 8px;
}
.MainPage .CityInfoMapBlocks .meeting-planner__button {
  padding: 3px;
  display: block;
  border: 2px solid;
  font-weight: 700;
  margin: 12px auto 0;
  max-width: 180px;
  text-align: center;
  text-decoration: none;
}

@media screen and (min-width: 1200px) {
  .CityInfoMapBlocks .city-info-map-blocks .planet-info .ephemeris {
    max-width: 65.572222%;
  }
}

@media screen and (min-width: 1081px) {
  .CityInfoMapBlocks .city-info-map-blocks .planet-info > h3 {
    display: block;
  }
}
@media screen and (min-width: 1039px) {
  .CityInfoMapBlocks .city-info-map-blocks .weather-collapse {
    margin: 30px 0;
  }
  .CityInfoMapBlocks
    .city-info-map-blocks
    .weather-collapse
    .city-info-map-blocks {
    margin: 0;
  }
  .CityInfoMapBlocks .city-info-map-blocks .planet-info {
    flex-flow: row wrap;
    flex-basis: 100%;
    padding: 0;
    box-shadow: none;
  }
  .CityInfoMapBlocks .city-info-map-blocks .planet-info h3 {
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }
  .CityInfoMapBlocks .city-info-map-blocks .planet-info .ephemeris {
    flex-basis: 100%;
  }
  .CityInfoMapBlocks .city-info-map-blocks.InformationBlocks {
    flex-flow: row wrap;
  }
  .CityInfoMapBlocks .city-info-map-blocks.world-clock-links-block {
    flex-flow: row wrap;
  }
  .CityInfoMapBlocks .city-info-map-blocks.world-clock-links-block > div {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 750px) and (max-width: 1038px) {
  .CityInfoMapBlocks .city-info-map-blocks,
  .MainPage .CityInfoMapBlocks .city-info-map-blocks.world-clock-links-block {
    flex-flow: row wrap;
  }
  .CityInfoMapBlocks .city-info-map-blocks .enlarge_clock,
  .CityInfoMapBlocks .city-info-map-blocks .city-info,
  .CityInfoMapBlocks .city-info-map-blocks .sun-moon-info,
  .CityInfoMapBlocks .city-info-map-blocks .panchang {
    max-width: 49%;
    flex-basis: 100%;
  }
  .CityInfoMapBlocks
    .city-info-map-blocks
    .weather-collapse
    .city-info-map-blocks {
    margin: 0;
  }

  .CityInfoMapBlocks .city-info-map-blocks .planet-info {
    flex-basis: 100%;
  }

  .CityInfoMapBlocks .city-info-map-blocks .planet-info {
    max-width: 100%;
    padding-top: 10px;
    margin: 0 !important;
  }
  .CityInfoMapBlocks .city-info-map-blocks .planet-info .panchang {
    display: none;
  }
  .CityInfoMapBlocks .city-info-map-blocks .planet-info .ephemeris {
    flex-flow: row wrap;
    flex-basis: 100%;
    justify-content: space-between;
  }
  .CityInfoMapBlocks
    .city-info-map-blocks
    .planet-info
    .ephemeris
    .EphemerisForToday,
  .CityInfoMapBlocks
    .city-info-map-blocks
    .planet-info
    .ephemeris
    .planet-links {
    max-width: 49%;
  }
}

@media screen and (max-width: 1038px) {
  .MainPage .CityInfoMapBlocks > h3 {
    display: none;
  }
  .CityInfoMapBlocks .city-info-map-blocks > div {
    box-shadow: none;
  }
  .CityInfoMapBlocks .city-info-map-blocks .planet-info {
    padding: 10px 0 0;
    flex-flow: row wrap;
  }
  .CityInfoMapBlocks .city-info-map-blocks .panchang {
    width: 100%;
  }
  .CityInfoMapBlocks .city-info-map-blocks .planet-info h3 {
    width: 100%;
    font-size: 1.1em;
    text-align: center;
    margin-bottom: 20px;
  }
  .CityInfoMapBlocks
    .city-info-map-blocks
    .weather-collapse
    .city-info-map-blocks {
    margin: 0;
  }
  .CityInfoMapBlocks .city-info-map-blocks .weather-block {
    flex-basis: 100%;
  }
  .CityInfoMapBlocks .city-info-map-blocks .world-clock-links {
    max-width: none;
  }
  .CityInfoMapBlocks
    .city-info-map-blocks.world-clock-links-block
    .world-clock-links {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1080px) {
  .CityInfoMapBlocks .city-info-map-blocks .enlarge_clock,
  .CityInfoMapBlocks .city-info-map-blocks .sun-moon-info,
  .MainPage .CityInfoMapBlocks .meeting-planner,
  .CityInfoMapBlocks .city-info-map-blocks .panchang,
  .CityInfoMapBlocks .city-info-map-blocks .city-info,
  .CityInfoMapBlocks .city-info-map-blocks .weather-collapse,
  .CityInfoMapBlocks .city-info-map-blocks.city-info-map-blocks_updated,
  .CityInfoMapBlocks .city-info-map-blocks.world-clock-links-block {
    flex-basis: 49%;
    margin: 0;
  }
  .CityInfoMapBlocks .city-info-map-blocks.world-clock-links-block {
    box-shadow: none;
    padding: 0;
  }
  .CityInfoMapBlocks
    .city-info-map-blocks.world-clock-links-block
    .world-clock-links {
    flex-basis: 100%;
  }
  .CityInfoMapBlocks .city-info-map-blocks .city-info-map-blocks {
    margin-top: 20px;
  }
  .CityInfoMapBlocks
    .city-info-map-blocks
    .world-clock-links.world-clock-links_bottom {
    margin-bottom: 0;
  }
  .CityInfoMapBlocks .city-info-map-blocks.world-clock-links-block > img {
    width: 100%;
    max-height: 250px;
    margin-bottom: 20px;
  }
  .CityInfoMapBlocks .city-info-map-blocks .upcoming-holidays {
    max-width: none;
    width: 100%;
  }
  .CityInfoMapBlocks .city-info-map-blocks .panchang {
    padding: 0 0 10px;
  }
  .CityInfoMapBlocks .city-info-map-blocks .upcoming-holidays img {
    height: 390px;
  }
}

@media screen and (max-width: 767px) {
  .MainPage .advertising-block-header .advertising-header {
    display: block;
  }
}

@media screen and (max-width: 750px) {
  .CityInfoMapBlocks .city-info-map-blocks .enlarge_clock,
  .CityInfoMapBlocks .city-info-map-blocks .sun-moon-info,
  .MainPage .CityInfoMapBlocks .meeting-planner,
  .CityInfoMapBlocks .city-info-map-blocks .panchang,
  .CityInfoMapBlocks .city-info-map-blocks .city-info,
  .CityInfoMapBlocks .city-info-map-blocks .weather-collapse,
  .CityInfoMapBlocks .city-info-map-blocks.city-info-map-blocks_updated,
  .CityInfoMapBlocks .city-info-map-blocks.world-clock-links-block {
    flex-basis: 100%;
    margin-bottom: 20px;
    width: 100%;
  }
}

@media all and (orientation: landscape) and (max-width: 740px) {
  .CityInfoMapBlocks .city-info-map-blocks {
    flex-flow: row wrap;
  }
  .CityInfoMapBlocks .city-info-map-blocks .enlarge_clock,
  .CityInfoMapBlocks .city-info-map-blocks .sun-moon-info,
  .MainPage .CityInfoMapBlocks .meeting-planner,
  .CityInfoMapBlocks .city-info-map-blocks .panchang,
  .CityInfoMapBlocks .city-info-map-blocks .city-info,
  .CityInfoMapBlocks .city-info-map-blocks .weather-collapse,
  .CityInfoMapBlocks .city-info-map-blocks.city-info-map-blocks_updated,
  .CityInfoMapBlocks .city-info-map-blocks.world-clock-links-block {
    flex-basis: 49%;
  }
  .CityInfoMapBlocks .city-info-map-blocks .planet-info {
    flex-basis: 100%;
  }
  .CityInfoMapBlocks .city-info-map-blocks .planet-info .ephemeris {
    flex-flow: row wrap;
  }
  .planet-info .ephemeris .planet-links,
  .planet-info .ephemeris .EphemerisForToday {
    width: 49% !important;
    margin-bottom: 0 !important;
  }
  .CityInfoMapBlocks .city-info-map-blocks.world-clock-links-block {
    flex-flow: column;
  }
  .MainPage .almahac-title.almahac-title_mob {
    display: none !important;
  }
  .MainPage .almahac-title.almahac-title_desk {
    display: block !important;
  }
}

@media screen and (max-width: 499px) {
  .MainPage .advertising-block-header .advertising-header {
    display: none;
  }
}
@media screen and (max-width: 414px) {
  .MainPage
    .CityInfoMapBlocks
    .city-info-map-blocks
    .city-info
    .hover_effect.currency_block
    .currency
    i
    em {
    width: auto;
  }
  .under_link_city-info .currency {
    width: 100% !important;
  }
  .under_link_city-info span:first-child {
    width: auto !important;
  }
}
@media screen and (max-width: 367px) {
  .MainPage .CityInfoMapBlocks .city-info-map-blocks .hover_effect {
    min-height: 74px;
  }
}
