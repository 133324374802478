.panchanga_page {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
}

.panchanga_page .pt-1 {
	padding-top: 10px;
}

.panchanga_page .pt-2 {
	padding-top: 20px;
}

.panchanga_page .pb-1 {
	padding-bottom: 10px;
}

.panchanga_page .w-100 {
	width: 100%;
}

.panchanga_page h5 {
	line-height: 25px;
}

.panchanga_page .header_page .citySunRiseSet {
	background: #ffb61e;
}

.panchanga_page .calendar_page .RaisedButton {
	max-width: 144px !important;
}

.panchanga_page .calendar_page .RaisedButton span {
	text-transform: none !important;
}

.panchanga_page .calendar_page .equinox-solstice-name {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100% !important;
	margin: 0 7px !important;
}

.panchanga_page .calendar_page .equinox-solstice-name > span {
	margin-bottom: 2px;
}

.panchanga_page
	.calendar_page
	.right-calendar-block
	.equinox-solstice-name
	.RaisedButton
	button:hover {
	background-color: #f5f5f5 !important;
}

.panchanga_page .content {
	display: flex;
	max-width: 100%;
	flex-wrap: wrap;
}

.panchanga_page .content .main {
	width: 75%;
	margin-bottom: 10px;
	order: 1;
}

.panchanga_page .content .panel {
	width: 25%;
	order: 2;
}

.panchanga_page .content .panel .planet-signs {
	margin-bottom: 40px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
	background: #f5f5f5;
}

.panchanga_page .content .panel .planet-signs .head {
	background: #ffb61e;
	padding: 10px 15px;
}

.panchanga_page .content .panel .planet-signs > div {
	padding: 10px 15px;
}

.panchanga_page .content .panel .planet-signs > div .articles-block {
	padding: 10px 0;
}
.panchanga_page
	.content
	.panel
	.planet-signs
	> div
	.articles-block:first-child {
	padding: 0 0 10px;
}
.panchanga_page .content .panel .planet-signs > div b {
	font-weight: normal;
}
.panchanga_page .content .panel .planet-signs > div > b {
	display: block;
	text-align: right;
	padding: 14px 16px;
}

.panchanga_page .content .panel .planet-signs .hindu {
	display: flex;
	justify-content: space-between;
}

.panchanga_page .content .panel .planet-signs .hindu .text,
.value {
	font-size: 12px;
	line-height: 26px;
}

.panchanga_page .content .panel .block_baner {
	display: flex;
	justify-content: center;
}

.panchanga_page .content .panel .block_baner .baner {
	width: 100%;
	padding: 0 2%;
}

/*                   DASHBOARD                     */

.panchanga_page .content .main .dashboard {
	padding: 0 20px;
}

.panchanga_page .content .main .dashboard .main_block {
	background: #e4e4e4;
	min-height: 372px;
}

.panchanga_page .content .main .dashboard .main_block .panchang-content {
	display: flex;
	justify-content: center;
	font-size: 16px;
	line-height: 20px;
	height: 375px;
	align-items: center;
}

.panchanga_page .content .main .dashboard .main_block .panchang-content span {
	text-align: center;
	width: 260px;
	display: inline-block;
	font-weight: 600;
	line-height: 32px;
}

.panchanga_page .content .main .dashboard .main_block .top {
	margin: 0 5% 0 15%;
}

.panchanga_page .content .main .dashboard .main_block .top .date {
	display: flex;
	/*justify-content: space-around;*/
	padding: 10px 0px;
	margin-bottom: 15px;
}

.panchanga_page .content .main .dashboard .main_block .top .date div {
	display: flex;
	justify-content: center;
	width: 25%;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.top
	.date
	div:nth-child(2) {
	display: flex;
	justify-content: center;
	width: 50%;
}

.panchanga_page .content .main .dashboard .main_block .top .date .middle_data {
	font-weight: 500;
	font-size: 16px;
}

.panchanga_page .content .main .dashboard .main_block .top .shedule {
	/*border: 1px solid rosybrown;*/
	display: flex;
	position: relative;
}

.panchanga_page .content .main .dashboard .main_block .top .shedule label {
	position: absolute;
	left: -18px;
	top: -18px;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.top
	.shedule
	.six_hour_top {
	height: 15px;
	border-left: 1px solid gray;
	width: 12.5%;
	position: relative;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.top
	.shedule
	.six_hour_top_last {
	height: 15px;
	border-left: 1px solid gray;
	position: relative;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.top
	.shedule
	.sunrise_line_top {
	position: absolute;
	height: 15px;
	border-right: 2px solid red;
}

.panchanga_page .content .main .dashboard .main_block .middle {
	padding-bottom: 16px;
}

.panchanga_page .content .main .dashboard .main_block .middle .board {
	display: flex;
	width: 95%;
	flex-wrap: wrap;
}

.panchanga_page .content .main .dashboard .main_block .middle .board .left_bar {
	/*border: 1px solid red;*/
	/*width: 17%;*/
	/*text-align: center;*/
	justify-content: space-evenly;
	display: flex;
	/*border: 1px solid red;*/
	flex-direction: column;
	width: 15.8%;
	height: 250px;
	text-align: center;
	position: relative;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.left_bar
	h5 {
	/*padding-top: 10px;*/
	margin: 15px 0;
	padding-top: 2px;
	position: relative;
}

.panchanga_page .content .main .dashboard .main_block .middle .board .schedule {
	width: 84.2%;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	overflow: hidden;
	height: 290px;
	padding-bottom: 27px;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.sunrise_line {
	border-right: 2px solid red;
	position: absolute;
	height: 264px;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.sunrise_line
	> span {
	width: 87px;
	bottom: -24px;
	position: absolute;
	color: red;
	font-size: 11px;
	left: -41px;
	text-align: center;
	line-height: 12px;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.six_hour {
	border-right: 1px solid gray;
	border-left: 1px solid gray;
	width: 12.5%;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.yellow {
	background: #f7f7ad;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.blue {
	background: #7ccaf9;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.background_nearby {
	position: absolute;
	height: 17%;
	width: 100%;
	opacity: 1;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.nakhsatra {
	background: #9980fa;
	top: 10px;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.yoga {
	background: #1d89ff;
	top: 70px;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.tithi {
	background: #f39c12;
	top: 130px;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.karana {
	background: #2ecc71;
	top: 190px;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.nearby {
	position: absolute;
	height: 17%;
	justify-content: center;
	align-items: center;
	display: flex;
	/*border-right: 1px solid black;*/
	border-left: 1px solid black;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.nearby
	.title {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: whitesmoke;
	/*font-size: 12px;*/
	font-size: 10px;
	text-align: center;
	/*padding-top: 5px;*/
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.nearby
	.title
	.name {
	font-size: 14px;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.dhanisha {
	position: absolute;
	background: violet;
	height: 17%;
	top: 25px;
}

.panchanga_page
	.content
	.main
	.dashboard
	.main_block
	.middle
	.board
	.schedule
	.shatabhisha {
	position: absolute;
	background: #ba3ffc;
	height: 17%;
	top: 25px;
}

.panchanga_page .content .main .below_info {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 0 20px;
	margin-top: 20px;
}

.panchanga_page .content .main .below_info .local {
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-size: 12px;
	line-height: 14px;
	margin: 0;
	height: 40px;
}

.panchanga_page .content .main .below_info .local span {
	color: #3434e2; /*#556bb5;*/
	margin: 0 3px;
}

/*                       CONSTELLATION                    */

.panchanga_page .content .main .constellation {
	padding: 0 20px;
	position: relative;
}

.panchanga_page .content .main .constellation h4 {
	margin-bottom: 10px;
	font-weight: bold;
}

.panchanga_page .content .main .constellation .describe {
	padding-left: 3%;
}

.panchanga_page .content .main .constellation .describe .longer {
	font-size: 18px;
	line-height: 20px;
}

.panchanga_page .content .main .constellation .row {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.panchanga_page .content .main .constellation .row_center {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.panchanga_page .content .main .constellation .row_center .balam_block {
	/*display: flex;*/
	/*flex-direction: column;*/
	/*width: 73%;*/
	/*align-items: center;*/

	display: flex;
	flex-direction: column;
	/*max-width: 550px;*/
	max-width: 600px;
	align-items: flex-start;
}

.panchanga_page
	.content
	.main
	.constellation
	.row_center
	.balam_block
	.balam_list {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.panchanga_page
	.content
	.main
	.constellation
	.row_center
	.balam_block
	.balam_list
	.col {
	width: 25%;
}

.panchanga_page .content .main .constellation .row_center .row_choghadiya {
	display: flex;
	justify-content: space-around;
	width: 100%;
	flex-wrap: wrap;
}

.panchanga_page .content .main .constellation .row_center .choghadiya_col {
	width: 45%;
	/*border: 1px solid black;*/
}

.panchanga_page
	.content
	.main
	.constellation
	.row_center
	.choghadiya_col
	.title {
	padding: 5px 10px;
	border: 1px solid black;
}

.panchanga_page .content .main .constellation .row_center .choghadiya_col .day {
	background: #ffec58;
}

.panchanga_page
	.content
	.main
	.constellation
	.row_center
	.choghadiya_col
	.night {
	background: #aee4fa;
}

.panchanga_page
	.content
	.main
	.constellation
	.row_center
	.choghadiya_col
	.row_table {
	width: 100%;
	display: flex;
}

.panchanga_page
	.content
	.main
	.constellation
	.row_center
	.choghadiya_col
	.row_table
	.col_table {
	width: 32%;
	border: none;
	border-right: 1px solid black;
	border-left: 1px solid black;
	border-bottom: 1px solid black;
	color: white;
}

.panchanga_page
	.content
	.main
	.constellation
	.row_center
	.choghadiya_col
	.row_table
	.col_table
	h5 {
	font-size: 13px;
}

.panchanga_page
	.content
	.main
	.constellation
	.row_center
	.choghadiya_col
	.row_table
	.col_table
	.subtype {
	font-size: 11px;
}

.panchanga_page
	.content
	.main
	.constellation
	.row_center
	.choghadiya_col
	.row_table
	.col_table:nth-child(1) {
	padding: 5px 10px;
	width: 36%;
}

.panchanga_page
	.content
	.main
	.constellation
	.row_center
	.choghadiya_col
	.row_table
	.col_table:nth-child(2) {
	text-align: center;
	padding-top: 5px;
}

.panchanga_page
	.content
	.main
	.constellation
	.row_center
	.choghadiya_col
	.row_table
	.col_table:nth-child(3) {
	padding: 5px 15px;
}

.panchanga_page .content .main .constellation .row .col {
	/*width: 50%;*/
	display: flex;
	flex-direction: column;
	width: 48%;
	line-height: 24px;
	white-space: nowrap;
}

.panchanga_page .content .main .constellation .row .col .arrow {
	font-size: 18px;
	line-height: 13px;
}

.panchanga_page .content .main .constellation .row .col .row {
	display: flex;
	justify-content: flex-start;
}

.panchanga_page .content .main .constellation .row .col .row .name {
	margin-right: 5px;
	min-width: 61px;
	padding-top: 0;
}

.panchanga_page .content .main .constellation .row .col .row .name h5 {
	line-height: 25px;
}

.panchanga_page .content .main .constellation .row .col-br-none {
	border: none !important;
}

.panchanga_page .content .main .constellation .row .first {
	margin-right: 8px;
}

.panchanga_page .content .main .constellation .row .second {
	padding-left: 5%;
}

@media (max-width: 1420px) {
	.panchanga_page .content .main .constellation .row .second {
		padding-left: 2%;
	}
}

@media (max-width: 1200px) {
	.calendar_page .right-calendar-block {
		width: 80%;
	}

	.panchanga_page .content .main .constellation .row .col {
		width: 70%;
	}

	.panchanga_page .content .main .constellation .row .col {
		padding-top: 10px;
	}

	.panchanga_page .content .main .constellation .row .first {
		margin-right: 0;
	}

	.panchanga_page .content .main .constellation .row .second {
		padding-left: 0;
	}

	.panchanga_page .content .main .constellation .row_center .choghadiya_col {
		width: 65%;
		margin-bottom: 10px;
	}
}

@media (max-width: 992px) {
	.panchanga_page .content .panel .block_baner .baner {
		width: auto;
	}

	.panchanga_page .content .main .dashboard .main_block .top {
		margin: 0 2% 0 2.2%;
	}

	.panchanga_page .content .main .dashboard .main_block .middle .board {
		width: 98%;
		position: relative;
		padding-left: 2%;
	}

	.panchanga_page
		.content
		.main
		.dashboard
		.main_block
		.middle
		.board
		.left_bar {
		width: 8.8%;
		position: absolute;
		top: -28px;
		color: black;
		z-index: 1;
	}

	.panchanga_page
		.content
		.main
		.dashboard
		.main_block
		.middle
		.board
		.schedule {
		width: 100%;
	}

	.panchanga_page .content .main .dashboard {
		padding: 0;
	}

	.panchanga_page .content .main {
		width: 100%;
		order: 2;
	}

	.panchanga_page .content .panel {
		width: 100%;
		order: 1;
	}
}

@media (max-width: 850px) {
	.panchanga_page .content .main .dashboard {
		overflow-x: scroll;
	}

	.panchanga_page .content .main .dashboard .main_block {
		width: 801px;
	}

	.panchanga_page .content .main .constellation .row_center .choghadiya_col {
		width: 75%;
	}

	.panchanga_page .content .main .constellation .row_center .balam_block {
		width: 90%;
	}
}

@media (max-width: 699px) {
	.panchanga_page .calendar_page .equinox-solstice-name {
		width: 45% !important;
		margin: 5px 0 !important;
	}

	.calendar_page .right-calendar-block {
		justify-content: center !important;
	}

	.panchanga_page .content .main .constellation .row .col {
		width: 95%;
	}

	.panchanga_page .content .main .constellation .row_center .choghadiya_col {
		width: 99%;
	}

	.panchanga_page
		.content
		.main
		.constellation
		.row_center
		.balam_block
		.balam_list
		.col {
		width: 50%;
	}
}

@media (max-width: 420px) {
	.panchanga_page .calendar_page .equinox-solstice-name {
		width: 100% !important;
	}

	.panchanga_page .content .main .constellation .row .col {
		white-space: normal;
	}
}
