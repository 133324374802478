.WorldClock {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}
.WorldClock .world-clock-header {
  background: #008854;
  color: #fff;
  padding: 10px 30px 2px;
  margin: 0 0 20px;
}
.WorldClock .world-clock-header h1 {
  margin: 0 0 5px;
  font-size: 24px;
  line-height: 26px;
  font-weight: 400;
}
.WorldClock .world-clock-header h3 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
}
.WorldClock .world-clock-header .select-block h3 {
  width: 650px;
  max-width: 100%;
}
.WorldClock .world-clock-header .select-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.WorldClock .world-clock-header .select-block .check-block {
  display: flex;
  align-items: center;
  width: 530px;
  max-width: 100%;
}
.WorldClock .world-clock-header .select-block .radio-check > div {
  display: flex;
}
.WorldClock
  .world-clock-header
  .select-block
  .check-block
  .world-clock-checkbox {
  width: 150px !important;
}
.WorldClock
  .world-clock-header
  .select-block
  .check-block
  .world-clock-checkbox
  > div
  > div
  > div
  svg {
  color: #fff !important;
  fill: #fff !important;
}
.WorldClock
  .world-clock-header
  .select-block
  .check-block
  .world-clock-checkbox
  label {
  color: #fff !important;
  width: auto !important;
  margin: 0 !important;
}
.WorldClock .world-clock-header .select-block .check-block hr {
  border-top: none rgb(224, 224, 224) !important;
  border-left: none rgb(224, 224, 224) !important;
  border-right: none rgb(224, 224, 224) !important;
  border-bottom: 1px solid rgb(224, 224, 224) !important;
}
.WorldClock .world-clock-header .select-block .check-block .sort-list {
  width: 120px !important;
  margin-left: auto;
  overflow: hidden;
}
.WorldClock .world-clock-header .select-block .check-block .sort-list div {
  color: #fff !important;
}
.WorldClock .world-list {
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px;
}
.WorldClock .world-list > div {
  /* border-left: 1px solid #ccc; */
  border-top: 1px solid #ccc;
  /* border-bottom: 1px solid #ccc; */
  width: 33.33333333333333333%;
}
.WorldClock .world-list > div:first-child > div {
  border-left: 1px solid #ccc;
}
.WorldClock .world-list > div > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.WorldClock .world-list > div > div:nth-child(2n) {
  background: #f1eeee;
  /* border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc; */
}
.WorldClock .world-list > div > div a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.WorldClock .world-list > div > div span:last-child {
  display: flex;
  justify-content: flex-end;
  min-width: 134px;
  height: 16px;
  padding: 0 0 0 5px;
}
.WorldClock .world-list > div > div span:last-child .imgBlock {
  position: relative;
  width: 16px;
  margin: 0 auto 0 0;
}
.WorldClock .world-list > div > div span:last-child .imgBlock img {
  height: 100%;
}
.WorldClock .world-list > div > div span:last-child .imgBlock div {
  display: none;
  position: absolute;
  bottom: calc(100% + 10px);
  right: -5px;
  /* right: -10px; */
  width: 260px;
  padding: 5px;
  background: #d2f4ff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 2px;
  text-align: center;
  z-index: 4;
}
.WorldClock .world-list > div > div span:last-child .imgBlock div:before,
.WorldClock .world-list > div > div span:last-child .imgBlock div:after {
  content: "";
  position: absolute;
  top: 100%;
  right: 0;
  border: 12px solid transparent;
  border-top: 10px solid rgba(0, 0, 0, 0.87);
  /* background: #c3f0ffcc; */
  /* transform: rotate(47deg); */
}
.WorldClock .world-list > div > div span:last-child .imgBlock div:after {
  top: calc(100% - 1px);
  border-top: 10px solid #d2f4ff;
}
.WorldClock .world-list > div > div span:last-child .imgBlock:hover div {
  display: block;
}
.WorldClock .adjasted-dst {
  display: flex;
  align-items: center;
  padding: 0 10%;
  padding: 0 3%;
  margin: 0 0 30px;
}
.WorldClock .adjasted-dst img {
  width: 25px;
  margin: 0 5px 0 0;
}

@media screen and (max-width: 1199px) {
  .WorldClock .world-clock-header .select-block {
    flex-direction: column;
  }
  .WorldClock .world-list > div {
    width: 50%;
  }
}
@media screen and (max-width: 769px) {
  .WorldClock .world-list > div {
    width: 100%;
  }
  .WorldClock .adjasted-dst {
    padding: 0;
  }
}
@media screen and (max-width: 529px) {
  .WorldClock .world-clock-header .select-block h3 {
    margin: 0 0 10px;
  }
  .WorldClock .world-clock-header .select-block .check-block {
    flex-direction: column;
    align-items: flex-start;
  }
  .WorldClock .world-clock-header .select-block .check-block .sort-list {
    margin: 0;
  }
}
