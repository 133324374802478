.rowHolidays:hover{
    background-color: #e9e9e9;
}
.cellHolidays_date{
    padding: 10px 10px 10px 10px;
    background-color: rgba(255, 243, 134, 0.6);
    text-align: right !important;
}
.cellHolidays_date > p {
    font-weight: bold;
}
.cellHolidays {
    padding: 5px 10px 5px 10px;
    text-align: left !important;
}
.rowHolidaysTitleTable{
    padding-top: 10px;
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
    justify-content: center;
    color: #7f7f7f;
}

.Holidays .geotimedate-table .sticky-table-row .sticky-table-cell {
    font-size: 16px !important;
}