.MoonRiseDayParts {
    width: 50%;
    min-width: 487px;
    margin: 0 auto 30px;
}
.MoonRiseDayParts .swiper-pagination,
.MoonRiseDayParts .swiper-button-prev,
.MoonRiseDayParts .swiper-button-next{
    display: none;
}

@media (max-width: 517px) {
    .MoonRiseDayParts {
        width: 100%;
        min-width: 100%;
    }
}
@media (max-width: 499px) {
    .MoonRiseDayParts .swiper-pagination,
    .MoonRiseDayParts .swiper-button-prev,
    .MoonRiseDayParts .swiper-button-next{
        display: block;
    }
}