.speace-date-event{
    height: 5px;
}
.row-block-event{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.row-block-event > .image > i{
    font-size: 70px;
}
.row-block-event > .info {
    padding-left: 10px;
}
.row-block-event > .info > h5{
    padding-top: 5px;
    padding-bottom: 5px;
}
.block-countdown-comp > .info-block > .row-block-event > .info > .row-block-event > .input-date {
    justify-content: flex-start;
}