.row-creat-title {
  display: flex;
  justify-content: flex-start;
}
.column-creat-title {
  width: 70%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.column-creat-title > .input {
  display: flex;
  justify-content: flex-start;
}
.column-style-font {
  width: 30%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.column-style-font > .input {
  display: flex;
  justify-content: flex-start;
}
.blokc-preview-title {
  width: 100%;
  min-height: 40px;
  background-color: #444;
  padding: 20px 20px 20px 20px;
}
.blokc-preview-title > p {
  color: white;
  font-size: 25px;
}
