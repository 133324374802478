.geotimedate-table{
    /* width: 100%; */
    border-spacing: 0;
}
.geotimedate-table .sticky-table-header-wrapper{
    background: #ffeb3b;
}
.geotimedate-table .sticky-table-row .sticky-table-cell:last-child{
    border-right: 1px solid #ccc;
}
.geotimedate-table .sticky-table-row .sticky-table-cell{
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    font-size: 12px;
    line-height: 14px;
}
.geotimedate-table .sticky-table-y-wrapper .sticky-table-row:last-child .sticky-table-cell{
    border-bottom: 1px solid #ccc;
}
.geotimedate-table .sticky-table-y-wrapper .sticky-table-row:first-child .sticky-table-cell{
    height: 0 !important;
    padding: 0 !important;
    border: none !important;
}

.geotimedate-table .sticky-table .y-scrollbar{
    display: none;
}

.Retrograde .planets-list b,
.Retrograde .planets-list b span{
    font-weight: normal;
}
.Retrograde .planets-list b span{
    text-transform: capitalize;
    cursor: pointer;
    color: #9ebce0;
}
.Retrograde .planets-list b span:hover{
    color: #85aad6;
}



/* .SunData .geotimedate-table .sticky-table-header-wrapper .sticky-table-corner .cell-wrapper .sun-twilight-info{
    padding: 0;
} */
/* .SunData .geotimedate-table .sticky-table-header-wrapper .sticky-table-header .sticky-table-table{
    width: 100%;
} */
/* .SunData .geotimedate-table .sticky-table-header-wrapper .sticky-table-corner,
.SunData .geotimedate-table .sticky-table-column{
    width: 37px;
} */
/* .MoonData .geotimedate-table .sticky-table-header-wrapper .sticky-table-corner,
.MoonData .geotimedate-table .sticky-table-column{
    width: 38px;
} */
/* .SunData .geotimedate-table .sticky-table-corner div,
.SunData .geotimedate-table .sticky-table-column div{
    max-width: 100%;
} */
/* .SunData .geotimedate-table .sticky-table-header-wrapper .sticky-table-header{
    width: calc(100% - 55px);
}
.MoonData .geotimedate-table .sticky-table-header-wrapper .sticky-table-header{
    width: calc(100% - 56px);
} */

.SunData {    
    margin: 0 0 30px;
}
.geotimedate-table .sticky-table-corner .sticky-table-row .sticky-table-cell:last-child,
.geotimedate-table .sticky-table-column .sticky-table-row .sticky-table-cell:last-child{
    border-right: none;
}
.geotimedate-table .sticky-table-header-wrapper .sticky-table-row .sticky-table-cell{
    padding: 0;
    background: #ffeb3b;
}
.geotimedate-table .sticky-table-row .sticky-table-cell,
.geotimedate-table .sticky-table-header-wrapper .sticky-table-row .sticky-table-cell{
    vertical-align: middle;
    text-align: center;
}
.geotimedate-table .sticky-table-header-wrapper .sticky-table-cell .cell-wrapper > b{
    display: block;
    padding: 0;
    font-weight: 600;
}
.geotimedate-table .sticky-table-header-wrapper .sticky-table-cell .cell-wrapper > b em{
    display: block;
    font-style: normal;
}
.geotimedate-table .sticky-table-header-wrapper .sticky-table-cell .cell-wrapper > b:first-child{
    flex-direction: column;
    padding: 0 5px;
}
.geotimedate-table .sticky-table-header-wrapper .sticky-table-cell .cell-wrapper > b:first-child{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
}
.geotimedate-table .sticky-table-row .sticky-table-cell span{
    display: inline-flex;
    align-items: center;
    width: 50%;
    padding: 2px;
    justify-content: center;
}
.geotimedate-table .sticky-table-y-wrapper .sticky-table-row .sticky-table-cell span{
    height: 34px;
    padding: 3px 2px;
}
.geotimedate-table .sticky-table-row .sticky-table-cell span:last-child{
    border-left: 1px solid #ccc;
}
.geotimedate-table .sticky-table-row .sticky-table-cell p:first-child{
    padding: 3px 2px 0 2px;
}
.geotimedate-table .sticky-table-row .sticky-table-cell p:last-child{
    padding: 0 2px 3px 2px;
}
.geotimedate-table .sticky-table-row .sticky-table-cell b{
    padding: 3px 2px;
    font-weight: normal;
}
.geotimedate-table .sticky-table-column .sticky-table-row .sticky-table-cell b{
    font-weight: 600;
}
.geotimedate-table_sun-notice {
    position: relative;
    z-index: 5;
    margin-top: 5px;
    font-weight: 700;
    font-size: 12px;
}
.geotimedate-table_sun-notice span{
    text-decoration: underline;
}
.geotimedate-table_sun-notice__under-datepicker {
    margin: 0;
    padding-left: 2em;
}
.geotimedate-table_moon-notice {
    position: relative;
    z-index: 5;
    margin-top: -15px;
    font-weight: 500;
    font-size: 12px;
}
.geotimedate-table_moon-notice span{
    text-decoration: underline;
}
.geotimedate-table_moon-notice__under-datepicker {
    margin: 0;
    padding-left: 2em;
}

.SunData .sun-month-year-change{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* color: #7f7f7f; */
}
.SunData .sun-month-year-change div{
    color: #7f7f7f !important;
}
.SunData .month-year-change{
    display: flex;
    width: 360px;
    max-width: 100%;
}
.SunData .month-year-change .sun-month-select{
    flex-basis: 150px !important;
    margin: 0 20px 0 0 !important;
}
.SunData .month-year-change .sun-year-select{
    flex-basis: 100px !important;
    margin: 0 20px 0 0 !important;
}
.SunData .month-year-change .month-chnge{
    flex-basis: 64px !important;
    background: #e9e9e9;
    color: #7f7f7f;
    border: none;
    cursor: pointer;
    padding: 0;
}
.SunData .month-year-change .month-chnge:hover{
    background: #008853;
    color: #fff;
}

.SunData .sun-date-list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0 20px;
}
.SunData .sun-date-list li{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 2px 5px;
    margin: 0 0.5%;
}
.SunData .sun-links-block{
    display: flex;
    justify-content: space-between;
}
.SunData .sun-links-block > div{
    width: 40%;
}
.SunData .sun-links-block > div a{
    display: block;
}
/* .SunData .sun-table-twilight{
    width: 110px;
}
.SunData .sun-table-noon{
    width: 80px;
} */
@media screen and (max-width: 640px) {}
@media screen and (max-width: 640px) {
    .responsiveTable {
        display: block;
    }
    .SunData .sun-month-year-change{
        flex-direction: column;
        align-items: left;
    }
    .SunData table td .sun-twilight-info{
        display: none;
    }
    .SunData table tr td p{
        display: inline-block;
    }
    .SunData table tr td p:last-child{
        margin: 0 0 0 2px;
    }
    .SunData table th span,
    .SunData table td span{
        float: none;
        width: auto;
    }
}

@media screen and (max-width: 40em){
    .responsiveTable td.pivoted {
        height: 35px;
    }
    .SunData table tr{
        height: auto;
    }
    .SunData table td span:first-child{
        border: none;
    }
    .SunData table td span{
        height: auto;
    }
}



.SunData table{
    margin: 0 0 10px;
    border-spacing: 0;
}
.SunData table th{
    background: #ffeb3b;
}
.SunData table tr{
    height: 34px;
}
.SunData table td{
    height: 100%;
}
.SunData table th,
.SunData table td{
    border: 2px solid #ccc;
    border-bottom: none;
    border-right: none;
    /* padding: 2px 5px; */
    font-size: 12px;
    line-height: 14px;
}
.SunData table th:last-child,
.SunData table td:last-child{
    border-right: 2px solid #ccc;
}
.SunData table tr:last-child td{
    border-bottom: 2px solid #ccc;
}
.SunData table th b,
.SunData table td b{
    display: block;
}
.SunData table th b{
    padding: 0 5px;
}
.SunData table th b:first-child{
    /* padding: 0 5px; */
    display: flex;
    align-items: center;
    height: 28px;
    
}
.SunData table th b:last-child{
    /* padding: 0 5px; */
    /* height: 28px; */
}
.SunData table td b{
    font-weight: normal;
    padding: 2px 5px;
}
.SunData table td{
    white-space: nowrap;
    /* padding: 2px 5px; */
    padding: 0;
}
.SunData table td p{
    height: 100%;
    padding: 0 2px;
    /* background: #ccc; */
}
.SunData table th span,
.SunData table td span{
    float: left;
    width: 50%;
    padding: 2px;
}
.SunData table td span{
    display: flex;
    align-items: center;
    height: 34px;
}
.SunData table td span:first-child{
    border-right: 2px solid #ccc;
}
/* .SunData table th span:first-child,
.SunData table td span:first-child{
    border-right: 2px solid #ccc;
}
.SunData table th span{
    border-top: 2px solid #ccc;
} */
