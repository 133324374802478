.sunCulcCityInfoRightSide-wrapper {
	width: 100%;
}
.sunCulcCityInfoRightSide b {
	display: block;
	font-weight: normal;
}
.sunCulcCityInfoRightSide .sunCulcCityInfoRightSide-wrapper > div {
	margin: 0 0 20px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
	background: #f5f5f5;
}
.sunCulcCityInfoRightSide
	.sunCulcCityInfoRightSide-wrapper
	> div
	.eclipse-header {
	background: #fff386;
	padding: 16px;
	white-space: normal !important;
	color: #444f4f;
	font-weight: bold;
}
.sunCulcCityInfoRightSide .eclipse-info .advertising {
	width: 100%;
}
.sunCulcCityInfoRightSide .links > b {
	text-align: right;
	padding: 14px 16px;
}
.sunCulcCityInfoRightSide .eclipse-info-block {
	display: flex;
	padding: 10px 16px;
}
.sunCulcCityInfoRightSide .eclipse-info-block b {
	width: 49%;
}
.sunCulcCityInfoRightSide .links .eclipse-info-block b {
	width: 100%;
}
.sunCulcCityInfoRightSide .eclipse-info-block b span {
	display: block;
}
.sunCulcCityInfoRightSide .eclipse-info-block b:first-child {
	margin: 0 2% 0 0;
}
.sunCulcCityInfoRightSide .day-length-info .eclipse-info-block b:last-child {
	text-align: right;
}
.sunCulcCityInfoRightSide .eclipse-info-block a {
	display: flex;
	width: 100%;
}
.sunCulcCityInfoRightSide .eclipse-info-block.eclipse-info-block-visible {
	background: #ec6400c7;
	color: #fff;
}
.sunCulcCityInfoRightSide .eclipse-info-block.eclipse-info-block-visible input {
	color: #fff !important;
}
.sunCulcCityInfoRightSide .azimuth-info .sun_shadow_input {
	width: 100px !important;
	font-size: 14px !important;
	line-height: 16px !important;
	height: 16px !important;
}
.sunCulcCityInfoRightSide .azimuth-info .sun_shadow_input hr {
	bottom: 0px !important;
	border-top: none #fff !important;
	border-left: none #fff !important;
	border-right: none #fff !important;
	border-bottom: 1px solid #fff !important;
}

@media screen and (max-width: 480px)  {
	.sunCulcCityInfoRightSide {
		margin-top: 30px;
	}
}

@media screen and (min-width: 480px) and (max-width: 980px) {
	.sunCulcCityInfoRightSide {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-top: 20px;
	}
	.sunCulcCityInfoRightSide-wrapper {
		width: 48%;
	}
}
