.PlanetRetrogradesSignsLinks .stations-list > span{
    display: flex;
    align-items: center;
    padding: 5px 10px;
    width: 30%;
    text-align: center;
    cursor: pointer;
}
.PlanetRetrogradesSignsLinks .stations-list > span img{    
    width: 25px;
    margin: 0 5px 0 0;
}