.NotFound {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	justify-content: center;
}

.NotFound h1 {
	font-size: 70px;
}

.NotFound h1,
.NotFound h2,
.NotFound p {
	margin: 0 0 10px;
}
