.header_countdown_NY {
    background: #008854;
    color: #ffffff;
    margin-bottom: 50px;
    width: 100%;
}
.header_countdown_NY .search-block{
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
}

@media screen and (max-width: 768px) {
    .header_countdown_NY .search-block{
        flex-direction: column;
    }
}
