.test-design {
  position: absolute;
  left: 0;
  width: 100%;
  min-height: 520px;
  background: rgba(220, 220, 220, 0.1);
}
.test-design > .container {
  height: 500px;
  width: 500px;
  margin: auto;
  margin-top: 0px;
  position: relative;
  background: #f6f5fe;
  border-radius: 50%;
  animation: balloon 2s forwards infinite;
}

.container > .ground {
  border: 3px solid #7548d6;
  position: absolute;
  top: 350px;
  left: 100px;
  border-radius: 5px;
  animation: ground-anim 3s forwards infinite;
}

.ground-one {
  height: 0px;
  border: 2px solid #7548d6;
  position: absolute;
  top: 380px;
  left: 130px;
  border-radius: 5px;
  animation: ground-one-anim 3s forwards infinite;
}

.building-left {
  width: 60px;
  border-left: 3px solid #e8dcf9;
  position: absolute;
  top: 230px;
  left: 140px;
  background: #e8dcf9;
  animation: building-left 3s forwards infinite;
}
.building-left .top {
  border: 2px solid #e8dcf9;
  position: absolute;
  top: -2px;
  left: -13px;
  border-radius: 5px;
  animation: building-left-top 3s forwards infinite;
}

.building {
  width: 75px;
  border: 3px solid #7548d6;
  border-bottom: none;
  border-top: none;
  position: absolute;
  top: 150px;
  left: 200px;
  animation: building 3s forwards infinite;
}
.building .top {
  border: 2px solid #7548d6;
  position: absolute;
  top: -2px;
  left: -17px;
  border-radius: 5px;
  animation: building-top 3s forwards infinite;
}

@keyframes building {
  0% {
    height: 0px;
  }
  50%,
  80%,
  100% {
    height: 200px;
  }
}
@keyframes building-top {
  0% {
    width: 0px;
  }
  50%,
  80%,
  100% {
    width: 106px;
  }
}
@keyframes ground-anim {
  0% {
    width: 0px;
  }
  50%,
  80%,
  100% {
    width: 300px;
  }
}
@keyframes ground-one-anim {
  0% {
    width: 0px;
  }
  50%,
  80%,
  100% {
    width: 40px;
  }
}
@keyframes building-left {
  0% {
    height: 0px;
  }
  50%,
  80%,
  100% {
    height: 120px;
  }
}
@keyframes building-left-top {
  0% {
    width: 0px;
  }
  50%,
  80%,
  100% {
    width: 70px;
  }
}
.window,
.window-1,
.window-2,
.window-3,
.window-4,
.window-5,
.window-6,
.window-7,
.window-8 {
  height: 7px;
  border: 2px solid #7548d6;
  background: #ede1ca;
  position: absolute;
  border-radius: 2px;
  animation: windows 3s forwards infinite;
}

.window-1 {
  top: 20px;
  left: 20px;
}

.window-2 {
  top: 40px;
  right: 21px;
}

.window-3 {
  top: 60px;
  left: 20px;
}

.window-4 {
  top: 80px;
  right: 21px;
}

.window-5 {
  top: 100px;
  left: 20px;
}

.window-6 {
  top: 120px;
  right: 21px;
}

.window-7 {
  top: 140px;
  left: 20px;
}

.window-8 {
  top: 160px;
  right: 21px;
}

@keyframes windows {
  0% {
    width: 0px;
  }
  50%,
  80%,
  100% {
    width: 30px;
  }
}
.building-right {
  width: 60px;
  border-left: 3px solid #e8dcf9;
  position: absolute;
  top: 190px;
  right: 160px;
  background: #e8dcf9;
  animation: building-right 3s forwards infinite;
}
.building-right .top {
  border: 2px solid #e8dcf9;
  position: absolute;
  top: -2px;
  right: -11px;
  border-radius: 5px;
  animation: building-right-top 3s forwards infinite;
}

@keyframes building-right {
  0% {
    height: 0px;
  }
  50%,
  80%,
  100% {
    height: 160px;
  }
}
@keyframes building-right-top {
  0% {
    width: 0px;
  }
  50%,
  80%,
  100% {
    width: 70px;
  }
}
.bush,
.bush-one,
.bush-two,
.bush-three {
  z-index: 2;
  background: #5adda0;
  width: 40px;
  height: 20px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  position: absolute;
  top: 330px;
  animation: bush 3s forwards infinite;
}

.bush-one {
  left: 120px;
}

.bush-two {
  right: 160px;
}

.bush-three {
  height: 30px;
  top: 320px;
  right: 140px;
}

.stem-one {
  height: 60px;
  width: 0px;
  border: 2px solid #7548d6;
  position: absolute;
  top: 280px;
  left: 136px;
  animation: bush 3s forwards infinite;
}
.stem-one .branch {
  height: 15px;
  width: 0px;
  border: 2px solid #7548d6;
  position: absolute;
  transform: rotate(40deg);
  left: 5px;
  z-index: 3;
}
.stem-one .leaves-one {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #5adda0;
  position: absolute;
  left: -20px;
  top: -45px;
}
.stem-one .leaves-two {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #5adda0;
  position: absolute;
  left: -13px;
  top: -60px;
}

@keyframes bush {
  0% {
    transform: scale(0);
  }
  50%,
  80%,
  100% {
    transform: scale(1);
  }
}
.balloon {
  width: 75px;
  height: 75px;
  position: absolute;
  top: 60px;
  left: 100px;
  animation: balloon 3s forwards infinite;
}
.balloon .head {
  width: 40px;
  height: 48px;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  background: #df96f6;
}
.balloon .base {
  width: 7px;
  height: 7px;
  position: absolute;
  top: 47px;
  left: 28px;
  background: #df96f6;
}

@keyframes balloon {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
