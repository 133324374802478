.weather-block {
  position: relative;
  background: #aca2ff;
}
.weather-block .loader-spinner {
  position: absolute;
  top: calc(50% - 40px);
  bottom: calc(50% - 40px);
}
.WeatherBlock.world-clock {
  margin: 0 0 20px;
}
.weather-block-day {
  font-size: 12px;
  width: 100%;
}
.weather-block-day p {
  font-weight: 700;
  font-size: 12px;
  margin: 0 !important;
}
.weather-block-day span {
  display: block;
  font-size: 18px;
  text-align: center;
}
.toggle-temperature-units {
  display: flex;
  justify-content: flex-end;
  padding: 0 6px 6px 0;
}
.toggle-temperature-units > div {
  width: auto !important;
}
.WeatherBlock.world-clock .weather-block-header {
  display: flex;
  background: #aca2ff;
  height: auto;
}
.WeatherBlock.world-clock .weather-block-header .weather-name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 8px;
}
.WeatherBlock.world-clock .weather-block-header .weather-name h3 {
  position: relative;
  color: #fff;
}
.WeatherBlock.world-clock .weather-block-header .weather-name h3:after {
  display: none;
}
.WeatherBlock.world-clock .weather-block-header .weather-name a {
  color: #fff;
  text-decoration: underline;
  width: 150px;
  align-self: flex-end;
}
.WeatherBlock.world-clock .weather-block-content {
  display: flex;
  min-height: 260px;
}
.WeatherBlock.world-clock .weather-block-content > div {
  padding: 6px;
  width: 20%;
}
.weather-block-current h3 {
  align-self: flex-start;
  font-size: 14px;
}
.WeatherBlock.world-clock .weather-block-content .weather-block-current {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  /* max-width: 120px; */
  background: rgb(255, 255, 255);
}

.weather-block-temperature h3 {
  font-size: 18px;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-current
  .weather-block-temperature {
  display: flex;
  margin: auto 0 0;
  justify-content: space-between;
  flex-flow: column wrap;
  align-items: center;
  width: 100%;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-current
  .weather-block-temperature
  .weather-block-img {
  position: relative;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-current
  .weather-block-temperature
  .weather-block-img
  canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}
.weather-block-day-top {
  margin-top: auto;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-current
  .weather-block-temperature
  .weather-block-number {
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 20px;
  line-height: 1;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-current
  .weather-block-temperature
  .weather-block-number
  sup {
  font-size: 12px;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-current
  .weather-block-temperature
  .weather-block-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 16px;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-current
  .weather-block-temperature
  .weather-block-info
  b {
  margin: 0 0 10px;
}
.weather-block-next.next-days {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-next.next-days
  h3,
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-next.next-days
  h5 {
  text-align: center;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-next.next-days
  .weather-block-temperature {
  display: flex;
  justify-content: center;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-next.next-days
  .weather-block-feels {
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  font-size: 12px;
  margin-top: 16px;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-next.next-days
  .weather-block-img {
  position: relative;
  height: 40px;
  width: 48px;
  margin: 0 auto 10px;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-next.next-days
  .weather-block-img
  canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-next.next-days
  .weather-block-temperature
  .weather-block-number {
  font-size: 20px;
  line-height: 1;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-next.next-days
  .weather-block-temperature
  .weather-block-number
  sup {
  font-size: 12px;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-next.next-days
  .weather-block-temperature
  .weather-block-info {
  padding: 5px;
}

.weather-block-wind {
  padding-top: 10px;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-current
  .weather-block-wind {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.weather-part {
  font-size: 11px;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-current
  .weather-block-wind
  .weather-part {
  font-size: 11px;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-wind
  .weather-part
  .weather-part-img {
  position: relative;
  height: 35px;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-wind
  .weather-part
  .weather-part-img
  img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}
.WeatherBlock.world-clock
  .weather-block-content
  .weather-block-wind
  .weather-part
  span {
  display: block;
  text-align: center;
}

.weekly-weather {
  display: flex;
  justify-content: space-between;
  max-width: calc(434px - 40px);
  margin-top: 20px;
}
.weekly-weather > div {
  width: calc(100% / 7);
  padding: 0 5px;
}
.weekly-weather > div h3,
.weekly-weather > div h5 {
  text-align: center;
  color: #fff;
  font-size: 14px;
}
.weekly-weather > div h3 {
  margin: 0 0 5px;
}
.weekly-weather > div h5 {
  margin: 0 0 10px;
}
.weekly-weather > div .weather-block-img {
  position: relative;
  height: 80px;
}
.weekly-weather > div .weather-block-img canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 100%;
  max-width: 40px;
  padding: 0 0 10px;
}
.weekly-weather > div .weatherTemperature {
  display: flex;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  align-items: center;
  justify-content: center;
}
.weekly-weather > div .weatherTemperature span {
  display: flex;
  height: 26px;
  font-size: 12px;
  line-height: 16px;
}
.weekly-weather > div .weatherTemperature span:last-child {
  align-items: flex-end;
}
@media screen and (max-width: 1080px) {
  .weekly-weather {
    max-width: none;
  }
}

@media (max-width: 767px) {
  .WeatherBlock.world-clock .weather-block-content > div h3 {
    font-size: 14px;
  }
  .weather-block-day span {
    font-size: 14px;
  }
}

@media (max-width: 940px) {
  .weather-block-next.next-days:last-child {
    display: none;
  }
}

@media (min-width: 700px) {
  .weather-block-next.next-days:nth-child(4) {
    display: none;
  }
  .weather-block-next.next-days:last-child {
    display: none;
  }
  .WeatherBlock.world-clock .weather-block-content > div {
    width: 30%;
  }
}

@media (min-width: 800px) {
  .weather-block-next.next-days:nth-child(4) {
    display: flex;
  }
  .WeatherBlock.world-clock .weather-block-content > div {
    width: 20%;
  }
}

@media (min-width: 920px) {
  .weather-block-next.next-days:last-child {
    display: flex;
  }
}

@media (min-width: 1081px) {
  .weather-block-next.next-days:nth-child(4) {
    display: none;
  }
  .weather-block-next.next-days:last-child {
    display: none;
  }
  .WeatherBlock.world-clock .weather-block-content > div {
    width: 30%;
  }
}

@media (min-width: 1300px) {
  .weather-block-next.next-days:nth-child(4) {
    display: flex;
  }
  .WeatherBlock.world-clock .weather-block-content > div {
    width: 20%;
  }
}

@media (min-width: 1420px) {
  .weather-block-next.next-days:last-child {
    display: flex;
  }
}
