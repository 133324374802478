
.Calculator .content .quarter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 10px 20px;
    margin: 0 0 20px;
    box-shadow: 0 1px 6px rgba(150, 51, 51, 0.12), 0 1px 4px rgba(0,0,0,.12);
}
/* .Calculator .content .quarter:hover{
    box-shadow: 0 1px 6px rgba(204, 153, 0, 1), 0 -1px 6px rgba(204, 153, 0, 1);
} */
.Calculator .content .quarter:last-child{
    margin: 0;
}
.Calculator .content .quarter b{
    font-weight: normal;
}
.Calculator .content .quarter button{
    height: 50px;
    width: 200px;
    background: rgba(25, 158, 216, 1);
    border: 1px solid rgba(25, 158, 216, 1);
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}