
.TimeZoneConverter .ContentBlock .time-converter-slider{
    position: relative;
    margin: 20px 0 34px;
}
.TimeZoneConverter .ContentBlock .time-converter-slider .slider > div{
    margin: 0 !important;
}
.TimeZoneConverter .ContentBlock .time-converter-slider span{
    position: absolute;
    top: 140%;
    background: rgba(116, 175, 173, 1);
    color: #000;
    border-radius: 5px;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 15px;
}
.TimeZoneConverter .ContentBlock b{
    font-weight: normal;
    margin: 0 0 20px;
    color: #7b7b7b;
}
.empty-timezone-list{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}