.PlanetaryHoursRightBlock {}
.PlanetaryHoursRightBlock .planet-signs{
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    margin: 0 0 20px;
}
.PlanetaryHoursRightBlock .planet-signs > div{
    padding: 10px 15px;
}
.PlanetaryHoursRightBlock .planet-signs .body {
    padding: 10px 15px;
}
.PlanetaryHoursRightBlock .planet-signs .body .planetary-hours,
.PlanetaryHoursRightBlock .planet-signs .body .equinoxes-solstices,
.PlanetaryHoursRightBlock .planet-signs .body > span{
    display: block;
}
.PlanetaryHoursRightBlock .planet-signs .body .planetary-hours{
    margin: 0 0 15px;
}
.PlanetaryHoursRightBlock .planet-signs .body > span{
    margin: 0 0 5px;
}
.PlanetaryHoursRightBlock .planet-signs .body .equinoxes-solstices{
    margin: 15px 0 0;
}