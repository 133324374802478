.Retrograde {
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
}
.Retrograde .planet-stationare-retrograde {
	height: 300px;
}
.Retrograde .retrograde-table {
	display: flex;
}
.Retrograde .retrograde-table .RetrogradeData {
	width: 74%;
	margin: 0 1% 0 0;
}
.Retrograde .retrograde-table .retrograde-right-block {
	width: 25%;
}

.Retrograde .retrograde-table .retrograde-info {
	flex-basis: 450px;
	max-width: 450px;
	padding: 0 0 0 10px;
	border-left: 1px solid #737171;
}
.Retrograde
	.geotimedate-table
	.sticky-table-header-wrapper
	.sticky-table-row
	.sticky-table-cell {
	height: 47px;
}

@media (max-width: 1199px) {
	.Retrograde .retrograde-table {
		flex-direction: column;
	}
	.Retrograde .retrograde-table .RetrogradeData {
		margin: 0 0 20px;
	}
	.Retrograde .retrograde-table .RetrogradeData .table-data-period {
		margin: 0 0 20px;
	}
	.Retrograde .retrograde-table .RetrogradeData,
	.Retrograde .retrograde-table .retrograde-right-block {
		width: 100%;
	}
	.Retrograde .retrograde-table .retrograde-info {
		flex-basis: 100%;
		max-width: 100%;
		border-left: none;
		padding: 0;
	}
}
.rowRetrogradetitleTable {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #7f7f7f;
}
.selectRow {
	background: rgba(0, 255, 0, 0.4) !important;
	z-index: 9999;
}
.hoverRow:hover {
	cursor: pointer;
	background: rgba(255, 255, 0, 0.2);
	z-index: 9998;
}
.planets-list .links .activeLink {
	color: #333333;
	text-decoration: underline;
}
.titleOnScale {
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 5px;
}

@media (max-width: 991px) {
	.rowRetrogradetitleTable {
		flex-direction: column;
		margin: 0 0 10px;
	}
}
@media (max-width: 591px) {
	.rowRetrogradetitleTable .select-change {
		flex-direction: column;
		width: 100%;
	}
	.rowRetrogradetitleTable .select-change > div {
		width: 100% !important;
	}
	.rowRetrogradetitleTable .select-change button {
		width: 100% !important;
		flex-basis: 40px !important;
	}
}
