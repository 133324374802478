.authorization .social-login {
	display: flex;
	flex-direction: column;
	padding: 15px 0;
}
.authorization .social-login b {
	text-align: center;
	font-weight: 400;
}
.authorization .social-login .social-login-buttons {
	display: flex;
	justify-content: space-between;
	/*align-content: space-between;*/
	align-content: start;
	flex-wrap: wrap;
	/*height: 140px;*/
	margin: 0 0 10px;
}
.authorization .social-login .social-login-buttons > span,
.authorization .social-login .social-login-buttons button {
	width: calc((100% - 10px) / 2);
	border: none !important;
	border-radius: 0 !important;
}
.authorization .social-login .social-login-buttons > button,
.authorization .social-login .social-login-buttons > span > button {
	color: rgb(255, 255, 255);
	padding: 10px 6px;
	border: none !important;
	font-size: 16px;
	font-weight: bold;
	font-family: Roboto;
	cursor: pointer;
	padding: 7px 0 7px 0;
}
.authorization .social-login .social-login-buttons > span > button {
	width: 100% !important;
	height: 100%;
}
.authorization .social-login .social-login-buttons .facebook-button {
	background: #4267b2;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	border: 1px solid #4267b2 !important;
}
.authorization .social-login .social-login-buttons .facebook-button i {
	margin-right: 10px;
	width: 18px;
	height: 18px;
	padding-top: 4px;
	padding-left: 4px;
	color: #4267b2;
	background: white;
}
.authorization .social-login .social-login-buttons .microsoft-button {
	background: #fff;
	color: black;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	border: 1px solid #7ea7d8 !important;
	padding: 8px 0 8px 18%;
	position: relative;
	text-align: start;
	margin-top: 10px;
}
.authorization .social-login .social-login-buttons .microsoft-button::before {
	position: absolute;
	content: " ";
	display: inline-block;
	left: 19%;
	top: 6px;
	width: 20px;
	height: 20px;
	background-image: url("../../../../src/assets/micrsofot-icon.png");
	background-position: center;
	background-size: cover;
}

.authorization .social-login .social-login-buttons .google-button {
	background: #fff;
	color: black;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	border: 1px solid #7ea7d8 !important;
	padding-left: 17%;
	position: relative;
	text-align: start;
}
.authorization .social-login .social-login-buttons .google-button::before {
	position: absolute;
	content: " ";
	display: inline-block;
	left: 18%;
	top: 6px;
	width: 20px;
	height: 20px;
	background-image: url("../../../../src/assets/google-logo.png");
	background-position: center;
	background-size: cover;
}

.authorization .social-login .social-login-buttons .linkedin-button {
	margin-top: 10px;
	background: #007ab5;
	border: 1px solid #007ab5 !important;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	padding-left: 8%;
	position: relative;
	text-align: start;
}
.authorization .social-login .social-login-buttons .linkedin-button::before {
	content: "in";
	display: inline-block;
	margin-right: 10px;
	width: 18px;
	height: 18px;
	padding-top: 3px;
	padding-left: 4px;
	color: #007ab5;
	font-weight: 600;
	background: white;
}

.authorization .social-login .social-login-buttons .amazon-button {
	margin-top: 10px;
	background: #232f3e;
	border: 1px solid #232f3e !important;
}
.authorization .authorization-text {
	padding: 10px 0;
}
.authorization .authorization-text b {
	font-weight: normal;
}
.authorization .authorization-text p {
	margin: 1em 0 2em;
	text-align: center;
	line-height: 1.5;
}
.authorization .authorization-text span {
	display: block;
	font-size: 12px;
	line-height: 1.5;
}
.authorization .authorization-text .no-bottom-margin {
	margin-bottom: 0;
}
.authorization .authorization-text .recovery-note {
	margin-bottom: 0;
}
.authorization-signup-link {
	text-align: center;
}

.authorization .authorization-title {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.authorization .br-bottom {
	border-bottom: 1px solid black;
	padding-top: 10px;
	width: 50%;
}

.authorization .authorization-middle-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
}

.authorization .middle-line {
	width: 26%;
	height: 1px;
	background-color: #000;
}

.authorization .agreement {
	margin-top: 30px;
	text-align: center;
	color: gray;
	font-size: 11px;
}
