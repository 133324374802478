.groups {
	position: relative;
}
.groups .loader-spinner {
	position: absolute;
	bottom: auto;
	transform: translate(-50%, -50%);
}
.groups .groups-list {
	display: flex;
	flex-direction: column;
}
.groups .groups-list .group {
	border: 1px solid #dedede;
	padding: 10px 20px;
}
.groups .groups-list .group:not(:last-child) {
	border-bottom: none;
}
.groups .groups-list .group .group-name {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #909090;
	/* cursor: pointer; */
}
.groups .groups-list .group .group-name .name {
	display: flex;
	width: 130px;
}
.groups .groups-list .group .group-name .name h5 {
	margin: 0 20px 0 0;
}
.groups .groups-list .group .group-name .name i {
	font-size: 16px;
	line-height: 16px;
	margin: 0 10px 0 0;
	cursor: pointer;
	width: 16px;
}
.groups .groups-list .group .group-name .name i:last-child {
	margin: 0;
}
.groups .groups-list .group .group-name .search {
	display: flex;
	align-items: center;
}
.groups .groups-list .group .group-name .search .Search {
	padding: 0;
	width: 300px;
}
.groups .groups-list .group .group-name .search > i {
	width: 24px;
	height: 24px;
	cursor: pointer;
}
.groups .groups-list .group .group-name .search .Search .search_form {
	margin: 0;
}
.groups .groups-list .group .group-name .search i {
	transition: 0.2s;
}
.groups .groups-list .group .cities-list {
	overflow: hidden;
}
.groups .groups-list .group .cities-list .city-block {
	display: flex;
	justify-content: space-between;
	padding: 10px 0;
	border-bottom: 1px solid #dedede;
}
.groups .groups-list .group .cities-list .city-block:last-child {
	border-bottom: none;
}
.groups .groups-list .group .cities-list .city-block > div {
	display: flex;
	align-items: center;
}
.groups .groups-list .group .cities-list .city-block .main-info > span {
	margin: 0 15px 0 0;
	width: 16px;
}
.groups .groups-list .group .cities-list .city-block .main-info img {
	height: 30px;
	margin: 0 15px 0 0;
}
.groups
	.groups-list
	.group
	.cities-list
	.city-block
	.main-info
	.cityName
	input {
	width: 100%;
}
.groups .groups-list .group .cities-list .city-block .main-info .cityName span {
	font-size: 0.8em;
	line-height: 0.8em;
}
.groups .groups-list .group .cities-list .city-block .main-info .cityName {
	width: 200px;
	margin: 0 10px 0 0;
}
.groups .groups-list .group .cities-list .city-block i {
	font-size: 1.3em;
	line-height: 1.3em;
	color: #afadad;
	cursor: pointer;
}
.groups .groups-list .group .cities-list .city-block .main-info i {
	max-width: 21px;
	margin: 0 5px 0 0;
	overflow: hidden;
}
.groups .groups-list .group .cities-list .city-block .working-hours {
	margin: 0 30px 0 0;
}
.groups .groups-list .group .cities-list .city-block .working-hours b,
.groups .groups-list .group .cities-list .city-block .weekends b {
	color: #909090;
	display: block;
	text-align: center;
	font-size: 0.8em;
	line-height: 0.8em;
	margin: 0 0 5px;
}
.groups
	.groups-list
	.group
	.cities-list
	.city-block
	.working-hours
	.input-block {
	display: flex;
	align-items: center;
}
.groups
	.groups-list
	.group
	.cities-list
	.city-block
	.working-hours
	.input-block
	> div {
	width: 80px;
}
.groups
	.groups-list
	.group
	.cities-list
	.city-block
	.working-hours
	.input-block
	> div
	.time-picker {
	width: 100% !important;
	height: 20px !important;
	font-size: 0.8em !important;
	line-height: 0.8em !important;
}
.groups
	.groups-list
	.group
	.cities-list
	.city-block
	.working-hours
	.input-block
	> div
	.time-picker
	input
	~ div {
	display: none;
}
.groups
	.groups-list
	.group
	.cities-list
	.city-block
	.working-hours
	.input-block
	input {
	border: 1px solid #ccc !important;
	padding: 3px 5px !important;
	height: 20px;
	width: 60px;
	font-size: 0.8em;
	font-weight: 700 !important;
	line-height: 0.8em;
	text-align: center;
}
.groups
	.groups-list
	.group
	.cities-list
	.city-block
	.working-hours
	.input-block
	input::placeholder {
	color: #b9b9b9;
}
.groups
	.groups-list
	.group
	.cities-list
	.city-block
	.working-hours
	.input-block
	i {
	margin: 0 0 0 5px;
}
.groups .groups-list .group .cities-list .city-block .weekends .days-block {
	display: flex;
}
.groups
	.groups-list
	.group
	.cities-list
	.city-block
	.weekends
	.days-block
	button {
	border: 1px solid #b9b9b9;
	margin-right: 2px;
	background: #fff;
	color: #757171;
	cursor: pointer;
}

.groups .groups-list .add-group {
	padding: 2px 15px;
	border: 1px solid #ff925d;
	border-radius: 8px;
	background: #ff925d;
	color: #fff;
	cursor: pointer;
}

.groups .groups-list .add-group.add-group__disabled {
	margin-right: 20px;
	border-color: #d8d8d8;
	background: #d8d8d8;
	cursor: not-allowed;
}
