.timePicker {
	max-width: 100%;
}
.timePicker .time-picker-header {
	align-items: center;
	justify-content: space-between;
}
.timePicker .time-picker-header .Search {
	padding: 0;
	flex-basis: 400px;
}
.Search .search_field_timepicker {
	padding: 10px 27px;
}
.timePicker .time-picker-header .search_form {
	margin: 0;
	justify-content: flex-end;
	height: 100%;
}
.timePicker .time-picker-header .Search .search_form_select {
	margin: 0;
	width: 400px;
	max-width: 100%;
	height: 38px;
}
.citiesRender
	.timePicker
	.time-picker-header
	.currentCityTittle
	.Search
	.search_form
	.search_form_select {
	margin: 0;
	width: 400px;
	max-width: 100%;
	height: 30px;
}
.timepicker-notification {
	position: absolute;
	bottom: calc(100% + 10px);
	left: 0;
	width: 274px;
	max-width: 100%;
	text-align: center;
    background: rgba(255, 178, 0, 1);
    color: #fff;
	transition: 0.8s;
	z-index: 2;
}
.timepicker-notification:after {
	content: "";
	position: absolute;
	top: 100%;
	right: 10%;
	border: 10px solid transparent;
	border-top: 10px solid rgba(255, 178, 0, 1);
	z-index: 2;
}
.timePicker .timepickerSearchError {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	background: #d0cccc;
	padding: 5px 10px;
	color: rgb(68, 68, 68);
	width: 100%;
	z-index: 6;
}
