.BusinessDateCalc .input-date {
  width: 50%;
}

.BusinessDateCalc .Calculator .right-block {
  padding: 0px !important;
}

.BusinessDateCalc .days {
  padding-top: 86px;
}

.BusinessDateCalc .days .row {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.BusinessDateCalc .days .label {
  padding-top: 14px;
}

.BusinessDateCalc .add_sub {
  margin-top: 113px;
}

.BusinessDateCalc .Calculator .right-block .info-block-row {
  justify-content: flex-start;
}

@media (max-width: 1225px) {
  .BusinessDateCalc .Calculator .right-block .info-block-row {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .BusinessDateCalc .add_sub {
    margin-top: 50px;
  }
  .BusinessDateCalc .days {
    padding-top: 0px;
  }

  .BusinessDateCalc .right_block_days .input-date {
    width: 259px !important;
  }

  .BusinessDateCalc .Calculator .right_block_days {
    width: 100% !important;
  }

  .BusinessDateCalc .Calculator .right-block .info-block-row {
    justify-content: flex-start;
  }
}
