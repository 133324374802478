.citySunRiseSet {
	position: relative;
	display: flex;
	justify-content: left;
	align-items: center;
	background: #fff386;
	padding: 11px 10px;
}
.citySunRiseSet .country_flag {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 10px;
	margin: auto;
	width: 48px;
	height: 48px;
}
.citySunRiseSet .country_flag img {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	max-width: 100%;
	max-height: 100%;
}
.citySunRiseSet .city_name_date {
	display: flex;
	align-items: center;
	padding: 0 0 0 58px;
}
