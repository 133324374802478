.about {
	max-width: 1100px;
	margin: 0 auto;
	text-align: justify;
	line-height: 1.2;
}

.about p {
	margin-bottom: 20px;
}

.about h1 {
	font-size: 32px;
}

.about h2 {
	font-size: 16px;
	margin-bottom: 5px;
}

.about hr {
	margin-bottom: 30px;
}
