.create-countdown-button{
    width: 130px;
    height: 65px;
    border: 1px solid #169bd5;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}
.create-countdown-button:hover{
    background-color: #1DACD6;
    transition: .2s;
}
.create-countdown-button:not(:hover) {
    -webkit-transition: .7s;
    -o-transition: .7s;
    transition: .7s;
    background-color: #169bd5;
}