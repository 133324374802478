.LinksBlock > div {
    margin: 0 0 20px;
}
.LinksBlock .link-block {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}
.LinksBlock .link-block h5 {
    background: #63697d;
    padding: 16px;
    color: #fff;
}
.LinksBlock .link-block .info-block {
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
}
.LinksBlock .link-block .info-block a {
    padding: 5px 0;
}
.LinksBlock .link-block .info-block > b{
    text-align: right;
    font-weight: normal;
}