.TimeZoneConverter .serarch-block{
    background: #008854;
    padding: 0 20px;
}
.TimeZoneConverter .serarch-block .currentCityImg{    
    position: relative;
    width: 30%;
    max-width: 121px;
    height: 72px;
}
.TimeZoneConverter .serarch-block .currentCityImg img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    padding: 5px;
}
.TimeZoneConverter .serarch-block .date-search-block{
    display: flex;
    flex-grow: 1;
}
.TimeZoneConverter .serarch-block .title-block{
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    color: #fff;
}
.TimeZoneConverter .time-picker-header .currentCityTittle {
    align-items: center;
}
.TimeZoneConverter .citiesRender .timePicker .time-picker-header .currentCityTittle .title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    height: auto;
}
.TimeZoneConverter .Search{
    flex-basis: 400px;
    padding: 0;
}
.TimeZoneConverter .search_form{
    margin: 0;
}
.TimeZoneConverter .time-format-date  {
    height: 100%;
    align-items: center;
}
.TimeZoneConverter .time-format-date .converterCalendarBlock {
    position: relative;
    height: 48px;
    margin: 0 10px 0 0;
}
.TimeZoneConverter .time-format-date .converterCalendarBlock .converterCalendar > div{
    width: 120px !important;
}
.TimeZoneConverter .time-format-date .converterCalendarBlock i {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 0;
    height: 24px;
    width: 24px;
    font-size: 24px;
    background: transparent;
    color: #fff;
    border-radius: 0 !important;
}
.TimeZoneConverter .time-format-date .converterCalendarBlock .converterCalendar input{
    color: #fff !important;
    z-index: 1;
    cursor: pointer !important;
}
.TimeZoneConverter .time-format-date .converterCalendarBlock .converterCalendar hr{
    display: none
}
.TimeZoneConverter .time-format-date .time-format{
    color: #fff;
}
.TimeZoneConverter .locations-clear{
    color: #b31760;
    cursor: pointer;
    background: none;
    border: 1px solid #b31760;
}
.TimeZoneConverter .Search .search_form .search_form_select,
.TimeZoneConverter .Search .search_form .search_form_select .search_field{
    height: 30px;
}
.TimeZoneConverter .Search .search_field_timepicker, .Search .search_field{
    padding: 10px 30px;
}
@media (max-width: 991px){
    .serarch-block {        
        flex-direction: row;
        align-items: center;
    }
}
@media (max-width: 879px) {
    .TimeZoneConverter .time-picker-header .currentCityImg {
        height: 100px;
    }
}
@media (max-width: 480px){
    .serarch-block {        
        flex-direction: column;
    }
}
