.authorization-wrapper {
	height: 100%;
	display: flex;
	align-items: center;
	padding: 2em 0 1em;
}
.authorization {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	width: 440px;
	max-width: 100%;
	border: 1px solid #ccc;
	padding: 15px 26px;
}
.authorization .authorization-forgot-note {
	margin-top: 2em;
	text-align: center;
	line-height: 1.5;
}
.authorization h4 {
	width: 85%;
	margin: 0 auto;
	padding: 30px 15px 15px;
	text-align: center;
	text-transform: uppercase;
	border-bottom: 1px solid #333333;
}
.authorization .authorization-title__short h4 {
	padding: 30px 15px 0;
	border-bottom: 0;
}
.authorization .authorisation-form-input {
	width: 100% !important;
}
.authorization .authorisation-form-input.authorisation-form-input__inline {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
}
.authorization .authorisation-form-input-padding label {
	padding-left: 20px !important;
}
.authorization .authorisation-form-input-padding label + div,
.authorization .authorisation-form-input-padding input {
	padding-left: 14px !important;
}
.authorization form .authorization-links {
	display: flex;
	justify-content: space-between;
	margin: 15px 0 20px;
}
.authorization form .authorization-links > div {
	width: 150px !important;
}
.authorization form .authorization-links > div > input + div > div {
	align-self: center;
	margin-right: 4px !important;
}
.authorization
	form
	.authorization-links
	> div
	> input
	+ div
	> div
	> div:first-child
	svg
	:last-child {
	fill: #85aad6 !important;
}
.authorization
	form
	.authorization-links
	> div
	> input
	+ div
	> div
	> div:first-child
	svg
	:last-child
	path {
	fill: #85aad6 !important;
}
.authorization form .authorization-links > div > input + div > div + label {
	width: calc(100% - 28px) !important;
	color: #85aad6 !important;
}
.authorization form .authorization-links a {
	display: flex;
	align-items: center;
}
.authorization .authorization-button {
	margin-top: 1.8em;
}
.authorization .authorization-button button {
	width: 100%;
	background: #7ea7d8;
	border: none;
	box-shadow: none;
	padding: 15px;
	color: #fff;
	cursor: pointer;
}
.authorization .authorization-error {
	display: block;
	position: relative;
	font-size: 12px;
	line-height: 12px;
	color: rgb(244, 67, 54);
	transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	padding: 20px 0 0;
}
.authorization .authorization-success {
	display: block;
	position: relative;
	font-size: 12px;
	line-height: 12px;
	color: #008854;
	transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	padding: 20px 0 0;
}
.authorization .email-note {
	font-size: 12px;
}
