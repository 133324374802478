.EphemerisForToday .currentCityHeader .currentCityImg {
  background: #9e5ba1;
}
.EphemerisForToday .currentCityHeader .currentCityTittle {
  background: #b06db3;
}
.EphemerisForToday .PlanetListBlock {
  margin: 0 0 15px;
  padding: 5px 0 0;
}
.EphemerisForToday .PlanetListBlock b {
  font-weight: normal;
}
.EphemerisForToday .PlanetListBlock .ephemeris_list_tittle {
  background: #7ea7d8;
  margin: 0 0 5px;
  color: #fff;
}
.EphemerisForToday .PlanetListBlock .ephemeris_table {
  display: flex;
  justify-content: space-between;
}
.EphemerisForToday .PlanetListBlock .ephemeris_table {
  padding: 3px 10px;
}
.EphemerisForToday .PlanetListBlock .planet_list {
  color: #000;
}
.EphemerisForToday
  .PlanetListBlock
  .planet_list
  .ephemeris_table:nth-child(2n) {
  background: #e0e0e0;
}
.EphemerisForToday .PlanetListBlock .single_planet {
  display: flex;
}
.EphemerisForToday .PlanetListBlock .single_planet .zodiac_name {
  cursor: default;
}
.EphemerisForToday .columnOne {
  flex-basis: 100px;
  flex-grow: 2;
}
.EphemerisForToday .PlanetListBlock .columnTwo {
  flex-basis: 100px;
  flex-grow: 2;
}
.EphemerisForToday .PlanetListBlock .columnThree {
  flex-basis: 30px;
}
.EphemerisForToday .PlanetListBlock .columnFour {
  flex-basis: 50px;
}
.EphemerisForToday .PlanetListBlock .columnFour img {
  max-width: 100%;
  width: 15px;
}

.planet-info {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 0 20px;
  min-height: 200px;
  position: relative;
}
.planet-info .loader-spinner {
  position: absolute;
}
.panchang {
  position: relative;
  background: #ffced3;
  width: 31.111111%;
  padding-bottom: 10px;
}
.panchang img {
  width: 100%;
}

.planet-info .ephemeris {
  display: flex;
  justify-content: space-between;
  flex-basis: 65.572222%;
  flex-direction: row;
}
.planet-info .ephemeris > div {
  width: calc(50% - 22px);
  padding: 20px;
  background-color: #b3ecf3;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}
.planet-info .ephemeris .EphemerisForToday > h3 {
  text-align: center;
  margin: 0 0 20px;
  font-size: 20px;
}
.planet-info .ephemeris .EphemerisForToday > span {
  display: block;
  text-align: center;
  margin: 0 0 10px;
}
.planet-info .ephemeris .EphemerisForToday .currentCityInfo {
  margin: 0 0 10px;
}
.planet-info .ephemeris .EphemerisForToday .currentCityInfo .PlanetListBlock {
  background: #fff;
  padding: 10px;
  margin: 0;
}
.planet-info .ephemeris .EphemerisForToday .currentCityFooter {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 5px 10px;
}
.planet-info .ephemeris .EphemerisForToday .currentCityFooter b {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  margin: 0 0 5px;
}
.planet-info .ephemeris .EphemerisForToday .currentCityFooter p {
  text-align: center;
}
.planet-info .ephemeris .EphemerisForToday .currentCityFooter p span {
  font-weight: 700;
}
.planet-info .ephemeris .planet-links .stations,
.planet-info .ephemeris .planet-links .signs {
  background: #fff;
  padding: 10px 20px;
}
.planet-info .ephemeris .planet-links h5 {
  margin: 15px 0;
  padding: 12px 20px;
  text-align: center;
  background: #fff;
}
.planet-info .ephemeris .planet-links h5 a {
  font-size: 16px;
  color: #3434e2;
}
.planet-info .ephemeris .planet-links > div h4 span {
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 2px solid #000;
}
.planet-info .ephemeris .planet-links > div h4,
.planet-info .ephemeris .planet-links > div span {
  text-align: center;
}
.PlanetRetrogradesSignsLinks .stations-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 130px;
  align-content: space-between;
}
.PlanetRetrogradesSignsLinks .stations-list.sun-moon-list {
  height: auto;
  margin: 0 0 10px;
  justify-content: center;
}
.PlanetRetrogradesSignsLinks .stations-list.sun-moon-list a:first-child {
  margin: 0 20px 0 0;
}
.PlanetRetrogradesSignsLinks .stations-list > a {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  width: 30%;
  text-align: center;
  height: 35px;
  color: #3434e2;
}
.PlanetRetrogradesSignsLinks .stations-list > a img {
  height: 100%;
  margin: 0 5px 0 0;
}
.planet-info .ephemeris .signs {
  margin: 0 0 10px;
}

.panchang .panchang-top {
  color: white;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 25px;
  text-align: center;
}

.panchang .panchang-content {
  padding: 10px 25px;
  display: flex;
  flex-direction: column;
}

.panchang .panchang-content .text_error_out {
  font-size: 16px;
  display: flex;
  justify-content: center;
}

.panchang .panchang-content .text_error_out span {
  width: 230px;
  text-align: center;
  margin-top: 150px;
  line-height: 20px;
}

.panchang .row_between {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  width: 100%;
}

.panchang .moon_sign span:first-child,
.panchang .sun_sign span:first-child {
  font-weight: 700;
}

.panchang .sun_sign,
.panchang .moon_sign {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.panchang .center {
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.panchang .sign_img {
  padding-top: 4px;
}

.panchang .sun_sign img,
.panchang .moon_sign img {
  width: 17px;
  height: 17px;
  float: left;
  margin: -1px 4px 0 0;
}

.panchang .text {
  display: flex;
  flex-direction: column;
  line-height: 19px;
  margin: 5px 0;
}

.panchang .text b {
  font-weight: 700;
}

.panchang hr {
  border: 0;
  height: 2px;
  background: black;
  width: 100%;
}

.panchang .panchang-bottom {
  padding: 0 16px;
}

.panchang .panchang-bottom b {
  color: #3434e2;
}

@media (min-width: 1081px) {
  .planet-info .panchang {
    width: 31.1111% !important;
  }
}

@media (max-width: 1199px) {
  .planet-info {
    flex-direction: column;
  }
  .planet-info .panchang {
    margin: 0 0 20px;
  }
  .planet-info > div {
    width: 100% !important;
  }
}

@media (min-width: 1081px) {
  .planet-info .ephemeris {
    flex-basis: 65.572222% !important
  }
}

@media (max-width: 1080px) {
  .planet-info .panchang {
    display: none;
  }
  .planet-info .ephemeris {
    margin: 20px 0;
  }
  .planet-info .ephemeris .planet-links,
  .planet-info .ephemeris .EphemerisForToday {
    width: 49%;
  }
}
@media (max-width: 980px) {
  .planet-info .panchang {
    display: none;
  }
}
@media (max-width: 789px) {
  .planet-info .ephemeris {
    flex-direction: column;
  }
  .planet-info .ephemeris > div {
    width: 100%;
  }
}
@media (max-width: 750px) {
  .planet-info .ephemeris {
    margin-top: 0;
    flex-basis: 100%;
  }
  .planet-info .ephemeris .planet-links {
    margin-bottom: 20px;
  }
  .planet-info .ephemeris .planet-links,
  .planet-info .ephemeris .EphemerisForToday {
    width: 100%;
  }
}
@media (max-width: 419px) {
  .planet-info .ephemeris .planet-links > div .stations-list {
    height: 220px;
    height: auto;
    justify-content: center;
  }
  .planet-info .ephemeris .planet-links > div .stations-list a {
    width: 48%;
    margin: 0 4% 0 0;
  }
  .planet-info .ephemeris .planet-links > div .stations-list a:nth-child(even) {
    margin: 0;
  }
  .planet-info .ephemeris .planet-links > div .stations-list.sun-moon-list {
    flex-wrap: initial;
    justify-content: space-between;
    margin: 0;
  }
  .planet-info .ephemeris .planet-links > div .stations-list a {
    width: 48%;
  }
  .planet-info
    .ephemeris
    .planet-links
    > div
    .stations-list.sun-moon-list
    a:first-child {
    margin: 0 4% 0 0;
  }
}
