.contact {
	max-width: 1100px;
	width: 100%;
	margin: 0 auto;
	line-height: 1.2;
}

.contact__title {
	margin-bottom: 24px;
	padding-bottom: 10px;
	border-bottom: 1px solid #000;
}

.contact-wrapper {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: flex-start;
	padding: 10px;
}

.contact-left {
	flex: 1;
	max-width: 35%;
	width: 100%;
	padding-top: 6px;
	border-right: 1px solid #d4d4d4;
}

.conctact-form {
	display: flex;
	flex-flow: column wrap;
	max-width: 304px;
	width: 100%;
}

.contact-form__captcha {
	margin-top: 30px;
}

.contact-right {
	flex: 1;
	padding-left: 100px;
	padding-top: 6px;
}

.contact-form__input label {
	top: 34px !important;
	color: #555 !important;
}

.contact__button {
	display: block;
	margin: 30px auto 0;
	padding: 2px 10px;
	border: 0;
	border-radius: 0;
	background-color: #ff8341;
	color: #000;
	cursor: pointer;
}

.contact_detail-main {
	margin-bottom: 12px;
}

.contact_detail-location {
	color: #555;
}

.contact_detail-email {
	margin-top: 16px;
	color: #555;
}

.contact_ok {
	width: 100%;
	color: green;
	font-size: 14px;
	margin-top: 20px;
}

.contact_error {
	width: 100%;
	color: red;
	font-size: 14px;
	margin-top: 20px;
}

@media screen and (max-width: 1080px) {
	.contact-right {
		padding-left: 50px;
	}
}

@media screen and (max-width: 1050px) {
	.contact-left {
		max-width: 45%;
	}
}

@media screen and (max-width: 800px) {
	.contact-wrapper {
		flex-flow: column wrap;
	}
	.contact-form__input {
		width: 100% !important;
	}
	.contact__title {
		margin-bottom: 0;
	}
	.conctact-form {
		max-width: unset;
	}
	.contact-form__captcha {
		margin: 30px auto 0;
	}
	.contact-left {
		max-width: unset;
		border-right: 0;
		padding-bottom: 30px;
		border-bottom: 1px solid #d4d4d4;
	}
	.contact-right {
		width: 100%;
		padding-top: 20px;
	}
}

@media screen and (max-width: 600px) {
	.contact-right {
		padding-left: 0;
	}
}
