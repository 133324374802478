.events-calendars .calendars {
	display: flex;
	margin: 0 0 30px;
}
.events-calendars .calendars > h5 {
	width: 140px;
	margin: 0 10px;
}
.events-calendars .calendars .calendar-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 10%;
}
.events-calendars .calendars .calendar-block h5 {
	color: #909090;
}
.events-calendars .calendars .calendar-block .modal-wrapper > div {
	min-width: auto !important;
	border-radius: 8px;
	box-shadow: none !important;
}
.events-calendars .calendars .calendar-block button {
	background: #f19752 !important;
	border: 1px solid #f19752 !important;
	cursor: pointer !important;
	border-radius: 8px !important;
	padding: 3px 0 !important;
	width: 60px !important;
	text-align: center !important;
	height: 24px !important;
	color: #fff;
}
.events-calendars .calendars .calendar-block button > div {
	height: 100% !important;
}
.events-calendars .calendars .calendar-block button div {
	height: 100% !important;
	display: flex;
	justify-content: center;
	align-items: center;
}
.events-calendars .calendars .calendar-block button span {
	color: #fff !important;
	margin: 0 !important;
	padding: 0 !important;
	font-size: 1em !important;
	line-height: 1em !important;
}
.signin-account-block {
	display: flex;
	margin: 0 0 20px;
	position: relative;
}
.signin-account-block:last-child {
	margin: 0;
}
.signin-account-block label {
	width: 130px;
	display: flex;
	align-items: center;
	margin: 0 20px 0 0;
}
.signin-account-block input {
	border: 1px solid #ccc;
	border-radius: 5px;
	height: 30px;
	padding: 5px 15px;
	margin: 0 20px 0 0;
	width: auto;
	flex-grow: 1;
}
.signin-account-block button {
	background: #f19752;
	border: 1px solid #f19752;
	color: #fff;
	cursor: pointer;
	padding: 3px 0;
	width: 60px;
	border-radius: 8px;
}
.signin-account-block a span {
	text-transform: capitalize;
	cursor: pointer;
}
.signin-account-block > span {
	position: absolute;
	color: #ff2929;
	top: 100%;
	left: 50%;
	transform: translate(-50%, 2px);
	transition: 0.5s;
}
.add-exchanged {
	height: 28px;
	margin-top: -5px;
	margin-left: auto;
	padding: 2px 15px;
	border: 1px solid #ff925d;
	border-radius: 8px;
	background: #ff925d;
	color: #fff;
	cursor: pointer;
}

.google-synchronized {
	padding: 4px 6px;
	font-weight: 500;
	border-radius: 8px;
	text-transform: uppercase;
	background: #f19752;
	color: #fff;
}

.google-sync-link {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background: #f19752;
	border-radius: 8px;
	user-select: none;
	text-decoration: none !important;
	font-weight: 500;
	width: 60px;
	min-height: 24px;
	transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.google-sync-link:hover span {
	border-radius: 2px;
	background-color: rgba(0, 0, 0, 0.08);
}
