.example {
  padding: 0 25px;
}

#countryCode::placeholder {
  font-size: 12px;
  color: lightgrey;
}
#countryCode {
  border: 1px solid lightgrey;
  width: 100%;
}
label {
  display: block;
  margin: 5px 0;
}

code {
  padding: 0.2em 0.5em;
  font-size: 85%;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
}

.menu {
  position: absolute;
  box-sizing: border-box;
  width: 350px;
  border: 1px solid #cccccc;
  background-color: white;
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;
}

.item {
  padding: 2px 6px;
  cursor: default;
}

.item-highlighted {
  color: white;
  background-color: #4095bf;
}

.item-header {
  background-color: #eeeeee;
  color: #454545;
  font-weight: bold;
}
