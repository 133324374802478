footer {
	background: #008854;
	color: #fff;
}

footer .footer-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 1440px;
	padding: 0 15px;
}

footer .footer-wrapper .logo_wrapper .logo_wrapper_block {
	width: 160px;
}

footer .footer-wrapper .logo_wrapper .logo_wrapper_block img {
	max-width: 70%;
}

footer .footer-wrapper .footer-links {
	display: flex;
	align-items: center;
}

footer .footer-wrapper .footer-links li {
	border-right: 1px solid #fff;
	padding: 0 5px;
}

footer .footer-wrapper .footer-links li:first-child {
	padding: 0 5px 0 0;
}

footer .footer-wrapper .footer-links li:last-child {
	border-right: none;
	margin: 0;
	padding: 0 0 0 5px;
}

footer .footer-wrapper .footer-links li a {
	color: #fff;
}

footer .footer-wrapper .social-links {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 150px;
}

footer .footer-wrapper .social-links li {
	width: 30px;
}

footer .footer-wrapper .social-links li img {
	color: #fff;
	width: 100%;
}
@media screen and (max-width: 1199px) {
	footer .footer-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding-top: 10px;
	}

	footer .footer-wrapper .logo_wrapper {
		margin: 0 0 10px;
	}

	footer .footer-wrapper .logo_wrapper .logo_wrapper_block img {
		margin-left: 15%;
	}

	footer .footer-wrapper > span {
		margin: 0 0 15px;
	}

	footer .footer-wrapper .social-links {
		margin: 0 0 15px;
	}

	footer .footer-wrapper .footer-links {
		flex-wrap: wrap;
		justify-content: center;
	}

	footer {
		padding-bottom: 15px;
	}
}

@media only screen and (max-width: 490px) {
	footer .footer-wrapper > span {
		text-align: center;
	}

	footer .footer-wrapper .footer-links li {
		margin-bottom: 7px;
	}
}
