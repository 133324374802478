.mobile-menu a {
	color: #000;
	text-decoration: none;
	margin-bottom: 10px;
}

.mobile-menu a:hover {
	text-decoration: none;
}

.mobile-menu ul {
	list-style-type: none;
}

.mobile-menu .top-menu {
	position: sticky;
	top: 0;
	left: 0;
	background-color: #008854;
	color: #fff;
}

.mobile-menu .top-menu .auth-title {
	margin-bottom: 14px;
	font-weight: 700;
}

.mobile-menu .top-menu a {
	color: #fff;
}

.mobile-menu .top-menu a:not(:last-child) {
	display: block;
	margin-bottom: 14px;
}

.mobile-menu .top-menu {
	padding: 15px 20px;
}

.mobile-menu .bottom-menu-wrapper {
	padding: 15px 20px 0;
	border-bottom: 1px solid #ccc;
}

.mobile-menu .bottom-menu-wrapper a,
.mobile-menu .bottom-menu-wrapper p {
	display: block;
	margin-bottom: 10px;
}

.mobile-menu .menu-item-title {
	display: block;
	color: #000;
	font-weight: 700;
	margin-bottom: 10px;
}

.mobile-menu a.menu-item-title_mb {
	margin-bottom: 0;
}

.mobile-menu .sub-menu {
	display: none;
	font-size: 14px;
	padding-left: 15px;
}

.mobile-menu .sub-menu-action:hover .sub-menu {
	display: block;
}

.mobile-menu .sub-menu__title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 180px;
	margin-bottom: 10px;
}

.mobile-menu .sub-menu__title p {
	margin: 0;
}

.mobile-menu .sub-menu__title i {
	height: 20px;
}
