/* Material-icons font */
@font-face {
	font-display: swap;
	font-family: "Material Icons";
	font-style: normal;
	font-weight: 400;
	src: url(./fonts/material-iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
	src: local("Material Icons"), local("MaterialIcons-Regular"),
		url(./fonts/material-iconfont/MaterialIcons-Regular.woff2)
			format("woff2"),
		url(./fonts/material-iconfont/MaterialIcons-Regular.woff) format("woff"),
		url(./fonts/material-iconfont/MaterialIcons-Regular.ttf)
			format("truetype");
}

.material-icons {
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	font-size: 24px; /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	-webkit-font-feature-settings: "liga";
	font-feature-settings: "liga";
}

/* General reset */
form,
fieldset {
	margin: 0;
	padding: 0;
	border-style: none;
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: normal;
	line-height: 1.1;
}
p,
figure {
	margin: 0;
	font-weight: normal;
}
h1 {
	font-size: 36px;
}
h2 {
	font-size: 30px;
}
h3 {
	font-size: 24px;
}
h4 {
	font-size: 18px;
}
h5 {
	font-size: 14px;
}
h6 {
	font-size: 12px;
}
img {
	border-style: none;
	display: block;
	font-size: 0 !important;
}

input,
select,
textarea,
button {
	vertical-align: middle;
	font: 100% Roboto, sans-serif;
	outline: none;
}

textarea {
	max-width: 100%;
}

label {
	text-align: left !important;
	margin: 0;
}
input:focus,
select:focus,
textarea:focus {
	outline: none !important;
}

a {
	color: #3434e2;
	text-decoration: none;
}
a:focus {
}

a:focus:hover,
a:hover {
	/* color: #85aad6;
    text-decoration: none; */
	text-decoration: underline;
}

mark {
	background: none;
	display: block;
}

* {
	box-sizing: border-box;
}

*::selection {
	background: rgb(215, 233, 255);
}

.form-control:focus {
	box-shadow: none;
}

section {
	padding: 15px 0;
}

button:focus {
	outline: none;
}

/* General styles */
html {
	height: 100%;
}

body {
	color: #000;
	background: #fff;
	margin: 0;
	padding: 0;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	line-height: 16px;
	height: 100%;
	/* min-width: 320px; */
}

#root {
	height: 100%;
}

.main-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
}

.main-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
}

header {
	display: flex;
	flex: 0 0 auto;
	justify-content: center;
	width: 100%;
}

main {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	justify-content: center;
	width: 100%;
	max-width: 1440px;
	/* max-width: 1440px; */
	padding: 0 15px;
	margin: 30px auto;
}

footer {
	display: flex;
	flex: 0 0 auto;
	justify-content: center;
	width: 100%;
}

.App {
	height: 100%;
}

/* button */
.cityDateButton {
	background: #3cc4ea;
	border-radius: 5px;
	border: 1px solid #3cc4ea;
	color: #fff;
	padding: 5px 10px;
	box-shadow: 0px 0px 7px 0px #3cc4ea;
	transition: 0.25s;
}
.cityDateButton:hover {
	cursor: pointer;
	box-shadow: 0px 0px 15px 0px #3cc4ea;
	transition: 0.25s;
}
/* text overflow (...) */
.ellipsis {
	overflow: hidden;
	height: 45px;
	width: 100%;
	font-size: 14px;
	line-height: 16px;
}
.ellipsis:before {
	content: "";
	float: left;
	width: 5px;
	height: 45px;
}
.ellipsis > *:first-child {
	float: right;
	width: 100%;
	margin-left: -5px;
	font-size: 14px;
	line-height: 16px;
}
.ellipsis:after {
	content: "\02026";
	box-sizing: content-box;
	float: right;
	position: relative;
	top: -25px;
	left: 100%;
	width: 3em;
	margin-left: -3em;
	padding-right: 5px;
	text-align: right;
	background-size: 100% 100%;
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0),
		white 50%,
		white
	);
}
