.eclipsesSolarLunarInfo .eclipse-city-information{
    margin: 0 0 30px;
}
.eclipsesSolarLunarInfo .eclipse-city-information .eclipse-city-link{
    border-bottom: 1px solid #b0b1ac;
    padding: 10px 0;
}
.eclipsesSolarLunarInfo .eclipse-city-information .eclipse-city-link:first-child{
    padding-top: 0;
}
.eclipsesSolarLunarInfo .eclipse-city-information .eclipse-city-link:last-child{
    border: none;
    padding-bottom: 0;
}
.eclipsesSolarLunarInfo .eclipse-city-information .eclipse-city-link h4.eclipse-tittle{
    margin: 0;
}
.eclipsesSolarLunarInfo .eclipse-city-information .eclipse-city-link .city_flag_name{
    display: flex;
    align-items: center;
}
.eclipsesSolarLunarInfo .eclipse-city-information .eclipse-city-link .city_flag_name .cityMainImg{
    display: block;
    position: relative;
    width: auto;
    height: 30px;
    padding: 0 5px 0 0;
    margin: 0;
}
.eclipsesSolarLunarInfo .eclipse-city-information .eclipse-city-link .city_flag_name h4{
    margin: 0;
}


.SunCatalogEclipses .CatalogTableEclipses .SunData,
.SunCatalogEclipses .eclipsesSolarLunar.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .CityTableEclipses{
    margin: 0;
}
.CatalogTableEclipses{
    min-height: 200px; 
}
.CatalogTableEclipses .catalog-title-block{
    display: flex;
}
.CatalogTableEclipses .catalog-title-block .eclipse_flag{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 150px;
}
.CatalogTableEclipses .catalog-title-block .eclipse_flag img{
    width: 88px;
    height: 88px;
}
.CatalogTableEclipses .citySunRiseSet .country_flag img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    max-width: 80%;
    max-height: 80%;
}
.CatalogTableEclipses .catalog-title-block .catalogEclipses{
    flex-grow: 1;
}
.CatalogTableEclipses .catalog-title-block .catalogEclipses .month-year-change{
    display: flex;
    /* justify-content: flex-end; */
}
.CatalogTableEclipses .catalog-title-block .catalogEclipses .month-year-change > div {
    margin: 0 20px 0 0;
}
.CatalogTableEclipses .catalog-title-block .catalogEclipses .month-year-change > div:last-child{
    margin: 0;
}
.CatalogTableEclipses .catalog-title-block .catalogEclipses .month-year-change > button{
    width: 70px;
    height: 48px;
    background: #e9e9e9;
    color: #7f7f7f;
    border: none;
    cursor: pointer;
    padding: 0;
}
.CatalogTableEclipses .catalog-title-block .catalogEclipses .month-year-change > button:hover{
    background: #008853;
    color: #fff;
}
.CatalogTableEclipses .SunData{
    display: flex;
}
.CatalogTableEclipses .SunData .eclipse-content{
    width: 76%;
    padding: 0 1% 0 0;
}
.CatalogTableEclipses .SunData .eclipses-img{
    width: 25%;
}
.CatalogTableEclipses .SunData .eclipses-img > div{
    margin: 0 0 20px !important;
}
.CatalogTableEclipses .SunData .eclipses-img > div:last-child{
    margin: 0 !important;
}
.CatalogTableEclipses .SunData .eclipses-img .sunCulcCityInfoRightSide .eclipse-info-header{
    background: #63697d;
    padding: 16px;
    color: #fff;
}

.CatalogTableEclipses .SunData .month-year-change{
    width: 100%;
    justify-content: flex-end;
}
.CatalogTableEclipses .SunData .month-year-change .sun-country-select{
    margin: 0 20px 0 0;
}

.SunCatalogEclipses .SunData .advertising-block-main{
    margin: 0;
}

@media screen and (max-width: 1250px) {
    .CatalogTableEclipses .SunData{
        flex-direction: column;
    }
    .CatalogTableEclipses .SunData > div{
        width: 100% !important;
    }
}
@media screen and (max-width: 840px) {
    .CatalogTableEclipses .catalog-title-block .eclipse_flag{
        display: none;
    }
}
@media screen and (max-width: 679px) {
    .CatalogTableEclipses .catalog-title-block .catalogEclipses .month-year-change > div{
        width: 100% !important;
        margin: 0 0 20px;
    }
    .SunCatalogEclipses .eclipsesCitySolarLunar .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .CatalogTableEclipses .month-year-change .sun-year-select,
    .SunCatalogEclipses .eclipsesCitySolarLunar .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .CatalogTableEclipses .month-year-change .sun-month-select{
        width: 100% !important;
    }
    .CatalogTableEclipses .catalog-title-block .catalogEclipses .month-year-change > div:last-child{
        margin: 0;
    }
}