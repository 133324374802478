/* for all pages of calculators */
.Calculator-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Calculator-content {
  width: 100%;
}

@media (max-width: 991px) {
  .Calculator-wrapper {
    flex-flow: column wrap;
  }
}
/* end - for all pages of calculators */

.calc-sidebar {
  max-width: 300px;
  width: 100%;
  margin-left: 30px;
  margin-top: 30px;
}

.calc-sidebar-block {
  margin-bottom: 20px;
  background-color: #f5f5f5;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
}

.calc-sidebar-block p {
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  background: #ff763c;
}

.calc-sidebar-block ul {
  padding: 12px 10px 12px 16px;
  list-style: none;
}

.calc-sidebar-block ul li {
  margin-bottom: 14px;
}

.calc-sidebar-block ul li a {
  line-height: 1.2;
}

.calc-sidebar-block.calc-sidebar-block_ads {
  background-color: #fff;
  padding: 10px;
}

.calc-sidebar-block.calc-sidebar-block_ads img {
  width: 100%;
}

@media (max-width: 991px) {
  .calc-sidebar {
    max-width: unset;
    margin: 40px 0 0;
  }
  .calc-sidebar-block.calc-sidebar-block_ads img {
    height: 250px;
    object-fit: contain;
  }
}
