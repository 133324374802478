
.moon-calc-page .sunCulcCityInfoCenter .color-slider-sunRise-wrapper .color-slider-sunRise .sunRise-slider > div > div > div:last-child{
    background-color: transparent !important;
    border: 2px solid transparent !important;
}
.moon-calc-page .sunCulcCityInfoCenter .color-slider-sunRise-wrapper .color-slider-sunRise .sunRise-slider > div > div > div:last-child:after{
    content: 'brightness_3';
    font-family: 'Material Icons';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -30%;
    right: 0;
    margin: auto;
    font-size: 28px;
    height: 17px;
    width: 23px;
    display: block;
    color: #3b4f7e;
    transform: rotate(35deg);
}
.moon-calc-page .sunCulcCityInfoCenter .color-slider-sunRise-wrapper .color-slider-sunRise .sunRise-slider > div > div > div:last-child > div > div > div{
    /* background: rgb(0, 188, 212) !important; */
    background: #3b4f7e !important;
    height: 49 !important;
    width: 49px !important;
    top: 10px !important;
    left: -13px !important;
    right: 0;
    bottom: 0;
    margin: auto;
}
.moon-calc-page circle:after{
    content: 'brightness_3';
    font-family: 'Material Icons';
    /* position: absolute;
    top: 0;
    bottom: 0;
    left: -30%;
    right: 0;
    margin: auto; */
    font-size: 28px;
    height: 17px;
    width: 23px;
    display: block;
    color: #b3e5fc;
}
.moon-calc-page .sunCulcCityInfoCenter .color-slider-sunRise-wrapper .color-slider-sunRise {
    background: rgb(181, 181, 181);
}