.InformationBlocks {
  position: relative;
  min-height: 200px;
  max-width: 1440px;
  margin: 0 auto;
}
.InformationBlocks > h3 {
  margin: 0 0 10px;
}
.InformationBlocks > span.home-city {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}
.InformationBlocks > span.home-city i {
  font-style: normal;
  color: #3434e2; /*#556bb5;*/
  cursor: pointer;
}
.MainPage.InformationBlocks .CityInfoMapBlocks {
  width: 100%;
}
.InformationBlocks .loader-spinner {
  position: absolute;
  top: calc(50% - 40px);
  bottom: calc(50% - 40px);
  margin: 0 !important;
}
.InformationBlocks > h3 {
  display: block;
  width: 100%;
  text-align: center;
}

.InformationBlocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.InformationBlocks > div:nth-child(1),
.InformationBlocks > div:nth-child(2),
.InformationBlocks > div:nth-child(3) {
  margin: 0 0 25px;
}
.InformationBlocks > div {
  display: flex;
  flex-direction: column;
  flex-basis: 31.111111%;
  height: auto;
  overflow: hidden;
}
.InformationBlocks > div p {
  margin: 0 0 10px;
  display: block;
}
.InformationBlocks > div > .currentCityHeader {
  display: flex;
  height: 100px;
  height: 75px;
}
.InformationBlocks > div > .currentCityHeader .currentCityImg {
  width: 30%;
  position: relative;
}
.InformationBlocks > div > .currentCityHeader .currentCityImg img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  padding: 8px;
}
.InformationBlocks > div > .currentCityHeader .currentCityTittle {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  color: #fff;
}
.InformationBlocks .currentCityTittle .title-block {
  width: 100%;
}
.InformationBlocks > div > .currentCityInfo {
  padding: 10px 7%;
  flex-grow: 1;
  background: #f5f5f5;
  color: #444f4f;
}
.InformationBlocks > div > .currentCityInfo .hover_effect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0;
  min-height: 44px;
}
.InformationBlocks > div > .currentCityInfo .hover_effect a {
  display: block;
  width: 100%;
  color: #3434e2; /*#556bb5;*/
}
.InformationBlocks > div > .currentCityInfo b {
  display: block;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}
.InformationBlocks > div > .currentCityInfo em {
  font-style: normal;
  color: #7f7f7f;
  font-size: 12px;
  line-height: 14px;
}
.InformationBlocks > div > .currentCityFooter .currentCityFooterRight {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
@media (max-width: 1199px) {
  .InformationBlocks > div {
    flex-basis: 48%;
  }
}

@media (max-width: 768px) {
  .InformationBlocks > div {
    flex-basis: 100%;
    height: auto;
    margin: 0 0 25px;
  }
  .InformationBlocks > div:last-child {
    margin: 0;
  }
}
