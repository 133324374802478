.eclipsesSolarLunar.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .eclipses-img .sunCulcCityInfoRightSide{
    margin: 0;
}
.eclipsesSolarLunar.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .eclipses-img .sunCulcCityInfoRightSide .eclipse-info{
    width: 100%;
}
.eclipsesSolarLunar.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .eclipses-img .sunCulcCityInfoRightSide .eclipse-info b{
    width: 100%;
}
.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .eclipses-img h4{
    margin: 14px 0; 
}

.SunCityEclipses .eclipsesSolarLunarInfo > .eclipses-img > div{
    width: 100%;
    margin: 0 0 20px !important;
}
.SunCityEclipses .eclipsesSolarLunarInfo > .eclipses-img > div:last-child{
    margin: 0 !important;
}
.SunCityEclipses .eclipsesSolarLunarInfo > .eclipses-img .sunCulcCityInfoRightSide .eclipse-info .eclipse-info-header{
    padding: 16px;
    background: #63697d;
    color: #fff;
}
.SunCityEclipses .eclipsesSolarLunarInfo > .eclipses-img .sunCulcCityInfoRightSide{
    display: block
}
.SunCityEclipses .CityTableEclipses .citySunRiseSet{
    background: #63697d;
    color: #fff;
}
.SunCityEclipses .CityTableEclipses .citySunRiseSet .city_name_date{
    padding: 0;
}

.eclipsesSolarLunar.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .CityTableEclipses{
    width: 100%;
    background: #fff;
    box-shadow: none;
}
.eclipsesSolarLunar.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .month-year-change .sun-year-select{
    width: 160px !important;
}
.eclipsesSolarLunar.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .month-year-change .sun-month-select{
    width: 135px !important;
}
.geotimedate-table.eclipse-table .sticky-table-y-wrapper .sticky-table-row .sticky-table-cell{
    height: 55px;
}
.geotimedate-table.eclipse-table .sticky-table-row .sticky-table-cell.picture-lunar{
    max-width: 150px;
}
.geotimedate-table.eclipse-table .sticky-table-row .sticky-table-cell.picture-lunar b{
    position: relative;
    height: 100%;
}
.geotimedate-table.eclipse-table .sticky-table-row .sticky-table-cell.picture-lunar img{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    padding: 2px;
}
.SunCityEclipses .SunData{
    margin: 0;
}
.SunCityEclipses .SunData .sun-month-year-change {
    justify-content: flex-end;
}
.SunCityEclipses .CityTableEclipses > a{
    display: block;
}
.SunCatalogEclipses .eclipse-content > a{
    display: block;
}
.SunData .sun-month-year-change .month-chnge {
    width: 64px;
    height: 32px;
    background: #008853;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 0;
    align-self: center;
    margin-left: auto;
}


@media screen and (max-width: 1250px) {
    .SunCityEclipses .eclipsesSolarLunarInfo{
        display: block;
    }
    .SunCityEclipses .eclipsesSolarLunarInfo > div{
        width: 100% !important;
    }
    .SunCityEclipses .eclipsesSolarLunarInfo > .sunCulcCityInfoRightSide{
        margin: 0 0 20px;
    }
}
@media (max-width: 1099px) {
    .eclipsesCitySolarLunar .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide{
        flex-basis: auto;
        width: 100%;
        margin: 0 0 20px;
    }
    .eclipsesSolarLunar .eclipsesSolarLunarInfo {
        flex-direction: column;
        align-items: center;
    }
    .eclipsesSolarLunar .eclipsesSolarLunarInfo .eclipses-img{
        flex-basis: auto;
        width: 100%;
        max-width: 100%;
        flex-basis: 435px;
    }
}
@media screen and (max-width: 767px) {
    .SunCityEclipses .eclipsesSolarLunarInfo > .sunCulcCityInfoRightSide{
        flex-direction: column;
    }
    .SunCityEclipses .eclipsesSolarLunarInfo > .sunCulcCityInfoRightSide > div{
        width: 100% !important;
        margin: 0 0 20px !important;
    }
    .SunCityEclipses .SunData .sun-month-year-change .month-year-change{
        width: 100%;
        flex-direction: column;
    }
    .SunCityEclipses .eclipsesSolarLunar.eclipsesCitySolarLunar .eclipsesSolarLunarInfo .month-year-change > div{
        width: 100% !important;
    }
    .SunCityEclipses .SunData .month-year-change .sun-year-select{
        flex-basis: auto !important;
    }
    .SunCityEclipses .SunData .month-year-change .sun-month-select{
        flex-basis: auto !important;
    }
}
@media (max-width: 649px) {
    .SunCityEclipses .eclipsesCitySolarLunar .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide{
        flex-direction: column;
    }
    .SunCityEclipses .eclipsesCitySolarLunar .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info{
        width: 100%;
        margin: 0;
    }
    .SunCityEclipses .eclipsesCitySolarLunar .eclipsesSolarLunarInfo .sunCulcCityInfoRightSide .eclipse-info:first-child{
        margin: 0 0 20px;
    }
}