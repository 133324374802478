.Holidays {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 1040px;
}

.Holidays .cellHolidays {
	padding: 10px 30px;
}

@media (max-width: 1200px) {
	.sticky-table-cell {
		width: calc(30%) !important;
	}

	.cellHolidays_date {
		width: 30% !important;
	}

	.week {
		width: 16% !important;
	}
}
