.SunChartsDraw{
    display: flex;
    position: relative;
    margin: 0 0 30px;
}
.SunChartsDraw div:first-child{
    position: absolute;
    top: 0;
    left: 0;
    ;
}