.searcj-block-loc_event{
    width: 100%;
}
.search_form_select{
    width: 100%;
    height: 60px;
}
.search_field{
    width: 100%;
}
.block-countdown-event{
    width: 34%;
}