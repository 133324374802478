.timePlaner .timePicker {
	position: relative;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}
.timePicker-note {
	margin-top: 1em;
	padding-left: 1em;
	line-height: 1.5;
}
.timePicker-note_align {
	padding-left: 10px;
}
.timePlaner .timePicker.timePlanerLoader {
	min-height: 240px;
}
.timePlaner .timePicker .loader-spinner {
	position: absolute;
	top: calc(50% - 40px);
	bottom: calc(50% - 40px);
}
.time-picker-header {
	display: flex;
	background: #008854;
	color: #fff;
	padding: 10px 0;
}
.time-picker-header .currentCityImg {
	position: relative;
	width: 30%;
	max-width: 121px;
	height: 100%;
}
.time-picker-header .currentCityImg img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	max-height: 100%;
	max-width: 100%;
	padding: 5px;
}
.time-picker-header .currentCityTittle {
	display: flex;
	flex-grow: 1;
	padding: 0 20px;
}
.time-picker-header .currentCityTittle .time-planner-icons {
	display: flex;
	padding-left: 5px;
}
.time-picker-header .currentCityTittle .time-planner-icons a:not(:last-child) {
	margin-right: 5px;
}
.time-picker-slider-scale {
	border: 1px solid #d4d4d4;
}
.currentCityTittle .title-block {
	height: 100%;
	width: 100px;
	padding: 0 20px 0 0;
}
.time-picker-header .currentCityTittle .title-block {
	display: flex;
	align-items: center;
	flex-grow: 1;
	height: auto;
}
.time-picker-header .currentCityTittle .title-block h3 {
	margin: 0 10px 0 0;
}
.currentCityTittle .title-block h3 {
	position: relative;
	margin: 0 0 17px;
}
.currentCityTittle .title-block h3:after {
	content: "";
	position: absolute;
	left: 0;
	top: calc(100% + 8px);
	width: 40px;
	height: 1px;
	background: #fff;
}
.timePlaner .time-picker-header .currentCityTittle .title-block h3 {
	font-size: 20px;
	line-height: 22px;
}
.timePlaner .time-picker-header .currentCityTittle .title-block h3::after {
	display: none;
}
.time-format-date .schedulerCalendarBlock .schedulerCalendar > div input {
	font-size: 14px !important;
	line-height: 24px !important;
}
.set_as_default {
	float: left;
}
.cityBlock {
	position: relative;
	display: flex;
	width: 100%;
	padding: 5px;
}
.cityBlock .time-picker-left > div,
.cityBlock .time-picker-right > div {
	margin: 10px 0 10px;
}
.cityBlock .time-picker-left > div:first-child,
.cityBlock .time-picker-right > div:nth-child(2) {
	margin: 20px 0 10px;
}
.cityBlock .time-picker-left > div:last-child,
.cityBlock .time-picker-right > div:last-child {
	margin: 10px 0 20px;
}

.cityBlock
	.time-picker-right
	> .slider-block-wrapper
	+ .time-picker-slider-scale:last-child {
	margin: 20px 0;
}

.cityBlock .time-picker-left > .time-picker-left-block:last-child:first-child {
	margin: 20px 0;
}

.cityBlock .time-picker-left {
	display: flex;
	width: 275px;
	min-width: 275px;
	flex-direction: column;
}
.cityBlock .time-picker-left > div {
	display: flex;
}
.cityBlock .time-picker-left .time-picker-left-name-block > div,
.cityBlock .time-picker-left .time-picker-left-info-block > div {
	padding: 0 5px;
}
.cityBlock .time-picker-left .time-picker-left-name-block {
	display: flex;
	align-items: center;
	width: 185px;
}
.cityBlock .time-picker-left .time-picker-left-info-block {
	display: flex;
	align-items: center;
	width: 90px;
}
.cityBlock .time-picker-left .add-and-dell-city {
	width: 24px;
	overflow: hidden;
}
.cityBlock .time-picker-left .add-and-dell-city i {
	font-size: 14px;
	line-height: 16px;
	color: #7b7b7b;
	cursor: pointer;
}
.cityBlock .time-picker-left .add-and-dell-city i.dell_city:hover {
	cursor: pointer;
}
.topBlockCities .cityBlock {
	position: relative;
}
.topBlockCities
	.cityBlock
	.time-picker-left
	> div:nth-child(2)
	.add-and-dell-city.reorder-cities
	i:first-child {
	opacity: 0;
}
.topBlockCities
	.cityBlock
	.time-picker-left
	> div:last-child
	.add-and-dell-city.reorder-cities
	i:last-child {
	opacity: 0;
}
.cityBlock .time-picker-left .add-and-dell-city.reorder-cities i:first-child {
	cursor: pointer;
	transform: rotate(-90deg);
}
.cityBlock .time-picker-left .add-and-dell-city.reorder-cities i:last-child {
	cursor: pointer;
	transform: rotate(90deg);
}
.cityBlock .time-picker-left .time-picker-left-flag {
	width: 64px;
	cursor: pointer;
	text-align: center;
}
.cityBlock .time-picker-left .time-picker-left-flag .time-difference {
	font-size: 12px;
	font-weight: 700;
	line-height: 11px;
	font-style: normal;
	color: #d75101;
}
.cityBlock .time-picker-left .time-picker-left-flag img {
	width: 100%;
}
.cityBlock .time-picker-left .time-picker-left-city {
	flex-grow: 1;
	flex-basis: 215px;
	/* cursor: pointer; */
}
.cityBlock
	.time-picker-left
	.time-picker-left-info-block
	.time-picker-left-zone {
	position: relative;
	flex-grow: 1;
	flex-basis: 51px;
	padding: 0 45px 0 5px;
}
.cityBlock .time-picker-left .time-picker-left-time {
	position: relative;
	flex-grow: 1;
	width: 100%;
	padding: 0 30px 0 0 !important;
}
.cityBlock .time-picker-left .time-picker-left-time .time-shift-warning {
	position: absolute;
	top: 0;
	right: 3px;
	bottom: 0;
	width: 26px;
	height: 26px;
	margin: auto;
	cursor: pointer;
}
.cityBlock .time-picker-left .time-picker-left-time img {
	width: 100%;
	height: 100%;
	color: #f72525;
}
.cityBlock .time-picker-left .time-shift-warning .time-shift-alert {
	display: none;
	position: absolute;
	bottom: calc(100% + 10px);
	right: 0;
	width: 275px;
	padding: 5px;
	background: #d2f4ff;
	border: 1px solid rgba(0, 0, 0, 0.87);
	border-radius: 2px;
	text-align: center;
	z-index: 4;
}
.cityBlock .time-picker-left .time-shift-warning:hover .time-shift-alert {
	display: block;
}
.cityBlock .time-picker-left .time-shift-warning .time-shift-alert:before,
.cityBlock .time-picker-left .time-shift-warning .time-shift-alert:after {
	content: "";
	position: absolute;
	top: 100%;
	right: 0;
	border: 12px solid transparent;
	border-top: 10px solid rgba(0, 0, 0, 0.87);
}
.cityBlock .time-picker-left .time-shift-warning .time-shift-alert:after {
	top: calc(100% - 1px);
	border-top: 10px solid #d2f4ff;
}
.cityBlock .time-picker-left p {
	color: #000;
	font-size: 13px;
	line-height: 14px;
}
.cityBlock .time-picker-left span {
	display: block;
	font-size: 9px;
	line-height: 11px;
	color: #888;
}
.timePicker .cityBlock .time-picker-right {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 80%;
	flex-grow: 1;
}
.timePicker .cityBlock .time-picker-right > div {
	min-width: 100%;
}
.cityBlock .time-picker-right .time-picker-slider-scale {
	display: flex;
	position: relative;
	height: 100%;
}
.cityBlock
	.time-picker-right
	.time-picker-slider-scale
	.time-picker-time-block {
	flex-grow: 1;
}
.cityBlock
	.time-picker-right
	.time-picker-slider-scale
	.time-picker-time-block
	.time {
	padding: 0 2px;
	border-left: 1px solid #ddd;
	font-size: 12px;
	line-height: 14px;
}
.cityBlock
	.time-picker-right
	.time-picker-slider-scale
	.time-picker-time-block
	.color-block {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.cityBlock
	.time-picker-right
	.time-picker-slider-scale
	.time-picker-time-block
	.color-block
	.time-picker-hours
	span {
	display: block;
	text-align: center;
}
.cityBlock
	.time-picker-right
	.time-picker-slider-scale
	.time-picker-time-block
	.color-block
	.time-picker-minutes {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 5px 0;
	font-size: 10px;
	line-height: 12px;
}
.cityBlock
	.time-picker-right
	.time-picker-slider-scale
	.time-picker-time-block
	.color-block
	.time-picker-minutes
	div {
	display: flex;
	align-items: center;
}
.timePicker .timePickerSlider {
	position: relative;
	max-width: 1440px;
	margin: 0 auto;
}
.timePicker .timePickerSlider .slider-block-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	z-index: 2;
	margin: 0;
}
.timePicker .timePickerSlider .slider-block-wrapper .slider-block-start {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	z-index: 3;
}
.timePicker .timePickerSlider .slider-block-wrapper .slider-block-end {
	position: absolute;
	top: 0;
	height: 100%;
	z-index: 3;
}
.timePicker .timePickerSlider .slider-block-wrapper .slider-block {
	position: absolute;
	top: 10px;
	height: calc(100% - 16px);
	width: 20px;
	border-radius: 10px;
	z-index: 4;
	padding: 9px 0 13px;
}
.timePicker .timePickerSlider .slider-block-wrapper .slider-block .devider {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 1px;
}
.timePicker
	.timePickerSlider
	.slider-block-wrapper
	.slider-block
	.material-icons {
	position: absolute;
	top: 0;
	cursor: pointer;
	color: #eab310;
	background: #ffffff8a;
	border-radius: 7px;
}
.timePicker .timePickerSlider .slider-block-wrapper .slider-block .time-diff {
	position: absolute;
	top: -9px;
	left: 50%;
	width: 80px;
	text-align: center;
	transform: translate(-50%, 0);
	background: #f5f5f5;
	height: 18px;
	border-radius: 5px;
}
.timePicker
	.timePickerSlider
	.slider-block-wrapper
	.slider-block
	.time-diff:before {
	content: ">";
	position: absolute;
	top: 0;
	left: -6px;
	font-size: 16px;
	line-height: 18px;
	color: rgb(253, 207, 3);
}
.timePicker
	.timePickerSlider
	.slider-block-wrapper
	.slider-block
	.time-diff::after {
	content: "<";
	position: absolute;
	top: 0;
	right: -6px;
	font-size: 16px;
	line-height: 18px;
	color: rgb(253, 207, 3);
}
.timePicker .timePickerSlider .slider-block-wrapper .slider-block .time {
	position: absolute;
	display: block;
	width: 100%;
	min-width: 65px;
	height: 12px;
}
.timePicker .timePickerSlider .slider-block-wrapper .slider-block .time span {
	position: absolute;
	min-width: 60px;
	font-size: 10px;
	line-height: 12px;
}
.timePicker
	.timePickerSlider
	.slider-block-wrapper
	.slider-block
	.time
	span:first-child {
	left: 5px;
	font-weight: bold;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.timePicker
	.timePickerSlider
	.slider-block-wrapper
	.slider-block
	.time
	span:last-child {
	right: 5px;
	font-weight: bold;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.time-format-date {
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
	align-items: center;
}
.time-format-date .schedulerCalendarWrapper {
	display: flex;
	margin: 0 10px 0 0;
}
.time-format-date .schedulerCalendarWrapper .schedulerCalendarBlock {
	position: relative;
	margin: 0 10px 0 0;
	height: 26px;
}
.time-format-date .schedulerCalendarBlock i {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto 0;
	height: 20px;
	width: 20px;
	font-size: 20px;
	background: transparent;
	color: #fff;
	border-radius: 0 !important;
}
.time-format-date .schedulerCalendarBlock .schedulerCalendar hr {
	display: none;
}
.time-format-date .schedulerCalendarBlock .schedulerCalendar > div {
	height: 26px !important;
	width: 150px !important;
}
.time-format-date .schedulerCalendarBlock .schedulerCalendar input {
	color: #fff !important;
	z-index: 1;
	cursor: pointer !important;
}
.time-format-date .calendar-date-picker-buttons {
	display: flex;
	justify-content: space-between;
	width: 86px;
}
.time-format-date .calendar-date-picker-buttons button {
	padding: 0 !important;
	height: 26px !important;
	width: 26px !important;
}
.time-format-date .calendar-date-picker-buttons button i {
	background: #8a8a8a;
	color: #fff;
	border-radius: 50% !important;
	font-size: 19px;
	line-height: 19px;
}
.time-format-date .calendar-date-picker-buttons .prev_date {
	transform: scale(-1, 1);
}

.group-list {
	display: flex;
	position: relative;
	margin: 10px 20px;
	overflow: hidden;
	background: #f5f5f5;
}
.group-list a,
.group-list a:hover,
.group-list a:active {
	text-decoration: none;
}
.group-list a.group-list-signin {
	background: #ff8341;
	border-radius: 5px;
	color: #fff;
	align-self: center;
	margin-left: auto;
	padding: 2px 6px;
}

.group-list .chips-group {
	display: flex;
}

.group-list .chips-group_arrow {
	position: absolute;
	top: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	background-color: #cecece;
	border-radius: 50%;
	transform: translateY(-50%);
	z-index: 2;
	cursor: pointer;
}

.group-list .chips-group_arrow:hover {
	background-color: #c3c3c3;
}

.group-list .chips-group_arrow.chips-group_arrow__left {
	left: 0;
}

.group-list .chips-group_arrow.chips-group_arrow__right {
	right: 0;
}

.time-picker-left-block {
	height: 38px;
}

@media (min-width: 1440px) {
	.timePicker .cityBlock .time-picker-right {
		overflow-x: hidden !important;
	}
}
@media (max-width: 1199px) {
	.timePlaner .timePicker .time-format-date .schedulerCalendarWrapper {
		flex-direction: column;
	}
	.timePlaner .timePicker .time-format-date .calendar-date-picker-buttons {
		justify-content: space-evenly;
		width: 180px;
	}
}
@media (max-width: 991px) {
	.timePlaner .timePicker .time-picker-header .currentCityTittle {
		flex-direction: column;
		align-items: center;
	}
	.timePlaner .timePicker .time-picker-header .Search {
		flex-basis: auto;
		width: 400px;
		margin-bottom: 1em;
	}
	.timePlaner .timePicker .currentCityTittle .title-block {
		height: 100%;
		width: 100%;
		margin: 0 0 20px;
		padding: 0;
	}
	.timePlaner .timePicker .time-format-date .schedulerCalendarWrapper {
		flex-direction: row;
	}
	.timePlaner .time-format-date {
		justify-content: flex-start;
	}
	.timePlaner .cityBlock {
		flex-direction: column;
		height: 365px;
	}
	.timePlaner .cityBlock .time-picker-left {
		width: 100%;
		margin: 5px 0 0;
	}
	.timePlaner .timePicker .cityBlock .time-picker-right {
		position: absolute;
		top: 31px;
		left: 5px;
		width: calc(100% - 10px);
		padding: 0;
		padding: 15px 0 15px 40px;
	}
	.cityBlock .time-picker-left > div {
		margin: 0 0 47px;
		height: 38px;
		align-items: center;
	}
	.cityBlock .time-picker-left > div:first-child {
		margin: 0 0 45px;
	}
	.cityBlock .time-picker-left > div:last-child {
		margin: 0;
	}
	.cityBlock .time-picker-right > div {
		margin: 5px 0 44px;
	}
	.cityBlock .time-picker-right > div:nth-child(2) {
		margin: 5px 0 44px;
	}
	.cityBlock .time-picker-right > div:last-child {
		margin: 5px 0 0;
	}
	.timePicker .timePickerSlider .slider-block-wrapper .slider-block-start,
	.timePicker .timePickerSlider .slider-block-wrapper .slider-block-end {
		top: 20px;
		height: calc(100% - 35px);
	}
	.timePicker .timePickerSlider .slider-block-wrapper {
		position: absolute;
		left: 40px;
	}
	.timePicker .cityBlock .time-picker-right > div {
		min-width: 1125px;
	}
}
@media (max-width: 579px) {
	.timePlaner .timePicker .time-picker-header {
		height: auto;
		padding: 10px 0;
	}
	.timePlaner .timePicker .currentCityTittle .title-block {
		margin: 0 0 10px;
		flex-direction: column;
	}
	.timePlaner .time-picker-header .currentCityTittle .title-block h3 {
		margin: 0 0px 10px 0;
	}
	.timePlaner .timePicker .time-picker-header .Search,
	.timePlaner
		.timePicker
		.time-picker-header
		.currentCityTittle
		.Search
		.search_form
		.search_form_select {
		width: 100% !important;
		margin-bottom: 1em;
	}
}
@media (max-width: 980px) {
	.timePlaner .timePicker {
		box-shadow: none;
	}
}
@media (max-width: 459px) {
	.time-picker-header .currentCityImg {
		display: none;
	}
}
@media (max-width: 369px) {
	.timePlaner .time-format-date {
		flex-direction: column;
		margin: 0 0 10px;
	}
	.timePlaner .timePicker .currentCityTittle .title-block {
		margin: 0;
	}
	.timePlaner .timePicker .time-format-date .schedulerCalendarWrapper {
		margin: 0 0 10px;
	}
	.cityBlock
		.time-picker-left
		.time-picker-left-info-block
		.time-picker-left-zone {
		padding: 0 5px;
		flex-grow: 0;
	}
	.topBlockCities .cityBlock .time-difference {
		position: relative !important;
		font-size: 12px;
		line-height: 14px;
	}
}
