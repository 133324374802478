.world-clock-links {
	position: relative;
}
.world-clock-links__ad {
	display: none;
	align-items: center;
	justify-content: center;
	font-size: 1.5em;
	font-weight: 700;
}
.world-clock-links .currentCityInfo a {
	color: #3434e2;
}
.MainPage .CityInfoMapBlocks {
	width: 100%;
}
.MainPage
	.CityInfoMapBlocks
	.city-info-map-blocks
	.world-clock-links
	.hover_effect {
	background: none;
	min-height: auto;
}
.CityInfoMapBlocks .city-info-map-blocks .world-clock-links .currentCityInfo {
	background: none;
}
.MainPage
	.CityInfoMapBlocks
	.city-info-map-blocks
	.world-clock-links:nth-child(odd) {
	background-color: #dfe7ea;
}
.MainPage
	.CityInfoMapBlocks
	.city-info-map-blocks
	.world-clock-links:nth-child(even) {
	background-color: #edf1f2;
}
.MainPage .CityInfoMapBlocks .city-info-map-blocks .world-clock-links h4 {
	text-align: center;
	margin: 0 0 10px;
}
.alter_names_full {
	min-height: 100px;
	overflow: auto;
	line-height: 1.5;
}
.MainPage .CityInfoMapBlocks .electric-plug {
	position: relative;
}
.MainPage .CityInfoMapBlocks .electric-plug img {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 10px;
	margin: auto 0 auto 10px;
	height: 100%;
}

@media screen and (min-width: 750px) and (max-width: 1038px) {
	.world-clock-links {
		display: block;
		max-width: 49%;
		flex-basis: 100% !important;
	}
}
