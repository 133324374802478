.test-design{
  position: absolute;
  left: 0;
  width: 100%;
  min-height: 520px;
}
.Airplane {
  background: linear-gradient(to bottom left,  rgba(65, 105, 225, .7), rgba(30, 144, 255, .2));
}
.plane {
  top: 10px;
  width: 1100px;
  height: 310px;
  margin: 0px auto 200px;
  position: relative;
  animation: go-top-bottom 10s infinite alternate;
}
 @keyframes go-top-bottom {   /* назовём анимацию: "go-left-right" */
    from {
      top: 10px;
      left: 10px;
      transform: rotate(3deg)
    }
    to {
      top: calc(155px);
      left: calc(-10px);
      transform: rotate(-3deg)
    }
 }
 @-webkit-keyframes go-top-bottom {
    from {
      top: 10px;
      left: 10px;
      transform: rotate(3deg)
    }
    to {
      top: calc(155px);
      left: calc(-10px);
      transform: rotate(-3deg)
    }
 }

.ver-stab {
  width: 12px;
  height: 135px;
  background-color: #B9B8CC;
  border-top-left-radius: 50% 30%;
  border-top-right-radius: 50% 30%;
  position: absolute;
  left: 49.6%;
  top: 3px;
  overflow: hidden;
}

.ver-stab-tri {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 120px 10px;
  border-color: transparent transparent #B9B8CC transparent;
  left: 49.3%;
  top: 6%;
}

.ver-stab:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #656479;
  position: absolute;
  left: 50%;
  border-top-left-radius: 5px 15px;
}

.ver-stab-tri:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 120px 0 0 10px;
  border-color: transparent transparent transparent #656479;
  position: absolute;
}



.nose {
  width: 153px;
  height: 153px;
  background-color: #B9B8CC;
  position: absolute;
  left: 50.1%;
  top: 44.5%;
  transform: translateX(-50%);
  border-radius: 50%;
  overflow: hidden;
}



.nose:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  border-radius: 50%;
  top: -20%;
  left: -15%;
}


.back-body {
  width: 165px;
  height: 80px;
  background: linear-gradient(to left, #D5D4E7 0%, #68687D 100%);
  position: absolute;
  left: 42.6%;
  top: 60.5%;
  border-radius: 50% / 100%;
  z-index: -100;
}

.back-body:before {
  content: "";
  position: absolute;
  background-color: #D5D4E7;
  width: 60px;
  height: 20px;
  top: 38%;
  left: -28%;
  transform: rotateZ(10deg);
}

.back-body:after {
  content: "";
  position: absolute;
  background-color: #68687D;
  width: 60px;
  height: 20px;
  top: 40%;
  right: -27%;
  transform: rotateZ(-12deg);
}

.window1 {
  border-bottom: 19px solid #202134;
  border-right: 8px solid transparent;
  height: 0;
  width: 23px;
  position: absolute;
  left: 51%;
  top: 21%;
  border-top-left-radius: 3px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 3px;
}

.window2 {
  border-bottom: 19px solid #202134;
  border-right: 8px solid transparent;
  height: 0;
  width: 23px;
  position: absolute;
  left: 47.5%;
  top: 21%;
  border-top-left-radius: 3px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 3px;
  transform: rotateY(180deg);
  transform-origin: 0%;
}

.window3 {
  border-bottom: 19px solid #202134;
  border-right: 8px solid transparent;
  height: 0;
  width: 12px;
  position: absolute;
  left: 71%;
  top: 22.8%;
  border-top-left-radius: 3px;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 5px;
  transform: skew(25deg, 15deg);
}

.window4 {
  border-bottom: 19px solid #202134;
  border-right: 8px solid transparent;
  height: 0;
  width: 12px;
  position: absolute;
  left: 14%;
  top: 22.8%;
  border-top-left-radius: 3px;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 5px;
  transform: skew(-25deg, -15deg) rotateY(180deg);
}


.hor-stab {
  border-bottom: 124px solid #B8B7CB;
  border-right: 5px solid transparent;
  height: 0;
  width: 8px;
  position: absolute;
  left: 43.7%;
  bottom: 38%;
  transform: rotateZ(-81deg);
  transform-origin: 0% 100%;
  border-top-right-radius: 15px;
  z-index: -100;
}

.hor-stab2 {
  border-bottom: 124px solid #68677D;
  border-right: 5px solid transparent;
  height: 0;
  width: 8px;
  position: absolute;
  left: 55.1%;
  bottom: 42.4%;
  border-top-right-radius: 20px;
  z-index: -100;
  transform: rotateY(180deg) rotateZ(-81deg);
  transform-origin: 100% 100%;
}

.wing {
  border-bottom: 467px solid #FDFDFD ;
  border-right: 25px solid transparent;
  height: 0;
  width: 8px;
  position: absolute;
  left: 40.5%;
  bottom: 23%;
  z-index: 0;
  transform: rotateY(180deg) rotateZ(85deg);
  transform-origin: 100% 100%;

}

.wing:before {
  content: "";
  position: absolute;
  border-bottom: 29px solid #B9B9CE;
  border-right: 5px solid transparent;
  height: 0;
  width: 5px;
  z-index: 1;
  transform: rotateZ(-80deg);
  border-top-right-radius: 15px;
  top: -12px;
  left: -15px;
}

.wing-bottom {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 500px 20px 0;
  border-color: transparent #D5D4E7 transparent transparent;
  left: 5%;
  bottom: 27.5%;
  z-index: -1;
  transform: rotate(6deg);
}

.wing-right {
  left: 50%;
  bottom: 26.8%;
  transform: rotateY(180deg) rotate(6deg);
  border-color: transparent #68687D transparent transparent;
}

.right {
  left: 53.5%;
  top: -73%;
  transform: rotateZ(85deg);
}

.engine {
  width: 98px;
  height: 100px;
  position: absolute;
  background: #908FA4;
  border-radius: 50%;
  bottom: 2%;
  left: 29.8%;
  overflow: hidden;
}

.engine:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #B9B9CE;
  border-radius: 50%;
  bottom: 5%;
}

.fan {
  position: absolute;
  width: 81%;
  height: 80%;
  background: -webkit-radial-gradient(center, cover, #000005 0%, #2B2A43 28%, #000005 85%);
  background: radial-gradient(center, cover, #000005 0%, #2B2A43 28%, #000005 85%);
  border-radius: inherit;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
}

.front {
  position: absolute;
  width: 26.8%;
  height: 27.9%;
  background-color: #78778C;
  top: 50%;
  left: 50%;
   transform: translate(-50%,-50%);
  border-radius: 50%;
  overflow: hidden;
}

.front:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.4);
  border-radius: 50%;
  left: -33%;
  top: -33%;
}

.inner {
  width: 65%;
  height: 65%;
  background: none;
  background-color: #9190a0;
  z-index: -10;
  left: 49%;
}

.eng-small {
  left: 17.2%;
  bottom: 16.8%;
  width: 73px;
  height: 70px;
}

.eng-big2 {
  left: 61.5%;
}

.small2 {
  left: 76.5%;
}
.Airplane > .cloud{
    position: absolute;
    width: 2em;
    height: 2em;
    left: 0%;
    top: 0%;
    /*background: #D5DEE9;*/
    background:  linear-gradient(to bottom left,  #f8f8f8, #f5f5f5);
    border-width: 0.25em;
    border-style: solid;
    border-color: #6E839E;
    border-radius: 50% 50% 0 0;
}
.cloud:before, .cloud:after {
    content: "";
    position: absolute; bottom: -0.25em;
    background: inherit;
    border-width: 0.25em;
    border-style: solid;
    border-color: #6E839E;
}
.cloud:before {
    left: 80%;
    width: 60%; height: 60%;
    border-left: none;
    border-radius: 50% 50% 50% 0;
}
.cloud:after {
    right: 80%;
    width: 80%; height: 80%;
    border-right: none;
    border-radius: 50% 50% 0;
}
.Airplane > .cloud-1{
  width: 6em;
  height: 6em;
  left: 10%;
  animation: cloud-top-bottom 10s infinite alternate;
}
 @keyframes cloud-top-bottom {   /* назовём анимацию: "go-left-right" */
    from {
      top: 190px;
    }
    to {
      top: calc(120px);
    }
 }
 @-webkit-keyframes cloud-top-bottom {
    from {
      top: 180px;
    }
    to {
      top: calc(120px);
    }
 }
 .Airplane > .cloud-2{
  width: 10em;
  height: 10em;
  left: 10%;
  animation: cloud-top-bottom2 10s infinite alternate;
}
 @keyframes cloud-top-bottom2 {   /* назовём анимацию: "go-left-right" */
    from {
      top: 190px;
    }
    to {
      top: calc(120px);
    }
 }
 @-webkit-keyframes cloud-top-bottom2 {
    from {
      top: 180px;
    }
    to {
      top: calc(120px);
    }
 }
 .Airplane > .cloud-3{
  width: 6em;
  height: 6em;
  left: 16%;
  animation: cloud-top-bottom3 10s infinite alternate;
}
 @keyframes cloud-top-bottom3 {   /* назовём анимацию: "go-left-right" */
    from {
      top: 200px;
    }
    to {
      top: calc(170px);
    }
 }
 @-webkit-keyframes cloud-top-bottom3 {
    from {
      top: 200px;
    }
    to {
      top: calc(170px);
    }
 }
  .Airplane > .cloud-4{
  width: 13em;
  height: 13em;
  left: 50%;
  animation: cloud-top-bottom4 10s infinite alternate;
}
 @keyframes cloud-top-bottom4 {   /* назовём анимацию: "go-left-right" */
    from {
      top: 200px;
    }
    to {
      top: calc(170px);
    }
 }
 @-webkit-keyframes cloud-top-bottom4 {
    from {
      top: 200px;
    }
    to {
      top: calc(170px);
    }
 }
.Airplane > .cloud-5{
  width: 20%;
  height: 54%;
  left: 70%;
  opacity: .9;
  animation: cloud-top-bottom5 10s infinite alternate;
}
 @keyframes cloud-top-bottom5 {   /* назовём анимацию: "go-left-right" */
    from {
      top: 180px;
    }
    to {
      top: calc(100px);
    }
 }
 @-webkit-keyframes cloud-top-bottom5 {
    from {
      top: 180px;
    }
    to {
      top: calc(100px);
    }
 }
 .Airplane > .cloud-6{
  width: 17%;
  height: 50%;
  left: 70%;
  opacity: .9;
  animation: cloud-top-bottom6 10s infinite alternate;
}
 @keyframes cloud-top-bottom6 {   /* назовём анимацию: "go-left-right" */
    from {
      top: 200px;
      left: 70%;
    }
    to {
      top: calc(80px);
      left: 72%;
    }
 }
 @-webkit-keyframes cloud-top-bottom6 {
    from {
      top: 200px;
      left: 0%;
    }
    to {
      top: calc(80px);
      left: 72%;
    }
 }