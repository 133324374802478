.loader-spinner {
    position: fixed;
    top: calc(50%);
    left: calc(50%);
    /* left: calc(50% - 40px); */
    bottom: calc(50%);
	/* right: calc(50% - 40px); */
	transform: translate(-50%, 0);
    z-index: 2;
    margin: auto;
    display: block;
	width: 80px;
}

.cssload-loader {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background-color: rgba(69, 210, 154, 0.65);
}
.cssload-loader,
.cssload-loader:before,
.cssload-loader:after {
	animation: 1.15s infinite ease-in-out;
}
.cssload-loader:before,
.cssload-loader:after {
	width: 100%; 
	height: 100%;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
}

.cssload-loader:before,
.cssload-loader:after {
    content: "";
}

.cssload-loader:before {
	content: '';
	border: 10px solid white;
	top: -10px;
	left: -10px;
	animation-name: cssload-animation;
}



@keyframes cssload-animation {
	0% { transform: scale(0); }
	100% { transform: scale(1); }
}

@-o-keyframes cssload-animation {
	0% { -o-transform: scale(0); }
	100% { -o-transform: scale(1); }
}

@-ms-keyframes cssload-animation {
	0% { -ms-transform: scale(0); }
	100% { -ms-transform: scale(1); }
}

@-webkit-keyframes cssload-animation {
	0% { -webkit-transform: scale(0); }
	100% { -webkit-transform: scale(1); }
}

@-moz-keyframes cssload-animation {
	0% { -moz-transform: scale(0); }
	100% { -moz-transform: scale(1); }
}