.flight-details {
	background: #f2f1ef;
	max-width: 900px;
	margin: 0 auto;
	border-radius: 10px;
}

.flight-header {
	display: flex;
	align-items: center;
	padding: 7px 30px;
	background: #ea4445;
	border-radius: 10px 10px 0 0;
	color: #fff;
}

.flight-header > img {
	width: 100%;
	max-width: 34px;
}

.flight-header h3 {
	margin-left: 22px;
	font-size: 26px;
}

.flight-duration {
	display: flex;
	flex-flow: column wrap;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	font-size: 16px;
}

.flight-duration > img {
	max-width: 34px;
	width: 100%;
	margin-bottom: 34px;
}

.flight-duration__title {
	padding: 0 4px 6px;
	margin-bottom: 6px;
	border-bottom: 1px solid #ccc;
}

.flight-details-share {
	max-width: 870px;
	margin: 0 auto 70px;
	text-align: right;
}

.flight-details-share span {
	margin-right: 6px;
	font-size: 14px;
}

.flight-details-share i {
	position: relative;
	top: 7px;
	color: #222;
}

.flight-body {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 40px 30px 50px;
}

@media screen and (max-width: 880px) {
	.flight-body {
		flex-flow: column wrap;
		padding: 20px;
	}
}
