.swiper-button-next{
    right: 0;
}
.swiper-button-prev{
    left: 0;
}
.swiper-button-next, .swiper-button-prev{
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 18px;
    height: 100%;
    background-image: none;
    background: none;
}
.swiper-button-next:hover,
.swiper-button-prev:hover{
    background: #e9e9e9;
}
.swiper-button-next .material-icons,
.swiper-button-prev .material-icons{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    font-size: 18px;
    line-height: 18px;
    width: 18px;
    height: 18px;
    color: #b3b3b3;
}


.SunRiseDayParts{
    margin: 0 0 30px;
    min-height: 0;
    min-width: 0;
}
.SunRiseDayParts .day_parts{
    position: relative;
    height: 170px;
    margin: 0 0 5px;
    background: #f5f5f5;
    /* color: #444f4f; */
    /* color: #7f7f7f; */
    /* border: 1px solid #ccc; */
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    z-index: 1;
    cursor: pointer;
}
.SunRiseDayParts .day_parts h5{    
    display: flex;
    align-items: center;
    padding: 20px 10px;
    background: rgb(83, 139, 136);
    color: #fff;
    /* margin: 0 0 20px; */
    height: 60px;
    line-height: 16px;
}
.SunRiseDayParts .day_parts > span {
    position: relative;
    display: block;
    padding: 20px 10px;
}
.SunRiseDayParts .day_parts > span > span{
    position: absolute;
    bottom: 8px;
    left: 10px;
    width: 20px;
    height: 2px;
}
.SunRiseDayParts .day_parts .day_parts_info{
    /* position: absolute;
    left: 0;
    bottom: 20px; */
    padding: 0 10px;
}
.SunRiseDayParts .day_parts .day_parts_info span{
    display: block;
}
.SunRiseDayParts .day_parts .day_parts_info:first-child{
    margin: 0 0 5px;
}
.SunRiseDayParts .swiper-button-next,
.SunRiseDayParts .swiper-button-prev{
    top: 61px;
    height: 108px;
    margin: 0;
}
.SunRiseDayParts .swiper-button-next.swiper-pagination-bullet-active,
.SunRiseDayParts .swiper-button-prev.swiper-pagination-bullet-active{
    background: #74afad;
}
.SunRiseDayParts .swiper-button-next.swiper-button-disabled,
.SunRiseDayParts .swiper-button-prev.swiper-button-disabled{
    background: none;
}
.SunRiseDayParts .swiper-pagination-bullet{    
    background: none;
    border: 2px solid #d9d9d9;
    opacity: 1;
}
.SunRiseDayParts .swiper-pagination-bullet-active{
    background: none;
    border: 2px solid rgb(83, 139, 136);
}