.account-pages{
    display: flex
}

@media screen and (max-width: 1060px) {
    .account-pages {
        flex-direction: column;
    }
    .pages-select,
    .account-info{
        width: 100% !important;
        min-width: 100% !important;
    }
    .account-info .profile .profile-list .profile-block{
        flex-wrap: wrap;
    }
    .account-info .profile .profile-list .profile-block .authorisation-form-input{        
        flex-direction: column;
        width: 100%;
        align-items: flex-start !important;
    }
    .account-info .profile .profile-list .profile-block .authorisation-form-input > div,
    .account-info .profile .profile-list .profile-block input{
        width: 100% !important;
    }
    .account-info .profile .profile-list .profile-block .authorisation-form-input label {
        margin: 0 0 5px;
    }
    .account-info > div form > div,
    .account-info .customise .checkbox-block{
        padding: 0 !important;
    }
    .account-info .customise .checkbox-block{
        flex-wrap: wrap;
    }
    .account-info .customise .checkbox-block span{
        width: 100% !important;
        margin: 0 0 5px;
    }
}