.povered-by {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	margin: 0 0 20px;
	max-width: 400px;
}
.povered-by span {
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 14px;
	margin: 0;
}
.povered-by a {
	display: flex;
	height: 40px;
	margin-bottom: 10px;
	color: #3434e2;
}
.povered-by a img {
	height: 100%;
	width: 90px;
	object-fit: contain;
}

@media (min-width: 470px) {
	.povered-by {
		justify-content: space-between;
	}
	.povered-by a {
		margin: 0;
	}
}

@media (min-width: 700px) {
	.povered-by {
		justify-content: flex-end;
	}
	.povered-by a {
		margin-bottom: 10px;
	}
}

@media (min-width: 900px) {
	.povered-by {
		justify-content: space-between;
	}
	.povered-by a {
		margin: 0;
	}
}

@media (min-width: 1200px) {
	.povered-by {
		justify-content: flex-end;
	}
	.povered-by a {
		margin-bottom: 10px;
	}
}

@media (min-width: 1353px) {
	.povered-by {
		justify-content: space-between;
	}
	.povered-by a {
		margin: 0;
	}
}
