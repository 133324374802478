.terms {
	max-width: 1100px;
	margin: 0 auto;
	text-align: justify;
	line-height: 1.2;
}

.terms p {
	margin-bottom: 20px;
}

.terms h1 {
	font-size: 32px;
}

.terms h2 {
	font-size: 16px;
	margin-bottom: 5px;
}

.terms hr {
	margin-bottom: 30px;
}
