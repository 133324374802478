.account-info {
  width: 75%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  padding: 20px;
}

.account-info h4 {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.account-info h4 span {
  font-size: 14px;
}

.account-info > div h5 {
  margin-bottom: 15px;
}

.account-info > div form > div {
  padding: 0 0 0 30px;
}

.account-info .profile {
  margin: 0 0 30px;
}

.account-info .profile .profile-list {
  display: flex;
  flex-direction: column;
}

.account-info .profile .profile-list .profile-block {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

.account-info .profile .profile-list .profile-block__success-pass-note {
  color: #008854;
}

.account-info .profile .profile-list .profile-block__error-pass-note {
  color: #e72227;
}

.account-info .profile .profile-list form:last-child .profile-block:last-child {
  margin: 0;
}

.account-info .profile .profile-list .profile-block .authorisation-form-input {
  display: flex;
  white-space: nowrap;
  align-items: center;
  margin: 0 10px 0 0;
}
.account-info
  .profile
  .profile-list
  .profile-block
  .authorisation-form-input
  label {
  width: 185px;
  padding: 0 10px 0 0;
  color: black;
}

.account-info
  .profile
  .profile-list
  .profile-block
  .authorisation-form-input
  > div {
  position: relative;
}

.account-info .profile .profile-list .profile-block input {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #e1e4e7;
  width: 200px;
}

.account-info
  .profile
  .profile-list
  .profile-block
  .authorisation-form-input
  > div
  span {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  color: rgb(244, 67, 54);
  font-size: 0.8em;
  line-height: 0.8em;
}

.account-info .profile .profile-list .profile-block button {
  cursor: pointer;
  background: none;
  border: none;
  color: #3434e2; /*#556bb5;*/
  margin: 0 10px 0 0;
}

.account-info .profile .profile-list .profile-block button:last-child {
  margin: 0;
}

.account-info .customise .checkbox-block {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 30px;
}

.account-info .customise .checkbox-block span {
  width: 25%;
  min-width: 130px;
  color: black;
}

.account-info .customise .checkbox-block > div {
  width: 150px !important;
}

.account-info .customise .checkbox-block__upgrade {
  padding-left: 2px;
}

@media only screen and (max-width: 1060px) {
  .account-info h4,
  .account-info > div h5 {
    text-align: center;
  }

  .account-info .customise .checkbox-block span {
    margin-bottom: 20px;
  }

  .account-info .customise .checkbox-block {
    padding-top: 20px;
  }

  .account-info
    .profile
    .profile-list
    .profile-block
    .authorisation-form-input {
    margin-bottom: 5px;
  }

  .account-info .profile .profile-list .profile-block button {
    padding-left: 1px;
  }

  .account-info .customise .checkbox-block {
    margin-bottom: 17px;
  }

  .checkbox-block label {
    font-weight: bold;
  }

  .account-info .profile {
    margin-bottom: 15px;
  }

  .customise-title {
    font-size: 15px;
    margin-bottom: 20px;
  }
}

.save-button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.save-button-wrapper p {
  margin-right: 1em;
  color: #008854;
  transition: all 3s;
}

.save-button {
  width: 100px;
  height: 30px;
  background: #7ea7d8;
  border: none;
  box-shadow: none;
  /*padding: 15px;*/
  color: #fff;
  cursor: pointer;
  float: right;
}
