.sunCulcCityInfo {
	display: flex;
}
.GoogleMapBox .sunCulcCityInfo .sunCulcCityInfoCenter {
	width: 76%;
	padding: 0 1% 0 0;
	display: flex;
	flex-direction: column;
}
.GoogleMapBox .sunCulcCityInfo .sunCulcCityInfoRightSide {
	width: 25%;
}

@media screen and (max-width: 991px) {
	.sunCulcCityInfo {
		flex-direction: column;
	}
	.GoogleMapBox .sunCulcCityInfo .sunCulcCityInfoLeftSide a {
		display: block;
		width: 40px;
		margin: 0 auto;
	}
	.GoogleMapBox .sunCulcCityInfo .sunCulcCityInfoCenter {
		width: 100%;
		padding: 0;
		order: 2;
		margin: 0 0 30px;
	}
	.GoogleMapBox .sunCulcCityInfo .sunCulcCityInfoRightSide {
		width: 100%;
	}
}

@media screen and (min-width: 991px) {
	.GoogleMapBox .sunCulcCityInfo .sunCulcCityInfoRightSide {
		display: flex;
		flex-direction: column;
	}

	.GoogleMapBox
		.sunCulcCityInfo
		.sunCulcCityInfoRightSide
		.sunCulcCityInfoRightSide-wrapper__first {
		order: 1;
		display: flex;
		flex-direction: column;
	}

	.GoogleMapBox
		.sunCulcCityInfo
		.sunCulcCityInfoRightSide
		.sunCulcCityInfoRightSide-wrapper__second {
		order: 0;
	}

	.GoogleMapBox
		.sunCulcCityInfo
		.sunCulcCityInfoRightSide
		.sunCulcCityInfoRightSide-wrapper__first
		.eclipse-info {
		order: 1;
	}

	.GoogleMapBox
		.sunCulcCityInfo
		.sunCulcCityInfoRightSide
		.sunCulcCityInfoRightSide-wrapper__first
		.azimuth-info {
		order: 0;
	}

	.GoogleMapBox
		.sunCulcCityInfo
		.sunCulcCityInfoRightSide
		.sunCulcCityInfoRightSide-wrapper__first
		.links {
		order: 3;
	}
}
