.calculators-ads {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.calculators-ads img {
  max-width: 48%;
  width: 100%;
}

/* @media screen and (max-width: 768px) {
  .calculators-ads img {
    max-width: unset;
    margin-bottom: 30px;
  }
} */
