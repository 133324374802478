.created-timer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.created-timer-title_name {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 130px;
  z-index: 800;
}
.created-timer-title_name > p {
  color: white;
  font-size: 55px;
  letter-spacing: 0.1em;
  text-shadow: 1px 1px #732372, 1px -1px #732372, -1px 1px #732372,
    -1px -1px #732372, 3px 3px 6px rgba(0, 0, 0, 0.5);
}

.created-timer-format {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
  z-index: 801;
}
.created-afte_timer-format {
  min-height: 120px;
  padding: 50px 20px 0px 20px;
  font-size: 45px;
  color: rgba(50, 50, 50, 0.7);
  background-color: rgba(250, 250, 250, 0.8);
  text-shadow: 2px 2px 6px rgba(250, 250, 250, 0.9),
    1px 1px 1px rgba(250, 250, 250, 0.9);
}
.created-timer-time_until {
  display: flex;
  justify-content: center;
  /*min-height: 230px;*/
  margin-bottom: 190px;
  width: 100%;
  padding-top: 30px;
  z-index: 802;
}
.created-timer-time_until > p {
  /*color: #1dacd6;*/
  padding-top: 3px;
  height: 23px;
  font-size: 20px;
  letter-spacing: 0.1em;
  color: rgba(50, 50, 50, 0.7);
  background-color: rgba(250, 250, 250, 0.8);
  text-shadow: 2px 2px 6px rgba(250, 250, 250, 0.9),
    1px 1px 1px rgba(250, 250, 250, 0.9);
}
#clockdiv {
  font-family: sans-serif;
  color: #fff;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 40px;
}

#clockdiv > div {
  margin-left: 5px;
  padding: 10px;
  border-radius: 3px;
  background: rgba(40, 50, 61, 0.7);
  display: inline-block;
}

#clockdiv div > span {
  padding: 30px;
  border-radius: 3px;
  background: rgba(40, 50, 61, 0.2);
  display: inline-block;
}

.smalltext {
  padding-top: 5px;
  font-size: 16px;
}

@media screen and (max-device-width: 1400px) {
  /*.created-timer-title_name {min-width:  1200px;}*/
}
