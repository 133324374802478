.flight-card {
	background: #fff;
	max-width: 300px;
	width: 100%;
	border-radius: 10px;
}

.flight-card-header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 7px 30px;
	background: #000;
	border-radius: 10px 10px 0 0;
	color: #fff;
}

.flight-card-header p {
	font-size: 18px;
}

.FlightTimeCalculator .flight-card-header .material-icons {
	font-size: 20px !important;
	position: absolute;
	left: 14px;
	top: 50% !important;
	transform: translateY(-50%);
}

.flight-card-body {
	padding: 16px;
}

.flight-card-body-title {
	text-align: center;
	padding-bottom: 10px;
	border-bottom: 1px solid #bcbcbc;
	margin-bottom: 10px;
}

.flight-card-body__city {
	font-size: 20px;
	margin-bottom: 6px;
}

.flight-card-body-info {
	display: flex;
	justify-content: space-between;
}

.flight-card-body-info:not(:last-child) {
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #f1f1f1;
}

.flight-card-timing {
	font-size: 14px;
}

.flight-card-timing_center {
	text-align: center;
}

.flight-card-timing__title {
	white-space: nowrap;
	max-width: 190px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.flight-card-timing__time {
	font-size: 16px;
}

.flight-card-timing__time span {
	text-transform: uppercase;
	font-size: 12px;
}

.flight-card-timing__timezone,
.flight-card-timing__date {
	text-transform: uppercase;
	font-size: 12px;
}

@media screen and (max-width: 880px) {
	.flight-card {
		max-width: 600px;
	}
	.flight-card:not(:last-child) {
		margin-bottom: 30px;
	}
	.flight-duration {
		margin-bottom: 30px;
	}
}
