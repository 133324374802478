.block-countdown-comp > .block-list{
    width: 27%;
    margin: 15px;
}
.square {
  margin: 0;
  counter-reset: li;
  list-style: none;
  background: white;
  /*padding: 10px;*/
}
.square li {
  position: relative;
  margin: 0 0 10px 2em;
  padding: 4px 8px;
  border-bottom: 2px solid #787A77;
  transition: .3s linear;
}
.square li:last-child {margin-bottom: 0;}
.square li:before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  top: -2px;
  left: -2em;
  width: 2em;
  box-sizing: border-box;
  margin-right: 8px;
  padding: 4px;
  border-top: 2px solid #787A77;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  background: #787A77;
  color: white;
  font-weight: bold;
  text-align: center;
  transition: .3s linear;
}
.square li:hover {border-bottom: 2px solid #169bd5;}
.square li:hover:before {
  border: 2px solid #169bd5;
  background: #1DACD6;
}