.solarLunarEclipses {
    display: flex;
}
.solarLunarEclipses .eclipsesInfo{
    width: 76%;
    padding: 0 1% 0 0;
}
.solarLunarEclipses .eclipsesInfo > div:last-child {
    margin: 0;
}
.solarLunarEclipses .LinksBlock{
    width: 25%;
}
.solarLunarEclipses .solarLunarEclipsesInfo > div{
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    margin: 0 0 10px;
}
.solarLunarEclipses .solarLunarEclipsesInfo {
    margin: 0 0 40px 0;
}
.Eclipses .solarLunarEclipses .solarLunarEclipsesInfo h5{
    display: flex;
    height: 50px;
    align-items: center;
    color: #000;
    padding: 5px 16px;
    font-size: 16px;
    line-height: 17px;
}
.Eclipses .solarLunarEclipses .solarLunarEclipsesInfo:first-child  h5{
    background: #fcf588;
}
.Eclipses .solarLunarEclipses .solarLunarEclipsesInfo:nth-child(2) h5{
    background: #b8e6fc;
}
.Eclipses .solarLunarEclipses .solarLunarEclipsesInfo h5 img {
    height: 100%;
    margin: 0 15px 0 0;
}

.solarLunarEclipses .solarLunarEclipsesInfo {
    width: 100%;
}
.solarLunarEclipses .solarLunarEclipsesInfo  .eclipse-info{
    display: flex;
    margin: 0;
}
.solarLunarEclipses .solarLunarEclipsesInfo  .eclipse-info .eclipse-blocks{
    flex-grow: 1;
}
.eclipsesWrapedBlock {
    background: #f5f5f5;
}
.eclipsesWrapedBlock .eclipse-info .eclipse-blocks .eclipse-info-block {
    display: flex;
    padding: 10px 16px;
}
.eclipsesWrapedBlock .eclipse-info .eclipse-blocks .eclipse-info-block b {
    display: block;
    font-weight: normal;
    width: 49%;
}
.eclipsesWrapedBlock .eclipse-info .eclipse-blocks .eclipse-info-block b:first-child {
    margin: 0 2% 0 0;
}
.solarLunarEclipses .solarLunarEclipsesInfo  .eclipse-info .eclipses-img{
    position: relative;
    width: 360px;
    margin: 0 0 0 0;
}
.solarLunarEclipses .solarLunarEclipsesInfo .eclipse-info .eclipses-img .eclipses-img-top{
    position: relative;
    height: 50%;
}
.solarLunarEclipses .solarLunarEclipsesInfo  .eclipse-info .eclipses-img .eclipses-img-bottom{
    position: relative;
    height: 50%;
}
.solarLunarEclipses .solarLunarEclipsesInfo  .eclipse-info .eclipses-img img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    height: calc(100% - 34px);
    width: auto;
    padding: 10px; 
}
.solarLunarEclipses .solarLunarEclipsesInfo  .eclipse-info .eclipses-img .eclipses-img-top img {
    height: auto;
}

.solarLunarEclipses .solarLunarEclipsesInfo  .eclipse-info .eclipses-img .picture-eclipse-text{
    position: absolute;
    bottom: 10px;
    left: 0;
    display: block;
    width: 100%;
    padding: 0 10px;
    text-align: center;
    font-size: 13px;
}


.aboutEclipses .eclipses-link-block .eclipse-info h5{
    background: rgb(83, 139, 136);
    padding: 15px 16px;
    color: #fff;
    font-size: 16px;
    line-height: 17px;
}

.Eclipses.EclipsesWorld .solarLunarEclipses .solarLunarEclipsesInfo > a{
    display: block;
}

@media (max-width: 991px) {
    .solarLunarEclipses {
        flex-direction: column;
    }
    .solarLunarEclipses .eclipsesInfo{
        width: 100%;
        padding: 0;
        margin: 0 0 20px;
    }
    .solarLunarEclipses .LinksBlock{
        width: 100%;
    }
}
@media (max-width: 767px) {
    .solarLunarEclipses .solarLunarEclipsesInfo .eclipse-info{
        flex-direction: column;
    }
    .solarLunarEclipses .solarLunarEclipsesInfo .eclipse-info .eclipses-img{
        height: 286px;
        width: 100%;
    }
}