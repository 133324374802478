.privacy {
	max-width: 1100px;
	margin: 0 auto;
	text-align: justify;
	line-height: 1.2;
}

.privacy p {
	margin-bottom: 20px;
}

.privacy h1 {
	font-size: 32px;
}

.privacy h2 {
	font-size: 16px;
	margin-bottom: 5px;
}

.privacy hr {
	margin-bottom: 30px;
}
