.FlightTimeCalculator .InformationBlocks {
  justify-content: flex-start;
}

.FlightTimeCalculator .InformationBlocks .CalculatorsAndTimers {
  margin-right: 26px;
}

.FlightTimeCalculator .tools-and-apis .currentCityHeader .currentCityImg {
  background: #63697b;
}

.FlightTimeCalculator .tools-and-apis .currentCityHeader .currentCityTittle {
  background: #767b8a;
}

.FlightTimeCalculator .imageFtF {
  width: 40px !important;
  height: 33px !important;
}

.FlightTimeCalculator .mat_iconFtF {
  top: 37px !important;
  font-size: 38px !important;
  margin-right: 2px;
}
