.sunMapWrapper{
    margin: 0 0 30px;
}
/* hide the scaling label */
/* .gm-style-pbc{
    display: none !important;
} */
/* hide streat view */
.gm-svpc{
    display: none;
}