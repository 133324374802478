.Calculator {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
  margin: 0 0 50px;
}

.Calculator .header {
  display: flex;
  align-items: center;
  height: 75px;
  padding: 10px 20px;
  background: #63697b;
  color: #fff;
}

.Calculator .content {
  padding: 20px;
}

.Calculator .content h4 {
  margin: 0 0 10px;
}

.Calculator .content .content-info {
  display: flex;
  justify-content: space-between;
}

.Calculator .content .content-info > div:first-child {
  margin: 0 2% 0 0;
}

.Calculator .content .content-info .info-block {
  display: flex;
}

.Calculator .content .content-info .info-block .image {
  position: relative;
  width: 80px;
  height: 80px;
}

.Calculator .content .content-info .info-block .image i {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 100%;
  max-width: 100%;
  margin: auto 0;
  padding: 0;
  width: 70px;
  height: 70px;
  font-size: 70px;
}

.Calculator .content .content-info .info-block .info {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  width: calc(100% - 80px);
}

.Calculator .content .content-info .info-block .info h5 {
  padding: 0 0 5px;
}

.Calculator .content .content-info .info-block .info input {
  width: 200px;
  max-width: 100%;
  padding: 10px 15px;
}

.Calculator .content .content-info .info-block:first-child .info input {
  width: 400px;
}

.Calculator .content .content-info .info-block .info .Search {
  padding: 0;
  width: 400px;
  max-width: 100%;
}

.Calculator
  .content
  .content-info
  .info-block
  .info
  .Search
  .search_form_select {
  margin: 0;
  max-width: 100%;
}

.Calculator .content .content-info .calendar-date-picker > div,
.Calculator .content .content-info .time-date-picker {
  max-width: 100%;
}

.Calculator .footer {
  padding: 20px;
  display: flex;
  align-items: center;
}

.Calculator .footer button {
  width: 130px;
  height: 65px;
  background-color: rgba(22, 155, 213, 1);
  border: 1px solid rgba(22, 155, 213, 1);
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.Calculator .footer b {
  display: none;
  margin: 0 0 0 20px;
  color: rgb(244, 67, 54);
}

.search_form_select input[type="text"]::-webkit-input-placeholder {
  color: #808080;
}

.search_form_select input[type="text"]::-moz-placeholder {
  color: #808080;
}

.unix-title {
  font-size: 18px;
  padding: 10px 0px 10px 10px;
  margin: 0px;
  font-weight: 400;
  line-height: 1.1;
}

.unix-general {
  font-family: Roboto, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 20px;
}

.unix-row {
  font-family: Roboto, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  min-height: 20px;
}

.unix-row > h3,
.unix-row > h4,
.unix-row > p {
  padding: 10px 0px 10px 10px;
  margin: 0px;
  min-height: 36px;
}

.Calculator > .unix-row > h4 {
  padding: 20px 0px 20px 20px;
  margin: 0px;
}

.gps-convector .unix-row p span {
  display: block;
  border-bottom: 2px solid #c0c0c0 !important;
  width: 100%;
  padding-bottom: 2px;
}

.gps-convector .unix-row p:hover span {
  transition: 0.5s;
  border-bottom: none;
  box-shadow: none;
}

.gps-convector .unix-row p:not(:hover) span {
  transition: 0.5s;
  border-bottom: 1px solid #c0c0c0;
}

.gps-convector .unix-column {
  width: 53%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1200px) {
  .gps-convector .unix-column {
    width: 70%;
  }
}

.gps-convector .unix-head > h3 {
  display: flex;
  justify-content: center;
  min-height: 20px;
  font-weight: 600;
}

.gps-convector .text {
  display: flex;
  justify-content: flex-start;
  width: 53%;
}
.gps-convector .text > h4 {
  font-weight: bold;
}
.gps-convector .text-result {
  isplay: flex;
  justify-content: flex-start;
  width: 26%;
}
.gps-convector .text-result > h4 {
  font-weight: bold;
}

.gps-convector .input > h4 {
  font-weight: bold;
}
.gps-convector .input-result {
  width: 77%;
}
.gps-convector .input-result > h4 {
  font-weight: bold;
}
.gps-convector .input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38%;
}
.gps-convector .input_your_curent {
  width: 83%;
}
.input > p > b:hover {
  font-size: 18px;
  transition: 0.5s;
}

.gps-convector .input > p > b:not(:hover) {
  font-size: 18px;
  transition: 0.5s;
}

.input > select,
input,
input:disabled {
  width: 85%;
  height: 30px;
  padding: 0px 0px 0px 5px;
  margin: 0px;
  border: none;
  border-bottom: 1px solid #ccc;
}

.gps-convector .input > select {
  border: none;
}

.gps-convector .input > input {
  height: 21px;
  border: none;
}

.gps-convector .input > input:disabled {
  height: 21px;
  border: none;
}

.gps-convector .input > input:disabled {
  /*background-color: white;*/
}
.gps-convector .input-select > select {
  height: 22px !important;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid #d2d5d5;
  color: darkgrey;
}
.gps-convector .input-select {
  border: 2px solid #d2d5d5;
  height: 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: darkgrey;
  width: 20%;
}

.gps-convector .input-date > select:focus {
  border-bottom: none;
  box-shadow: none;
  transition: 0.5s;
}

.gps-convector .input-date > input:focus {
  border-bottom: none;
  box-shadow: none;
  transition: 0.5s;
}

.gps-convector .input-date > select:not(:focus) {
  border-bottom: none;
  transition: 0.5s;
}

.gps-convector .input-date > input:not(:focus) {
  border-bottom: none;
  transition: 0.5s;
}

.input-date {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gps-convector .input-date {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #d2d5d5;
  width: 8%;
  margin-right: 2px;
}

.input-date > input {
  text-align: center;
  width: 70%;
  height: 30px;
  padding: 0px 0px 0px 5px;
  margin: 0px;
}

.gps-convector .input-date > input {
  text-align: center;
  width: 100% !important;
  height: 21px;
  padding: 0 !important;
  margin: 0;
}

.input-date input::-webkit-input-placeholder {
  color: #c0c0c0;
}

.input-date input::-moz-placeholder {
  color: #c0c0c0;
}

.gps-convector .button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10% !important;
}

.gps-convector .button > button {
  height: 20px;
  width: 50px;
  padding: 0px 0px 0px 0px;
  margin: 0px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.buttons > button {
  height: 30px;
  width: 90%;
  padding: 0px 0px 0px 0px;
  margin: 10px;
}

.gps-convector .unix-use {
  background-color: #ee9545 !important;
  border: 1px solid #ee9545 !important;
  border-radius: 3px !important;
  color: #fff;
  cursor: pointer;
}

.unix-use:hover {
  background-color: #1dacd6;
  transition: 0.5s;
}

.unix-use:not(:hover) {
  transition: 2s;
  background-color: #169bd5;
}

.errorUnix {
  padding-left: 48%;
  color: #f44336;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.gps-convector .input-enter {
  border: 2px solid #d2d5d5;
  /*padding: 10px;*/
  height: 25px;
}

.input > .input-error {
  border-bottom: 1px solid #f44336;
  box-shadow: 0px 5px 10px -9px #f44336;
  transition: 0.5s;
}

.input-date > .input-error {
  border-bottom: 1px solid #f44336;
  box-shadow: 0px 5px 10px -9px #f44336;
  transition: 0.5s;
}

@media (max-width: 1199px) {
  .FlightTimeCalculator .InformationBlocks {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .Calculator .content .content-info {
    flex-direction: column;
  }

  .Calculator .content .content-info > div {
    width: 100%;
  }

  .Calculator .content .content-info > div:first-child {
    margin: 0;
  }
}
