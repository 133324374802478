header {
  color: #fff;
  background: #008854;
  display: flex;
  flex-direction: column;
  align-items: center;
}
header .header_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}
header .header_wrapper .logo_wrapper {
  width: 250px;
  height: 100px;
  color: #fff;
}
header .header_wrapper .logo_wrapper:hover {
  color: #fff;
}
header .header_wrapper .logo_wrapper .logo_wrapper_block {
  position: relative;
  height: 100%;
}
header .header_wrapper .logo_wrapper .logo_wrapper_block .App-logo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 100%;
  max-width: 60%;
  margin: auto;
  padding: 0;
  animation: App-logo-spin infinite 20s linear;
}
header .header_wrapper .logo_wrapper h3 {
  font-size: 24px;
  line-height: 24px;
}
header .header_wrapper .header-blocks {
  width: calc(100% - 280px);
}
.header-top {
  display: flex;
  justify-content: center;
  height: 100px;
}

.header-bottom {
  display: flex;
  justify-content: flex-end;
}
.header-bottom .header_wrapper {
  display: flex;
}
.header-bottom > ul {
  display: flex;
}
.header-menu li {
  position: relative;
  border-bottom: none;
  border-top: none;
  width: 190px;
  height: 100%;
  min-width: 108px;
}
.header-menu li:hover,
.menu_Accordeon .react-sanfona-item-expanded,
.menu_Accordeon .react-sanfona-item:hover {
  background: #45a580;
}
.header-menu li + li {
  border-left: none;
}
.menu_Accordeon {
  height: 25px;
}

.header-menu li p {
  cursor: default;
}

.header-menu li p,
.header-menu li a,
.header-menu li > a:focus {
  color: #fff;
  padding: 7px 16px;
  display: flex;
  width: 100%;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
}

.header-menu li p i,
.header-menu li a i {
  font-size: 16px;
  line-height: 16px;
}

.header-menu li p:hover i,
.header-menu li a:hover i {
  text-decoration: none;
}

.header-menu li div a {
  color: #000 !important;
}

.header-menu li:last-child {
  width: auto;
}

.header-menu li > div a:hover {
  background: #5fc59e;
  color: #fff !important;
}

.header-menu li > div {
  display: none;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  background: #fff;
}

.header-bottom > ul > li:hover > div {
  display: block;
}

.header-menu li div.account-preview-button {
  height: auto;
  overflow: inherit;
  width: auto;
  position: relative;
  top: 0;
  left: 0;
  z-index: 5;
  box-shadow: none;
  background: #048854;
}

.header-menu li > img {
  height: 30px;
  cursor: pointer;
}

.header-menu .dropdown-title:hover > div {
  display: block;
}

.header-menu li:last-child:hover .account-preview {
  display: block;
}

.header-menu li .second-lvl-menu {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-menu li .second-lvl-menu:hover {
  background-color: #5fc59e;
}

.header-menu li .second-lvl-menu:hover > p {
  color: #fff;
}

.header-menu li .second-lvl-menu > p {
  color: #000;
}

.header-menu li .second-lvl-menu .sub-menu li {
  width: 100%;
}

.header-menu li .second-lvl-menu .sub-menu {
  display: none;
}

.header-menu li .second-lvl-menu:hover .sub-menu {
  display: flex;
}

.header-menu li .second-lvl-menu ul {
  display: none;
  flex-direction: column;
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 6;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}

.header-menu li .second-lvl-menu > i {
  position: absolute;
  right: 0;
  color: #000;
  transform: rotate(-90deg);
}

.header-menu li > span {
  display: block;
  padding: 7px 16px;
}

.header-menu li > div button {
  border: none;
  background: none;
  padding: 7px 16px;
  width: 100%;
  cursor: pointer;
  text-align: left;
}

.header-menu li > div button:hover {
  background: #5fc59e;
  color: #fff;
}

.account-preview-button {
  position: relative;
}

.account-preview-button .logInButtonImg {
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 100%;
}

.account-preview-button .logInButtonImg img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
}

.account-preview {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 3;
  width: 350px;
  background: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  color: #444f4f;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}

.account-preview .account-preview-top {
  display: flex;
}

.account-preview .account-preview-top .logo {
  position: relative;
  width: 60px;
  height: 60px;
  margin: 0 10px 0 0;
}

.account-preview .account-preview-top .logo img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.account-preview .account-preview-top .nickname {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.account-preview .account-preview-center {
  display: flex;
  padding: 15px 0;
}

.account-preview .account-preview-center a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #444f4f;
  border-right: 1px #444f4f solid;
  padding: 5px;
}

.account-preview .account-preview-center a:last-child {
  border-right: none;
}

.account-preview .account-preview-center a:hover {
  color: #fff;
  background: #7ea7d8;
}

.account-preview .account-preview-bottom button {
  width: 100%;
  background: #7ea7d8;
  border: none;
  box-shadow: none;
  padding: 10px 15px;
  color: #fff;
  cursor: pointer;
}

.nav_button {
  display: block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background: url(../../assets/main_page_blocks/navImg_w.png);
  background-size: cover;
}

.navigation {
  margin: 0 auto;
}

.user_img {
  margin: 5px auto;
  width: 90px;
  height: 90px;
}

.user_img_wrapper h3 {
  margin-bottom: 10px;
  text-align: center;
}

.user_social {
  margin-bottom: 10px;
  margin-left: 10px;
  text-align: left;
}

.user_social a {
  display: inline-block;
  margin: 5px;
}

.user_social a img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.react-sanfona-item-title {
  font-size: 16px;
  padding-top: 15px;
  height: 49px;
}

.nav_container span > div > div {
  margin-left: 20px !important;
}

.react-sanfona-item-body-wrapper span > div > div {
  margin-left: 10px !important;
  padding: 0 !important;
  color: #fff;
}

.Shedule_Icon > div:before,
.Clock_Icon > div:before,
.TimeZones_Icon > div:before,
.Almanac_Icon > div:before,
.Calculator_Icon > div:before,
.Ephemeris_Icon > div:before,
.News_Icon > div:before,
.Weather_Icon > div:before,
.Account_Settings_Icon > div:before,
.Logout_Icon > div:before {
  content: "";
  position: absolute;
  top: 10px;
  left: 5px;
  display: block;
  width: 25px;
  height: 25px;
}

.Shedule_Icon > div,
.Clock_Icon > div,
.TimeZones_Icon > div,
.Almanac_Icon > div,
.Calculator_Icon > div,
.Ephemeris_Icon > div,
.News_Icon > div,
.Weather_Icon > div,
.Account_Settings_Icon > div,
.Logout_Icon > div {
  position: relative;
  color: #fff;
}

.Shedule_Icon > div {
  background: #7ea7d8;
}

.Shedule_Icon > div:before {
  background: url(../../assets/main_page_blocks/timePicker.png) no-repeat;
  background-size: contain;
}

.Clock_Icon > div {
  background: #5a9fb9;
}

.Clock_Icon > div:before {
  background: url(../../assets/main_page_blocks/currentCity.png) no-repeat;
  background-size: contain;
}
.TimeZones_Icon > div {
  background: #4a58a6;
}
.TimeZones_Icon > div:before {
  background: url(../../assets/main_page_blocks/timeZones.png) no-repeat;
  background-size: contain;
}

.Almanac_Icon > div {
  background: #04897d;
}

.Almanac_Icon > div:before {
  background: url(../../assets/main_page_blocks/soonMoonPlanets.png) no-repeat;
  background-size: contain;
}

.Calculator_Icon > div {
  background: #646a7c;
}

.Calculator_Icon > div:before {
  background: url(../../assets/main_page_blocks/calculatorsAndTimers.png)
    no-repeat;
  background-size: contain;
}

.Ephemeris_Icon > div {
  background: #9a5da4;
}

.Ephemeris_Icon > div:before {
  background: url(../../assets/main_page_blocks/ephemerisForToday.png) no-repeat;
  background-size: contain;
}

.News_Icon > div {
  background: #6f7ca0;
}

.News_Icon > div:before {
  background: url(../../assets/main_page_blocks/news.png) no-repeat;
  background-size: contain;
}

.Weather_Icon > div {
  background: rgba(202, 200, 56, 0.667);
}

.Weather_Icon > div:before {
  background: url(../../assets/main_page_blocks/cloudWeather.png) no-repeat;
  background-size: contain;
}

.Account_Settings_Icon > div {
  background: rgb(175, 165, 165);
}

.Account_Settings_Icon > div:before {
  background: url(../../assets/main_page_blocks/accountSett.png) no-repeat;
  background-size: contain;
}

.Logout_Icon > div {
  background: rgb(140, 123, 108);
}

.Logout_Icon > div:before {
  background: url(../../assets/main_page_blocks/logoutIcon.png) no-repeat;
  background-size: contain;
}

.menu_Accordeon .react-sanfona-item-body-wrapper {
  background: #6796ce;
}

.menu_Accordeon {
  position: relative;
  z-index: 5;
}

.menu_Accordeon ul li {
  display: block;
}
.menu_Accordeon ul li {
  background: #6796ce;
  border-top: 1px solid #7ea7d8;
}

.menu_Accordeon ul li:hover {
  background: #7ea7d8;
}

.menu_Accordeon .react-sanfona-item-title {
  margin-left: 16px !important;
  height: 44px;
  font-size: 14px;
}

.advertising-block-header {
  display: flex;
  align-items: center;
  height: 100px;
  width: 100%;
}

.advertising-block-header .advertising-header {
  display: flex;
  justify-content: flex-end;
  height: 90px;
  width: 100%;
  color: #444f4f;
}

.advertising-block-header .advertising-header .advertising {
  max-width: 100%;
}

@media screen and (min-width: 992px) {
  .nav_button {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .header-bottom {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .advertising-block-header .advertising-header {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  header .header_wrapper .logo_wrapper .logo_wrapper_block .App-logo {
    max-width: 80%;
  }
}
